import PRSwitch from "~components/Generic/PRSwitch";

import ClosingCodeEdit from "./ClosingCodeEdit";
import ClosingCodeList from "./ClosingCodeList";

function ClosingCode() {
  const routes = [
    { path: "/contactcenter/settings/closing-code/form/:id?", component: ClosingCodeEdit },
    { path: "/contactcenter/settings/closing-code/", component: ClosingCodeList },
    { path: "/contactcenter", to: "/contactcenter/settings/closing-code", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default ClosingCode;
