import { useEffect, useState } from "react";

import { PalButton, PalDialog, PalSelect } from "@palamar/fe-library";
import { withCardon } from "cardon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Alert, Box } from "@mui/material";

import { getClosingCodeList } from "~store/closingCode/actions";
import { selectCurrentProject } from "~store/user/selectors";

const SessionCloseModal = withCardon(
  function SessionCloseModalContent({ closingCodeEnabled, forceClosingCode, get }) {
    const currentProject = useSelector(selectCurrentProject);
    const dispatch = useDispatch();
    const [closingCodeList, setClosingCodeList] = useState([]);
    const [selectedClosingCode, setSelectedClosingCode] = useState(null);
    const [showDynamicAlert, setShowDynamicAlert] = useState({ type: "", message: "" });
    const { t } = useTranslation();
    const [open, setOpen] = useState(true);
    const handleClose = () => {
      if (forceClosingCode && closingCodeEnabled && !selectedClosingCode) {
        setShowDynamicAlert({ type: "error", message: t("component.sessionChat.closingCodeRequired") });
        return;
      }
      get(selectedClosingCode || true)();
      setOpen(false);
    };

    useEffect(() => {
      (async () => {
        if (closingCodeEnabled) {
          const response = await dispatch(getClosingCodeList(currentProject.id));
          setClosingCodeList(response?.results || []);
        }
      })();
    }, [dispatch, closingCodeEnabled, currentProject.id]);

    const handleSelectClosingCode = (e, value) => {
      setSelectedClosingCode(value);
      setShowDynamicAlert({ type: "", message: "" });
    };

    const handleCancel = () => {
      get(false)();
    };
    return (
      <PalDialog fullWidth maxWidth="sm" open={open} scroll="body" onClose={handleClose}>
        <PalDialog.Title>{t("component.sessionChat.endChat")}</PalDialog.Title>
        <PalDialog.Content dividers={false}>
          {!!showDynamicAlert.type && (
            <Alert severity={showDynamicAlert.type} sx={{ mb: 1 }}>
              {showDynamicAlert.message}
            </Alert>
          )}
          {/* {forceClosingCode && closingCodeEnabled && (
            <Alert severity="warning" sx={{ mb: 1 }} variant="outlined">
              {t("component.sessionChat.closingCodeWarning")}
            </Alert>
          )} */}
          <PalDialog.ContentText>{t("component.sessionChat.detailMessage")}</PalDialog.ContentText>
          {closingCodeEnabled && (
            <Box mt={2}>
              <PalSelect
                fullWidth
                isPrimitiveValue
                helperText={t("component.sessionChat.closingCodeRequired")}
                label={t("component.sessionChat.closingCode")}
                options={closingCodeList.map((item) => ({
                  label: item.key,
                  value: item.id,
                }))}
                size="small"
                value={selectedClosingCode}
                onChange={handleSelectClosingCode}
              />
              {/* {forceClosingCode && (
                <FormHelperText required>{t("component.sessionChat.closingCodeRequired")}</FormHelperText>
              )} */}
            </Box>
          )}
        </PalDialog.Content>

        <PalDialog.Actions>
          <PalButton color="error" variant="contained" onClick={handleClose}>
            {t("component.sessionChat.terminate")}
          </PalButton>
          <PalButton onClick={handleCancel}>{t("common.cancel")}</PalButton>
        </PalDialog.Actions>
      </PalDialog>
    );
  },
  { destroyOnHide: true }
);

export default SessionCloseModal;
