import { useMemo } from "react";

import { withCardon } from "cardon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import styled from "@emotion/styled";

import useBootstrapBreakpoint from "~common/hooks/useBootstrapBreakpoint";
import PRModal from "~components/Generic/PRModal";
import PRSelect from "~components/Generic/PRSelect";
import PalButton from "~components/mui/PalButton";
import { getLivechatFilterWithProjectFilterOverride, userRole } from "~constants";
import HistoryHelper from "~helpers/HistoryHelper";
import store from "~store";
import { setSelectedBot, setSelectedProject } from "~store/actions";
import { setLivechatOptions } from "~store/socket/livechat/actions";
import { selectLivechatOptions } from "~store/socket/livechat/selectors";
import {
  selectBots,
  selectCurrentBot,
  selectCurrentProject,
  selectProjects,
  selectUserInfo,
} from "~store/user/selectors";

import HeaderDivider from "./HeaderDivider";

const StyledPRSelect = styled(PRSelect)`
  font-family: "Museo Sans";
  font-size: 14px;
  font-weight: 500;
  .pr-sel__control {
    background-color: transparent !important;
    min-width: 50px;
  }
  .pr-sel__menu {
    min-width: 225px;
  }
  .pr-sel__indicator {
    padding: 0 0 0 3px !important;
  }
  .pr-sel__value-container {
    padding: 0;
  }
  .pr-sel__group-heading {
    padding-bottom: 5px;
  }
  .pr-sel__indicator-separator {
    display: none;
  }
`;

const StyledHeaderLabel = styled.div`
  font-family: "Museo Sans";
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => (props.muted ? "#888" : "#000")};
  font-weight: ${(props) => (props.light ? 300 : 500)};

  white-space: nowrap;
`;

const ProjectAndBotSelectModal = withCardon(
  function ProjectAndBotSelectModalContent({
    get,
    projectBots,
    botOptions,
    projectOptions,
    handleProjectChange,
    handleProjectBotChange,
  }) {
    const projects = useSelector(selectProjects);
    const currentProject = useSelector(selectCurrentProject);
    const currentBot = useSelector(selectCurrentBot);
    const { t } = useTranslation();

    return (
      <PRModal
        submitText={""}
        title={
          projects?.length > 1
            ? t("component.projectAndBotSelectModal.title")
            : t("component.projectAndBotSelectModal.titleBotOnly")
        }
        onClose={get(false)}
      >
        <Row className="g-2">
          {projects?.length > 1 && (
            <>
              <Col xs={12}>{t("common.project")}</Col>
              <Col xs={12}>
                <PRSelect
                  noBorder
                  className="w-100"
                  isClearable={false}
                  labelSelector={"name"}
                  options={projectOptions}
                  value={currentProject}
                  valueSelector={"id"}
                  onChange={handleProjectChange}
                />
              </Col>
            </>
          )}
          <Col xs={12}>Bot</Col>
          <Col xs={12}>
            {projectBots?.length > 1 ? (
              <PRSelect
                noBorder
                className="w-100"
                isClearable={false}
                labelSelector={"name"}
                options={botOptions}
                value={currentBot}
                valueSelector={"id"}
                onChange={handleProjectBotChange}
              />
            ) : (
              <StyledHeaderLabel light muted>
                {currentBot?.name}
              </StyledHeaderLabel>
            )}
          </Col>
        </Row>
      </PRModal>
    );
  },
  {
    destroyOnHide: true,
  }
);
export default function ProjectSelector() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const projects = useSelector(selectProjects);
  const bots = useSelector(selectBots);
  const currentProject = useSelector(selectCurrentProject);
  const currentBot = useSelector(selectCurrentBot);
  const isMd = useBootstrapBreakpoint("lg");

  const userInfo = useSelector(selectUserInfo);
  const livechatValidRoles = [userRole.admin, userRole.callCenterAgent];

  const hasLivechatAccess = livechatValidRoles.some((role) => currentProject?.permissions?.includes(role));

  const projectBots = useMemo(() => {
    const bots =
      currentProject?.chatbot_list ||
      Object.entries(currentProject?.chatbot_status || {}).map(([key, value]) => ({
        language: key,
        ...value,
      }));
    return bots;
  }, [currentProject]);

  async function handleProjectChange(project) {
    dispatch(setSelectedProject(project));

    const botsForProject = bots.filter((bot) => bot.projectId === project.id);
    const mainBotId = project?.main_bot;
    const targetBotId = mainBotId ?? botsForProject?.[0]?.id;

    const selectedBot = botsForProject.find((bot) => bot.id === targetBotId);

    if (selectedBot) {
      dispatch(setSelectedBot(selectedBot));
    }
    const pathnameParts = window.location.pathname.split(`/bot/${currentBot.id}`);
    if (pathnameParts.length > 1) {
      HistoryHelper.push(`/dashboard/project/${project.id}/bot/${selectedBot?.id}${pathnameParts[1]}`);
    } else {
      HistoryHelper.push(`/dashboard/project/${project.id}/bot/${selectedBot?.id}`);
    }
    if (hasLivechatAccess && userInfo.is_superuser) {
      const livechatOptions = selectLivechatOptions(store.getState());

      const newOptions = { ...livechatOptions };
      const filters = { ...newOptions?.filters };
      let newFilters = getLivechatFilterWithProjectFilterOverride(project?.id, filters);
      newOptions.filters = newFilters;
      dispatch(setLivechatOptions(newOptions));
    }
  }
  async function handleProjectBotChange(bot) {
    dispatch(setSelectedBot(bot));

    const pathnameParts = window.location.pathname.split(`/bot/${currentBot.id}`);
    if (pathnameParts.length > 1) {
      HistoryHelper.push(`/dashboard/project/${currentProject.id}/bot/${bot?.id}${pathnameParts[1]}`);
    } else {
      HistoryHelper.push(`/dashboard/project/${currentProject.id}/bot/${bot?.id}`);
    }
  }
  const { projectOptions, botOptions } = useMemo(() => {
    return {
      projectOptions: [
        {
          name: t("layout.projectSelector.projectOptionsName"),
          id: 1,
          options: projects,
        },
      ],
      botOptions: [
        {
          name: t("layout.projectSelector.botOptionsName"),
          id: 1,
          options: projectBots,
        },
      ],
    };
  }, [t, projects, projectBots]);

  const handleClickOpenProjectSelect = () => {
    ProjectAndBotSelectModal.show({
      projectBots,
      botOptions,
      projectOptions,
      handleProjectChange,
      handleProjectBotChange,
    });
  };
  return (
    <Row className="align-items-center g-0 flex-nowrap">
      {isMd ? (
        <Col xs>
          <PalButton variant="text" onClick={handleClickOpenProjectSelect}>
            <StyledHeaderLabel className="text-truncate">{currentProject?.name}</StyledHeaderLabel>
          </PalButton>
        </Col>
      ) : (
        <>
          <Col
            style={{
              flex: "0 1 auto",
              width: "auto",
            }}
          >
            {projects?.length > 1 ? (
              <StyledPRSelect
                noBorder
                labelSelector={"name"}
                options={projectOptions}
                value={currentProject}
                valueSelector={"id"}
                onChange={handleProjectChange}
                isClearable={false}
                // className="w-100"
              />
            ) : (
              <StyledHeaderLabel muted className="text-truncate">
                {currentProject?.name}
              </StyledHeaderLabel>
            )}
          </Col>
          {!!currentBot?.name && (
            <Col xs="auto">
              <HeaderDivider className="" />
            </Col>
          )}
          <Col
            style={{
              flex: "0 5 auto",
              width: "auto",
            }}
          >
            {projectBots?.length > 1 ? (
              <StyledPRSelect
                noBorder
                labelSelector={"name"}
                options={botOptions}
                value={currentBot}
                valueSelector={"id"}
                onChange={handleProjectBotChange}
                isClearable={false}
                // className="w-100"
              />
            ) : (
              <StyledHeaderLabel light muted className="text-truncate">
                {currentBot?.name}
              </StyledHeaderLabel>
            )}
          </Col>
        </>
      )}
    </Row>
  );
}
