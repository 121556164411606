import { useEffect, useState, useRef, useCallback } from "react";

import { useMonaco } from "@palamar/fe-library";
import { useSelector } from "react-redux";

import useCopyPasteHistory from "~common/hooks/useCopyPasteHistory";
import useLoading from "~common/hooks/useLoading";
import { apiUrlLowcode } from "~constants";
import Network from "~helpers/Network";
import { selectCurrentProject } from "~store/user/selectors";

const OPENING_BRACKETS = ["(", "[", "{"];
const CLOSING_BRACKETS = [")", "]", "}"];
const QUOTES = ['"', "'", "`"];

function codeBlockWithLanguage(text, language) {
  return "```" + language + "\n" + text + "\n```";
}

function createCompletionContext(contextName, contextData) {
  return `**${contextName}:**\n${contextData}`;
}

class CompletionFormatter {
  constructor(monaco, editor, position) {
    this._editor = editor;
    this._monaco = monaco;
    this._cursorPosition = position;
    const fullRange = editor.getFullModelRange();
    const lineEndPosition = fullRange ? fullRange.getEndPosition() : { lineNumber: 1, column: 1 };
    const textAfterRange = new this._monaco.Range(
      this._cursorPosition.lineNumber,
      this._cursorPosition.column,
      lineEndPosition.lineNumber,
      lineEndPosition.column
    );
    this._lineText = editor.getLineContent(this._cursorPosition.lineNumber);
    this._textAfterCursor = editor.getValueInRange(textAfterRange);
    this._characterBeforeCursor = this._lineText[this._cursorPosition.column - 2] || "";
    this._characterAfterCursor = this._lineText[this._cursorPosition.column] || "";
    this._lineCount = editor.getLineCount();
  }

  isMatchingPair(open, close) {
    return (
      (open === "(" && close === ")") ||
      (open === "[" && close === "]") ||
      (open === "{" && close === "}") ||
      (open === '"' && close === '"') ||
      (open === "'" && close === "'")
    );
  }

  matchCompletionBrackets() {
    let accumulated = "";
    const openBrackets = [];
    for (const char of this._originalCompletion) {
      if (OPENING_BRACKETS.includes(char)) {
        openBrackets.push(char);
      }
      if (CLOSING_BRACKETS.includes(char)) {
        if (openBrackets.length && this.isMatchingPair(openBrackets[openBrackets.length - 1], char)) {
          openBrackets.pop();
        } else {
          break;
        }
      }
      accumulated += char;
    }
    this._completion = accumulated.trimEnd() || this._originalCompletion.trimEnd();
    return this;
  }

  ignoreBlankLines() {
    if (this._completion.trimStart() === "" && this._originalCompletion !== "\n") {
      this._completion = this._completion.trim();
    }
    return this;
  }

  normalise(text) {
    return text?.trim();
  }

  removeDuplicateStart() {
    const beforeText = this._editor
      .getValueInRange(new this._monaco.Range(1, 1, this._cursorPosition.lineNumber, this._cursorPosition.column))
      .trim();
    const completionText = this.normalise(this._completion);
    let overlap = 0;
    const maxLength = Math.min(completionText.length, beforeText.length);
    for (let len = 1; len <= maxLength; len++) {
      const endBefore = beforeText.substring(beforeText.length - len);
      const startCompletion = completionText.substring(0, len);
      if (endBefore === startCompletion) {
        overlap = len;
      }
    }
    if (overlap > 0) {
      this._completion = this._completion.substring(overlap);
    }
    return this;
  }

  isCursorMidWord() {
    return (
      this._characterBeforeCursor && /\w/.test(this._characterBeforeCursor) && /\w/.test(this._characterAfterCursor)
    );
  }

  removeMiddleQuotes() {
    const startsWithQuote = QUOTES.includes(this._completion[0] || "");
    const endsWithQuote = QUOTES.includes(this._completion[this._completion.length - 1] || "");
    if (startsWithQuote && endsWithQuote) {
      this._completion = this._completion.substring(1);
    }
    if (endsWithQuote && this.isCursorMidWord()) {
      this._completion = this._completion.slice(0, -1);
    }
    return this;
  }

  preventDuplicateLines() {
    let nextLine = this._cursorPosition.lineNumber + 1;
    while (nextLine < this._cursorPosition.lineNumber + 3 && nextLine <= this._lineCount) {
      const lineContent = this._editor.getLineContent(nextLine);
      if (this.normalise(lineContent) === this.normalise(this._originalCompletion)) {
        this._completion = "";
        return this;
      }
      nextLine++;
    }
    return this;
  }

  removeInvalidBreaks() {
    if (this._completion.endsWith("\n")) {
      this._completion = this._completion.trimEnd();
    }
    return this;
  }

  getNewLineCount() {
    return this._completion.match(/\n/g) || [];
  }

  getLastLineLength() {
    const lines = this._completion.split("\n");
    return lines[lines.length - 1].length;
  }

  trimStart() {
    const firstNonSpace = this._completion.search(/\S/);
    if (firstNonSpace > this._cursorPosition.column - 1) {
      this._completion = this._completion.substring(firstNonSpace);
    }
    return this;
  }

  stripExtraText() {
    this._completion = this._completion
      .replace(/```.*\n/g, "")
      .replace(/```/g, "")
      .replace(/`/g, "")
      .replace(/# ?Suggestions?: ?/g, "");
    return this;
  }

  ignoreContextAtEdges() {
    const textAfter = this._textAfterCursor;
    const textBefore = this._editor.getValueInRange(
      new this._monaco.Range(1, 1, this._cursorPosition.lineNumber, this._cursorPosition.column)
    );
    const noTextAround = !textAfter || !textBefore;
    const contextMatch = this._normalisedCompletion.match(/\/\*\s*Language:\s*(.*)\s*\*\//);
    const extensionMatch = this._normalisedCompletion.match(/\/\*\s*File extension:\s*(.*)\s*\*\//);
    const commentMatch = this._normalisedCompletion.match(/\/\*\s*\*\//);
    if (noTextAround && (contextMatch || extensionMatch || commentMatch)) {
      this._completion = "";
    }
    return this;
  }

  formatCompletion(range) {
    const newLines = this.getNewLineCount();
    const lastLineLen = this.getLastLineLength();
    return {
      insertText: this._completion,
      range: {
        startLineNumber: this._cursorPosition.lineNumber,
        startColumn: this._cursorPosition.column,
        endLineNumber: this._cursorPosition.lineNumber + newLines.length,
        endColumn:
          this._cursorPosition.lineNumber === range.startLineNumber && newLines.length === 0
            ? this._cursorPosition.column + lastLineLen
            : lastLineLen,
      },
    };
  }

  format(insertText, range) {
    this._originalCompletion = insertText;
    this._normalisedCompletion = this.normalise(insertText);
    this._completion = "";
    return this.matchCompletionBrackets()
      .ignoreBlankLines()
      .removeDuplicateStart()
      .removeMiddleQuotes()
      .preventDuplicateLines()
      .removeInvalidBreaks()
      .trimStart()
      .stripExtraText()
      .ignoreContextAtEdges()
      .formatCompletion(range);
  }
}

function removeWrappedCode(text = "") {
  const start = text.indexOf("```");
  const end = text.lastIndexOf("```");
  if (start === -1 || end === -1) return text;
  const regex = /```.*?\n(?<code>.*?)\n```/s;
  const code = text.match(regex)?.groups?.code;
  return code || text;
}

const defaultProps = {
  language: "python",
  cacheSize: 10,
  getRelevantContext: () => "",
  onLoading: (_status) => {},
};

const AUTO_TRIGGER_DELAY_MS = 1000;

const useMonacoCodeCompletion = (editorBase, options) => {
  const editor = editorBase?.getEditorType() === "vs.editor.IDiffEditor" ? editorBase.getModifiedEditor() : editorBase;
  const { onLoading, language, cacheSize, getRelevantContext } = { ...defaultProps, ...options };
  const monaco = useMonaco();
  const [cachedSuggestions, setCachedSuggestions] = useState([]);
  const requestCancellationRef = useRef(new AbortController());
  const currentProject = useSelector(selectCurrentProject);
  const copyPasteHistory = useCopyPasteHistory(15);
  const [userInstructionsHistory, setUserInstructionsHistory] = useState([]);
  const [loading, q] = useLoading();

  const addUserInstructions = useCallback((instructions) => {
    setUserInstructionsHistory((prev) => {
      const updated = [instructions, ...prev];
      return updated.slice(0, 10);
    });
  }, []);

  const complete = useCallback(
    async (body, autoCompletion) => {
      if (!requestCancellationRef.current?.signal?.aborted) {
        requestCancellationRef.current.abort("AI completion request cancelled");
      }
      requestCancellationRef.current = new AbortController();
      try {
        onLoading(true);
        const apiUrl = autoCompletion
          ? apiUrlLowcode.getCompleteFast.format(currentProject?.id)
          : apiUrlLowcode.getCompletePrompt.format(currentProject?.id);
        const response = await q(
          Network.request(apiUrl, {
            method: "POST",
            data: body,
            signal: requestCancellationRef.current.signal,
          })
        );
        let code = response || "";
        return removeWrappedCode(code);
      } catch (error) {
        if (error.name === "AbortError") return "";
        console.error("Completion fetch error:", error);
        return "";
      } finally {
        onLoading(false);
      }
    },
    [currentProject?.id, q, onLoading]
  );

  const triggerCompletion = useCallback(
    async (userInstructions, auto = false) => {
      if (!editor) return;
      const model =
        editor.getEditorType() === "vs.editor.IDiffEditor" ? editor.getModifiedEditor().getModel() : editor.getModel();
      if (!model || !model.getValue()) return;

      const position = editor.getPosition();
      const currentLineContent = model.getLineContent(position.lineNumber);
      const codeBeforeCursor = currentLineContent.slice(0, position.column - 1);

      const textAfterCursor = currentLineContent.slice(position.column - 1);
      if (textAfterCursor.trim().length > 0) return;

      if (position.lineNumber < model.getLineCount()) {
        const nextLineContent = model.getLineContent(position.lineNumber + 1);
        if (nextLineContent.trim().length > 0) return;
      }

      const promptContextList = [];
      const selectedText = model.getValueInRange(editor.getSelection());
      const fullCode = model.getValue();

      promptContextList.push(createCompletionContext("Current File", codeBlockWithLanguage(fullCode, language)));
      promptContextList.push(
        createCompletionContext("Cursor Position", `Line ${position.lineNumber}, Column ${position.column}`)
      );
      if (codeBeforeCursor) {
        promptContextList.push(
          createCompletionContext("Code Before Cursor", codeBlockWithLanguage(codeBeforeCursor, language))
        );
      }
      if (selectedText) {
        promptContextList.push(createCompletionContext("Selected Code", codeBlockWithLanguage(selectedText, language)));
      }
      if (userInstructions) {
        promptContextList.push(createCompletionContext("User Intent", userInstructions));
        addUserInstructions(userInstructions);
      }
      if (copyPasteHistory.length > 0) {
        promptContextList.push(
          createCompletionContext("Copy-Paste History", codeBlockWithLanguage(JSON.stringify(copyPasteHistory), "json"))
        );
      }
      if (userInstructionsHistory.length > 0) {
        promptContextList.push(
          createCompletionContext(
            "User’s Previous Interactions",
            codeBlockWithLanguage(userInstructionsHistory.map((instr) => `- "${instr}"`).join("\n"), "plaintext")
          )
        );
      }
      const relevantContext = getRelevantContext?.();
      if (relevantContext) {
        promptContextList.push(createCompletionContext("Relevant Information", relevantContext));
      }
      const activeEditors = monaco.editor.getModels().map((model) => model.uri.path);
      const openFiles = activeEditors.filter((path) => path !== model.uri.path);
      if (openFiles.length > 0) {
        promptContextList.push(
          createCompletionContext("Open Files", codeBlockWithLanguage(openFiles.join("\n"), "plaintext"))
        );
      }

      const extraFields = userInstructions ? { code_to_edit: selectedText, existing_code: fullCode } : {};

      const newCompletion = await complete(
        {
          dynamic_context: promptContextList.join("\n\n"),
          language,
          current_file: fullCode,
          cursor_position: `Line ${position.lineNumber}, Column ${position.column}`,
          code_before_cursor: codeBeforeCursor,
          ...extraFields,
        },
        auto
      );

      if (newCompletion) {
        const newSuggestion = {
          insertText: newCompletion,
          range: {
            startLineNumber: position.lineNumber,
            startColumn: position.column,
            endLineNumber: position.lineNumber,
            endColumn: position.column,
          },
          textBeforeCursorOnCurrentLine: codeBeforeCursor,
          textBeforeCursor: codeBeforeCursor,
          selectedCode: selectedText,
          selection: editor.getSelection(),
        };

        if (userInstructions) {
          editor.executeEdits("inlineCompletion", [{ range: newSuggestion.range, text: newSuggestion.insertText }]);
        } else {
          setCachedSuggestions((prev) => [...prev.slice(-cacheSize + 1), newSuggestion]);
        }
      }
    },
    [editor, complete, language, cacheSize, monaco, copyPasteHistory, userInstructionsHistory, addUserInstructions]
  );

  useEffect(() => {
    if (!editor || !monaco) return;
    const model =
      editor.getEditorType() === "vs.editor.IDiffEditor" ? editor.getModifiedEditor().getModel() : editor.getModel();
    if (!model || model.getLanguageId() !== language) return;

    const actions = [];

    actions.push(
      editor.addAction({
        id: "triggerAIInlineSuggest",
        label: "Trigger AI Completion",
        keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyB],
        contextMenuGroupId: "AICompletion",
        run: () => triggerCompletion(null, true),
      })
    );

    actions.push(
      editor.addAction({
        id: "triggerAIInlineSuggestWithInstructions",
        label: "Trigger AI Completion with Instructions",
        keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyI],
        contextMenuGroupId: "AICompletion",
        run: () => {
          const instructions = prompt("Enter instructions for the AI:");
          if (instructions) triggerCompletion(instructions);
        },
      })
    );

    return () => {
      actions.forEach((action) => action.dispose());
    };
  }, [editor, monaco, triggerCompletion, language]);

  useEffect(() => {
    if (!editor || !monaco) return;
    const model =
      editor.getEditorType() === "vs.editor.IDiffEditor" ? editor.getModifiedEditor().getModel() : editor.getModel();
    if (!model || model.getLanguageId() !== language) return;

    const provider = monaco.languages.registerInlineCompletionsProvider(language, {
      provideInlineCompletions: async (model, position, context, token) => {
        if (token.isCancellationRequested) return { items: [] };

        const currentLine = model.getLineContent(position.lineNumber);
        const textBeforeCursorOnLine = currentLine.substring(0, position.column - 1);
        const textBeforeCursor = textBeforeCursorOnLine;

        const textAfterCursor = currentLine.substring(position.column - 1);
        if (textAfterCursor.trim().length > 0) {
          return { items: [] };
        }
        if (position.lineNumber < model.getLineCount()) {
          const nextLine = model.getLineContent(position.lineNumber + 1);
          if (nextLine.trim().length > 0) {
            return { items: [] };
          }
        }

        const localSuggestions = cachedSuggestions.filter((suggestion) => {
          if (
            suggestion.range.startLineNumber !== position.lineNumber ||
            suggestion.textBeforeCursor !== textBeforeCursor
          )
            return false;
          if (textBeforeCursorOnLine.startsWith(suggestion.textBeforeCursorOnCurrentLine)) {
            const rest = textBeforeCursorOnLine.substring(suggestion.textBeforeCursorOnCurrentLine.length);
            return suggestion.insertText.startsWith(rest);
          }
          return false;
        });

        localSuggestions.reverse();
        return {
          items: localSuggestions.map((suggestion) => {
            const formatter = new CompletionFormatter(monaco, model, position);
            const formatted = formatter.format(suggestion.insertText, suggestion.range);
            formatted.range = {
              ...formatted.range,
              endLineNumber: formatted.range.startLineNumber,
              endColumn: formatted.range.startColumn,
            };
            if (suggestion.selection) {
              formatted.range = {
                startLineNumber: suggestion.selection.startLineNumber,
                startColumn: suggestion.selection.startColumn,
                endLineNumber: suggestion.selection.endLineNumber,
                endColumn: suggestion.selection.endColumn,
              };
            }
            return formatted;
          }),
        };
      },
      freeInlineCompletions: () => {},
    });

    editor.trigger("inlineCompletion", "editor.action.inlineSuggest.trigger", {});
    return () => provider.dispose();
  }, [monaco, cachedSuggestions, language, editor]);

  useEffect(() => {
    if (!editor || !monaco) return;
    const inlineCompletionsController = editor.getContribution("editor.contrib.inlineCompletionsController");
    if (inlineCompletionsController && inlineCompletionsController.trigger) {
      inlineCompletionsController.trigger();
    } else {
      editor.trigger("inlineCompletion", "editor.action.inlineSuggest.trigger", {});
    }
  }, [cachedSuggestions, monaco, editor]);

  useEffect(() => {
    if (!editor || !monaco) return;
    const model =
      editor.getEditorType() === "vs.editor.IDiffEditor" ? editor.getModifiedEditor().getModel() : editor.getModel();
    if (!model || model.getLanguageId() !== language) return;

    let timer = null;
    let disposableListenModel;
    const listenModel = () => {
      if (disposableListenModel) {
        disposableListenModel.dispose();
      }
      const isDiffEditor = editor.getEditorType() === "vs.editor.IDiffEditor";
      const activeModel = isDiffEditor ? editor.getModifiedEditor().getModel() : editor.getModel();
      disposableListenModel = activeModel.onDidChangeContent(() => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          triggerCompletion(null, true);
        }, AUTO_TRIGGER_DELAY_MS);
      });
    };

    const contentListener = editor.onDidChangeModel(() => {
      listenModel();
    });
    listenModel();

    return () => {
      if (timer) clearTimeout(timer);
      contentListener.dispose();
    };
  }, [editor, monaco, triggerCompletion, language]);

  useEffect(() => {
    if (!editor || !monaco) return;
    const inlineCompletionsController = editor.getContribution("editor.contrib.inlineCompletionsController");
    if (inlineCompletionsController && inlineCompletionsController.trigger) {
      inlineCompletionsController.trigger();
    } else {
      editor.trigger("inlineCompletion", "editor.action.inlineSuggest.trigger", {});
    }
  }, [cachedSuggestions, monaco, editor]);

  return { loading };
};

export default useMonacoCodeCompletion;
