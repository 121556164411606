import { useState } from "react";

import { withCardon } from "cardon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";

import { Box } from "@mui/material";

import PRButton from "~components/Generic/PRButton";
import PRModal from "~components/Generic/PRModal";
import PRSelect from "~components/Generic/PRSelect";
import { getChatLabelList } from "~store/chatLabels/actions";
import { createOrUpdateChatLabelList } from "~store/socket/livechat/actions";
import { selectUserInfo } from "~store/user/selectors";

function SetSessionLabelModalContent({ get, projectId, botId, sessionId, sessionLabels }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userInfo = useSelector(selectUserInfo);
  const [labels, setLabels] = useState(sessionLabels?.length ? sessionLabels.map((item) => item?.label?.id) : []);

  const handleSubmit = async () => {
    // await dispatch(createOrUpdateChatLabel(projectId, sessionId, label));
    await dispatch(createOrUpdateChatLabelList(projectId, sessionId, labels));
    get({
      idList: labels,
    })();
  };

  const handleSearch = async (searchText, callback, signal, valueProp) => {
    const response = await dispatch(
      getChatLabelList(projectId, {
        params: {
          //   key__icontains: searchText,
          limit: 999, //TODO: support for key__icontains filter and reduce limit to 20
        },
        signal,
      })
    );
    const newResult = [];
    for (const result of response?.results || []) {
      if (result.users?.length && result.users.includes(userInfo.id)) {
        newResult.push(result);
      } else if (!result.users?.length) {
        newResult.push(result);
      }
    }
    return response?.results || [];
  };

  const handleClickDelete = async () => {
    // await dispatch(createOrUpdateChatLabel(projectId, sessionId, null));
    await dispatch(createOrUpdateChatLabelList(projectId, sessionId, []));
    get({
      idList: [],
    })();
  };

  //   const handleClickGoToLabels = () => {
  //     const newTabUrl = `/project/${projectId}/bot/${botId}/contactcenter/settings/chat-labels`;

  //     window.open(newTabUrl, "_blank");
  //   };

  return (
    <PRModal
      size="lg"
      title={t("component.setSessionLabelModal.title")}
      onClick={handleSubmit}
      onClose={get(false)}
      // onDelete={handleClickDelete}
    >
      <Row className="g-2">
        {/* <Col xs="12">
          <Label> Add text question to intent</Label>
        </Col> */}
        <Col className="d-flex justify-content-between align-items-center" xs="12">
          <Label>{t("common.label")}</Label>
          <PRButton
            link={`/dashboard/project/${projectId}/bot/${botId}/contactcenter/settings/chat-labels`}
            linkProps={{
              newTab: true,
              scope: "",
              noPrefix: true,
            }}
          >
            {t("component.setSessionLabelModal.manageLabels")}
          </PRButton>
        </Col>

        <Col xs="12">
          <PRSelect
            isMulti
            isPrimitiveValue
            lazy
            menuPortal
            className="w-100"
            isClearable={false}
            labelRenderer={(option) => {
              return (
                <Box alignItems="center" display="flex" gap={1}>
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: option.color,
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                  {option.key}
                </Box>
              );
            }}
            labelSelector="key"
            loadOptions={handleSearch}
            name="label"
            placeholder={t("component.setSessionLabelModal.selectLabelPlaceholder")}
            value={labels}
            valueSelector="id"
            onChange={setLabels}
          />
        </Col>
      </Row>
    </PRModal>
  );
}
const SetSessionLabelModal = withCardon(SetSessionLabelModalContent, { destroyOnHide: true });
export default SetSessionLabelModal;
