import PRSwitch from "~components/Generic/PRSwitch";

import DefaultMessageEdit from "./DefaultMessageEdit";
import DefaultMessageList from "./DefaultMessageList";

function DefaultMessage() {
  const routes = [
    { path: "/contactcenter/settings/canned-responses/form/:id?", component: DefaultMessageEdit },
    { path: "/contactcenter/settings/canned-responses/", component: DefaultMessageList },
    { path: "/contactcenter", to: "/contactcenter/settings/canned-responses", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default DefaultMessage;
