import PRSwitch from "~components/Generic/PRSwitch";

import AgentGroupsEdit from "./AgentGroupsEdit";
import AgentGroupsList from "./AgentGroupsList";

function AgentGroups() {
  const routes = [
    { path: "/contactcenter/settings/agent-groups/form/:id?", component: AgentGroupsEdit },
    { path: "/contactcenter/settings/agent-groups/", component: AgentGroupsList },
    { path: "/contactcenter", to: "/contactcenter/settings/agent-groups", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default AgentGroups;
