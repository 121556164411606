import { createRef, useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { Box, Chip } from "@mui/material";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import DialogHelper from "~helpers/DialogHelper";
import { getCallcenterSettings, updateCallcenterSettings } from "~store/socket/livechat/actions";
import { selectCallcenterSettings } from "~store/socket/livechat/selectors";
import { selectCurrentProject } from "~store/user/selectors";

const AgentGroupsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = createRef();
  const currentProject = useSelector(selectCurrentProject);

  const callcenterSettings = useSelector(selectCallcenterSettings);

  useEffect(() => {
    dispatch(getCallcenterSettings(currentProject.id));
  }, [currentProject.id, dispatch]);

  const actions = useMemo(() => {
    return [
      {
        label: t("common.createNew"),
        icon: MdAdd,
        color: "success",
        link: "/contactcenter/settings/agent-groups/form",
      },
    ];
  }, [t]);

  const columns = useMemo(() => {
    const handleClickDelete = (row) => async () => {
      if (await DialogHelper.showQuestionDeleteWithFormat(row.name)) {
        await dispatch(
          updateCallcenterSettings(
            currentProject.id,
            {
              ...callcenterSettings,
              agent_groups: [
                ...(callcenterSettings.agent_groups || [])
                  .filter((item) => item.id !== row.id)
                  .map((item) => ({
                    ...item,
                    agents: item.agents.map((agent) => agent.id),
                  })),
              ],
            },
            {
              updateAgentGroups: true,
            }
          )
        );

        await dispatch(getCallcenterSettings(currentProject.id));
        tableRef.current.refresh();
      }
    };
    return [
      {
        label: t("common.label"),
        key: "name",
      },
      {
        label: t("common.color"),
        key: "color",
        render: (row) => {
          return (
            <Box
              sx={{
                width: "20px",
                height: "20px",
                backgroundColor: row.color,
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            />
          );
        },
      },
      {
        label: t("common.agents"),
        key: "agents",
        render: (row) => {
          return (
            <Box alignItems="center" display="flex" gap={1}>
              {row.agents?.map((agent) => (
                <Chip key={agent.id} label={agent.name} size="small" />
              ))}
            </Box>
          );
        },
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        render: (row) => (
          <div className="gap-1 d-flex justify-content-center">
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/contactcenter/settings/agent-groups/form/${row.id}`}
              size="sm"
              tooltipText={t("common.edit")}
            />
            <PRButton
              outline
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText={t("common.delete")}
              onClick={handleClickDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [currentProject.id, dispatch, tableRef, t]);
  return (
    <PRContainer actions={actions} name={t("common.contactCenter")} parentName={t("dashboard.agentGroups")}>
      <PRTable ref={tableRef} columns={columns} data={callcenterSettings.agent_groups} />
    </PRContainer>
  );
};

export default AgentGroupsList;
