import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

export const initState = {
  closingCode: null,
};

const closingCode = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_CLOSING_CODE:
        draft.closingCode = action.payload;
        break;
    }
  });

export default closingCode;
