import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import useAsync from "~common/hooks/useAsync";
import useLoading from "~common/hooks/useLoading";
import ErrorBoundary from "~components/Generic/PRErrorBoundary";
import VerticalLayout from "~components/VerticalLayout";
import LoadingHelper from "~helpers/LoadingHelper";
import TokenHelper from "~helpers/TokenHelper";
import Utils from "~helpers/Utils";
import ChatbotEmbeddedPreview from "~pages/ChatbotEmbeddedPreview";
import LiveChat from "~pages/ContactCenter/LiveChat";
import Onboarding from "~pages/Onboarding";
import ChatbotPreview from "~pages/Onboarding/ChatbotPreview";
import { OfflineWrapper } from "~pages/public/Offline";
import { authProtectedRoutes } from "~routes";
import { getCurrentUser } from "~store/actions";
import { selectCurrentProject, selectProjects } from "~store/user/selectors";

import AuthorizedRedirect from "../components/AuthorizedRedirect";

// import LoadingOverlay from "~components/LoadingOverlay";

function GetWrappedRouteComponent(Component, componentProps) {
  return function WrappedRouteComponent(props) {
    return (
      <ErrorBoundary>
        <OfflineWrapper>
          {/* <Suspense fallback={<LoadingOverlay />}> */}
          <Component {...componentProps} {...props} />
          {/* </Suspense> */}
        </OfflineWrapper>
      </ErrorBoundary>
    );
  };
}
export function RouteMiddleware({ component, componentProps, ...rest }) {
  return <Route {...rest} render={GetWrappedRouteComponent(component, componentProps)} />;
}
function getInternalRedirectComponent({ to }) {
  return function InternalRedirectComponent() {
    return <AuthorizedRedirect to={to} />;
  };
}
const authProtectedRoutePrefix = "/dashboard/project/:projectId/bot/:botId";
function AuthProtectedRoutes() {
  const currentProject = useSelector(selectCurrentProject);
  const permissions = currentProject?.permissions || [];
  const filteredRoutes = authProtectedRoutes.filter((route) => {
    const routePermissions =
      route?.permissions?.length && typeof route?.permissions === "string" ? [route?.permissions] : route?.permissions;
    if (!currentProject?.id && route.noPermission) return true;
    if (routePermissions && Array.isArray(routePermissions)) {
      return routePermissions.some((permission) => permissions.includes(permission));
    }
    return false;
  });

  return (
    <Switch>
      {filteredRoutes.map((route) =>
        route.redirect ? (
          <RouteMiddleware
            key={route.path}
            component={getInternalRedirectComponent(route.to)}
            componentProps={route.componentProps}
            path={`${authProtectedRoutePrefix}${route.path}`}
          />
        ) : (
          <RouteMiddleware
            key={route.path}
            component={route.component}
            componentProps={route.componentProps}
            path={`${authProtectedRoutePrefix}${route.path}`}
          />
        )
      )}
      <RouteMiddleware
        key={`${authProtectedRoutePrefix}`}
        exact
        component={AuthorizedRedirect}
        path={`${authProtectedRoutePrefix}`}
      />
    </Switch>
  );
}
const blockUserRef = { current: false };
function RouteMiddlewareProtected({ location }) {
  const { value: token, status, loading: tokenLoading } = useAsync(TokenHelper.getJwtIfValid);
  const projects = useSelector(selectProjects);
  const [loading, q] = useLoading(true, [(!tokenLoading && !token) || projects]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!token || blockUserRef.current) {
      return;
    }
    const abortController = new AbortController();

    q(
      dispatch(
        getCurrentUser({
          signal: abortController.signal,
        })
      )
    );
    return () => {
      abortController.abort();
    };
  }, [dispatch, token, q]);

  useEffect(() => {
    if (loading) {
      LoadingHelper.open();
    } else {
      LoadingHelper.close();
    }
  }, [loading]);
  if (!tokenLoading && !token) {
    return <Redirect to={"/login" + Utils.qs({ return: location?.pathname })} />;
  }

  if (loading) {
    return null;
  }

  return (
    <Switch>
      <Route path="/dashboard/bot-preview">
        <ChatbotEmbeddedPreview />
      </Route>
      <Route path="/dashboard/setup/bot-preview">
        <ChatbotPreview />
      </Route>
      <Route path="/dashboard/setup/:id?">
        <Onboarding />
      </Route>
      <Route path="/dashboard/project/:projectId?/bot/:botId?/m/contactcenter/livechat/">
        <VerticalLayout noLayout isProtected={true}>
          <LiveChat />
        </VerticalLayout>
      </Route>
      <Route path="/dashboard/project/:projectId?/bot/:botId?">
        <VerticalLayout isProtected={true}>
          <AuthProtectedRoutes />
        </VerticalLayout>
      </Route>
      <Route exact component={AuthorizedRedirect} path="/dashboard" />
      <Redirect to="/dashboard" />
    </Switch>
  );
}

export default RouteMiddlewareProtected;
