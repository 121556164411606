import { useEffect, useMemo } from "react";

import { PalAutoComplete, PalTextField, PalTypography } from "@palamar/fe-library";
import { useFormik } from "formik";
import { SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { Box, Grid } from "@mui/material";

import useProjectChange from "~common/hooks/useProjectChange";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRPage from "~components/Generic/PRPage";
import PRPopover from "~components/Generic/PRPopover";
import { userRole } from "~constants";
import HistoryHelper from "~helpers/HistoryHelper";
import { createOrUpdateClosingCode, getClosingCode, setClosingCode } from "~store/closingCode/actions";
import { selectClosingCode } from "~store/closingCode/selectors";
import { getPermissionUserList } from "~store/settings/permission/actions";
import { selectPermissionUserList } from "~store/settings/permission/selectors";
import { getCallcenterSettings } from "~store/socket/livechat/actions";
import { selectCallcenterSettings } from "~store/socket/livechat/selectors";
import { selectCurrentProject } from "~store/user/selectors";

const ClosingCodeEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const dispatch = useDispatch();

  const currentProject = useSelector(selectCurrentProject);
  const closingCode = useSelector(selectClosingCode);
  const permissionUserList = useSelector(selectPermissionUserList);
  const callcenterSettings = useSelector(selectCallcenterSettings);

  const handleClickCancel = () => {
    HistoryHelper.goBack("/contactcenter/settings/closing-code/", { scope: "dashboard" });
  };

  useEffect(() => {
    dispatch(getPermissionUserList(currentProject.id));
    dispatch(getCallcenterSettings(currentProject?.id));
    if (!id) return;
    dispatch(getClosingCode(currentProject.id, id));

    return () => {
      dispatch(setClosingCode({}));
    };
  }, [dispatch, id, currentProject.id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: closingCode?.id || 0,
      key: closingCode?.key || "",
      color: closingCode?.color || "#ffffff",
      agents: (closingCode?.agents || []).map((item) => item.id),
      agent_groups: (closingCode?.agent_groups || []).map((item) => item.id),
    },
    validationSchema: Yup.object({
      key: Yup.string().required(t("component.formik.required").format(t("common.label"))),
    }),
    onSubmit: async (values) => {
      await dispatch(
        createOrUpdateClosingCode(currentProject.id, {
          ...values,
          agents: values.agents,
          agent_groups: values.agent_groups,
        })
      );
      handleClickCancel();
    },
  });

  useProjectChange(() => {
    HistoryHelper.push("/contactcenter/settings/closing-code/", { scope: "dashboard" });
  }, []);

  const parentName = useMemo(
    () => [
      {
        label: t("dashboard.closingCodes"),
        url: "/contactcenter/settings/closing-code",
      },
      {
        label: id ? t("dashboard.closingCodesEdit.editCode") : t("dashboard.closingCodesEdit.createCode"),
      },
    ],
    [id, t]
  );

  const handleChangeColor = (color) => {
    formik.setFieldValue("color", color.hex);
  };

  const permissionUserOptions = useMemo(() => {
    const ticketValidRoles = [userRole.AgentManager, userRole.admin, userRole.callCenterAgent];

    return permissionUserList
      .filter((item) => item?.roles?.some((role) => ticketValidRoles.includes(role)))
      .map((item) => ({
        label: `${item.firstname} ${item.lastname} (${item.email})`,
        value: item.id,
      }));
  }, [permissionUserList]);

  const handleChangeAgents = (e, agents) => {
    formik.setFieldValue("agents", agents);
  };
  const handleChangeAgentGroups = (e, agentGroups) => {
    formik.setFieldValue("agent_groups", agentGroups);
  };
  const agentGroupList = useMemo(() => {
    return (callcenterSettings?.agent_groups || []).map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [callcenterSettings]);

  return (
    <PRContainer name={t("common.contactCenter")} parentName={parentName}>
      <PRPage>
        <Grid
          container
          spacing={2}
          width={{
            xs: 1,
            sm: 1,
            md: 1 / 2,
          }}
        >
          <Grid item xs={12}>
            <PalTextField
              fullWidth
              invalid={formik.touched.key && formik.errors.key}
              label={t("dashboard.closingCodesEdit.labelPlaceholder")}
              name="key"
              value={formik.values.key}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <PalAutoComplete
              isPrimitiveValue
              multiple
              invalid={formik.touched.agents && formik.errors.agents}
              label={t("common.agents")}
              name="agents"
              options={permissionUserOptions}
              value={formik.values.agents}
              onBlur={formik.handleBlur}
              onChange={handleChangeAgents}
            />
          </Grid>
          <Grid item xs={12}>
            <PalAutoComplete
              isPrimitiveValue
              multiple
              invalid={formik.touched.agentGroups && formik.errors.agentGroups}
              label={t("dashboard.agentGroups.agentGroups")}
              name="agentGroups"
              options={agentGroupList}
              value={formik.values.agent_groups}
              onBlur={formik.handleBlur}
              onChange={handleChangeAgentGroups}
            />
          </Grid>
          <Grid item alignItems={"center"} display={"flex"} xs={12}>
            <PalTypography>{t("common.color")}</PalTypography>
            <Box ml={1}>
              <PRPopover
                content={
                  <>
                    <SketchPicker color={formik.values.color} onChange={handleChangeColor} />
                  </>
                }
              >
                <Box
                  sx={{
                    backgroundColor: formik.values.color || "#ffffff",
                    width: "20px",
                    height: "20px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
              </PRPopover>
            </Box>
          </Grid>
        </Grid>

        <Grid container mt={2}>
          <Grid item ml="auto">
            <PRButton outline className="me-2" onClick={handleClickCancel}>
              {t("common.cancel")}
            </PRButton>
            <PRButton onClick={formik.handleSubmit}>{id ? t("common.update") : t("common.create")}</PRButton>
          </Grid>
        </Grid>
      </PRPage>
    </PRContainer>
  );
};

export default ClosingCodeEdit;
