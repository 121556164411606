import { combineReducers } from "redux";

import agents from "./agents/reducer";
import announcement from "./announcement/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import chathistory from "./chathistory/reducer";
import chatLabels from "./chatLabels/reducer";
import closingCode from "./closingCode/reducer";
import custom from "./custom/reducer";
import dialogComponents from "./dialogComponents/reducer";
import helpdesk from "./helpdesk/reducer";
import alert from "./helpers/alert/reducer";
import dialog from "./helpers/dialog/reducer";
import loading from "./helpers/loading/reducer";
import integration from "./integration/reducer";
import knowledgeBase from "./knowledgeBase/reducer";
import layout from "./layout/reducer";
import lowcode from "./lowcode/reducer";
import notification from "./notification/reducer";
import onboarding from "./onboarding/reducer";
import organization from "./organization/reducer";
import platform from "./platform/reducer";
import reservationGenerator from "./reservationGenerator/reducer";
import settings from "./settings/reducer";
import socket from "./socket/reducer";
import statisticGenerator from "./statisticGenerator/reducer";
import statistics from "./statistics/reducer";
import theme from "./theme/reducer";
import user from "./user/user.reducer";

const rootReducer = combineReducers({
  agents,
  announcement,
  ForgetPassword,
  Login,
  Account,
  chathistory,
  chatLabels,
  custom,
  dialogComponents,
  helpdesk,
  alert,
  dialog,
  loading,
  integration,
  knowledgeBase,
  layout,
  notification,
  onboarding,
  organization,
  platform,
  settings,
  socket,
  statisticGenerator,
  reservationGenerator,
  statistics,
  theme,
  user,
  lowcode,
  closingCode,
});

export default rootReducer;
