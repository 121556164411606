import { apiUrlClosingCode } from "~constants";
import Network from "~helpers/Network";

import * as at from "./actionTypes";

export const setClosingCode = (payload) => ({
  type: at.SET_CLOSING_CODE,
  payload,
});

export const setClosingCodeList = (payload) => ({
  type: at.SET_CLOSING_CODE_LIST,
  payload,
});

export const getClosingCode =
  (projectId, id, options = {}) =>
  (_dispatch, _getState) => {
    const url = `${apiUrlClosingCode.get.format(projectId)}/${id}/`;
    return Network.request(url, {
      onSuccess: setClosingCode,
      loading: true,
      ...options,
    });
  };

export const getClosingCodeList =
  (projectId, params, options = {}) =>
  (_dispatch, _getState) => {
    return Network.request(apiUrlClosingCode.get.format(projectId), {
      params,
      loading: true,
      ...options,
    });
  };

export const createOrUpdateClosingCode = (projectId, data) => (_dispatch, _getState) => {
  const url = data.id
    ? apiUrlClosingCode.createOrUpdate.format(projectId, data.id)
    : apiUrlClosingCode.get.format(projectId);
  return Network.request(url, {
    method: data.id ? "PATCH" : "POST",
    data,
    loading: true,
    successMsg: true,
  });
};

export const deleteClosingCode = (projectId, id) => (_dispatch, _getState) => {
  const url = `${apiUrlClosingCode.get.format(projectId)}/${id}`;
  return Network.request(url, {
    method: "DELETE",
    loading: true,
    successMsg: true,
  });
};

export const setClosingCodeToSession = (projectId, sessionId, data) => (_dispatch, _getState) => {
  const url = apiUrlClosingCode.setToSession.format(projectId, sessionId);
  return Network.request(url, {
    method: "POST",
    data,
    loading: true,
    successMsg: true,
  });
};
