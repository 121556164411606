import PRSwitch from "~components/Generic/PRSwitch";

import AgentQueuesEdit from "./AgentQueuesEdit";
import AgentQueuesList from "./AgentQueuesList";

function AgentQueues() {
  const routes = [
    { path: "/contactcenter/settings/agent-queues/form/:id?", component: AgentQueuesEdit },
    { path: "/contactcenter/settings/agent-queues/", component: AgentQueuesList },
    { path: "/contactcenter", to: "/contactcenter/settings/agent-queues", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default AgentQueues;
