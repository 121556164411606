import { useEffect, useState } from "react";

import { useFormik } from "formik";
import { isValidNumber } from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import { MdAdd, MdDelete, MdHelp } from "react-icons/md";
import ReactPlayer from "react-player/lazy";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import styled from "@emotion/styled";

import useProjectChange from "~common/hooks/useProjectChange";
import PRAlert from "~components/Generic/PRAlert";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRDropZone from "~components/Generic/PRDropZone";
import PRFormFeedback from "~components/Generic/PRFormFeedback";
import PRInput, { PRTextArea } from "~components/Generic/PRInput";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import PRTooltip from "~components/Generic/PRTooltip";
import PalPhoneNumber from "~components/mui/PalPhoneNumber";
import {
  chatbotGupshupButtonType,
  chatbotGupshupButtonTypeOptions,
  chatbotGupshupCategory,
  chatbotGupshupCategoryOptions,
  chatbotGupshupLanguage,
  chatbotGupshupLanguageOptions,
  chatbotGupshupTemplateType,
  chatbotGupshupTemplateTypeOptions,
} from "~constants";
import HistoryHelper from "~helpers/HistoryHelper";
import Network from "~helpers/Network";
import { createOrUpdateWhatsappTemplate, getWhatsappTemplate, setWhatsappTemplate } from "~store/platform/actions";
import { selectWhatsappTemplate } from "~store/platform/selectors";
import { selectCurrentProject } from "~store/user/selectors";

const StyledPRContainer = styled(PRContainer)`
  .whatsapp-buttons {
    background-color: #fff;
    border-radius: 7px;
    margin: 10px 0;
    padding: 10px;
  }
`;

const WhatsappTemplateMessageEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const whatsappTemplate = useSelector(selectWhatsappTemplate);
  const currentProject = useSelector(selectCurrentProject);
  const [loadedMediaFileItem, setLoadedMediaFileItem] = useState(null);

  const dispatch = useDispatch();

  const handleClickCancel = () => {
    HistoryHelper.goBack("/contactcenter/livechat/whatsapp-template-messages/", { scope: "dashboard" });
  };

  useEffect(() => {
    if (!id) return;
    dispatch(getWhatsappTemplate(currentProject.id, id));

    return () => {
      dispatch(setWhatsappTemplate({}));
    };
  }, [dispatch, id, currentProject.id]);
  useEffect(() => {
    if (!loadedMediaFileItem && whatsappTemplate?.media_file) {
      //fetch binary file and create File object
      Network.request(whatsappTemplate?.media_file, {
        method: "GET",
        responseType: "blob",
        rawResponse: true,
      }).then((response) => {
        const file = new File(
          [response.data],
          whatsappTemplate?.response_data?.templateType === chatbotGupshupTemplateType.IMAGE
            ? "image.png"
            : whatsappTemplate?.response_data?.templateType === chatbotGupshupTemplateType.VIDEO
              ? "video.mp4"
              : whatsappTemplate?.response_data?.templateType === chatbotGupshupTemplateType.DOCUMENT
                ? "document.pdf"
                : "file"
        );

        setLoadedMediaFileItem(file);
      });

      setLoadedMediaFileItem(whatsappTemplate?.media_file);
    }
  }, [dispatch, id, currentProject.id, loadedMediaFileItem, whatsappTemplate]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...whatsappTemplate,

      media_file: whatsappTemplate?.media_file || null,
      data: {
        ...whatsappTemplate?.data,
        buttons: (() => {
          if (whatsappTemplate?.data?.buttons) {
            let parsed = JSON.parse(whatsappTemplate.data.buttons);
            if (typeof parsed === "string" && parsed.startsWith("[")) {
              //INFO: Temporary fix for buttons array parsing issue for old templates
              parsed = JSON.parse(parsed);
            }
            if (parsed.length > -1) {
              return parsed;
            }
          }
          return [];
        })(),
        allowTemplateCategoryChange:
          typeof whatsappTemplate?.data?.allowTemplateCategoryChange === "boolean"
            ? whatsappTemplate?.data?.allowTemplateCategoryChange
            : true,
        enableSample: true,
        category: whatsappTemplate?.data?.category || chatbotGupshupCategory.MARKETING,
        languageCode: whatsappTemplate?.data?.languageCode || chatbotGupshupLanguage.tr,
        templateType: whatsappTemplate?.data?.templateType || chatbotGupshupTemplateType.TEXT,
        addSecurityRecommendation: whatsappTemplate?.data?.addSecurityRecommendation || false,
        content: whatsappTemplate?.data?.content || "",
        example: whatsappTemplate?.data?.example || "",
        exampleHeader: whatsappTemplate?.data?.exampleHeader || "",
        footer: whatsappTemplate?.data?.footer || "",
        header: whatsappTemplate?.data?.header || "",
        vertical: whatsappTemplate?.data?.vertical || "",
        elementName: whatsappTemplate?.data?.elementName || "",
      },
    },
    validationSchema: Yup.object({
      media_file: Yup.mixed().when("templateType", {
        is: (val) =>
          [
            chatbotGupshupTemplateType.IMAGE,
            chatbotGupshupTemplateType.VIDEO,
            chatbotGupshupTemplateType.DOCUMENT,
          ].includes(val) && !whatsappTemplate?.id,

        then: Yup.mixed().required("Required"),
      }),

      data: Yup.object({
        elementName: Yup.string()
          .required(t("component.formik.required").format(t("common.name")))
          .test("small_under_and_numbers", t("dashboard.whatsappTemplateMessageEdit.smallUnderAndNumbers"), (value) => {
            return /^[a-z0-9_]+$/.test(value);
          })
          .max(100, t("component.formik.maxLength").format(t("common.name"), "100")),
        category: Yup.string().required(t("component.formik.required").format(t("common.category"))),
        vertical: Yup.string()
          .required(t("component.formik.required").format(t("dashboard.whatsappTemplateMessageEdit.vertical")))
          .max(180, t("component.formik.maxLength").format(t("dashboard.whatsappTemplateMessageEdit.vertical"), "180")),
        languageCode: Yup.string().required(t("component.formik.required").format(t("common.language"))),
        templateType: Yup.string().required(t("component.formik.required").format(t("common.template"))),
        content: Yup.string()
          .required(t("component.formik.required").format(t("common.content")))
          .max(1028, t("component.formik.maxLength").format(t("common.content"), "1028")),
        example: Yup.string()
          .required(t("component.formik.required").format(t("common.example")))
          .max(1028, t("component.formik.maxLength").format(t("common.example"), "1028")),
        footer: Yup.string().when("category", {
          is: (val) => val !== chatbotGupshupCategory.AUTHENTICATION,
          then: Yup.string().max(
            60,
            t("component.formik.maxLength").format(t("dashboard.whatsappTemplateMessageEdit.footer"), "60")
          ),
        }),
        header: Yup.string().when("category", {
          is: (val) => val !== chatbotGupshupCategory.AUTHENTICATION,
          then: Yup.string().max(
            60,
            t("component.formik.maxLength").format(t("dashboard.whatsappTemplateMessageEdit.header"), "60")
          ),
        }),
        exampleHeader: Yup.string().max(
          60,
          t("component.formik.maxLength").format(t("dashboard.whatsappTemplateMessageEdit.exampleHeader"), "60")
        ),

        buttons: Yup.array()
          .nullable()
          .when("category", {
            is: (val) => val !== chatbotGupshupCategory.AUTHENTICATION,
            then: Yup.array()
              .of(
                Yup.object({
                  type: Yup.string().required(t("component.formik.required").format(t("common.type"))),
                  text: Yup.string().when("type", {
                    is: chatbotGupshupButtonType.QUICK_REPLY,
                    then: Yup.string()
                      .required(t("component.formik.required").format(t("common.text")))
                      .max(60, t("component.formik.maxLength").format(t("common.text"), "60")),
                  }),
                  url: Yup.string().when("type", {
                    is: chatbotGupshupButtonType.URL,
                    then: Yup.string()
                      .required(t("component.formik.required").format(t("common.url")))
                      .url(t("component.formik.invalid").format(t("common.url"))),
                  }),
                  phone_number: Yup.string().when("type", {
                    is: chatbotGupshupButtonType.PHONE_NUMBER,
                    then: Yup.string()
                      .required(t("component.formik.required").format(t("common.phoneNumber")))
                      .test(
                        "phone_number",
                        t("component.formik.invalid").format(t("common.phoneNumber")),
                        (val, context) => {
                          if (!val) return true;
                          let isValid = false;
                          try {
                            isValid = isValidNumber(val);
                          } catch (e) {}

                          return isValid;
                        }
                      ),
                  }),
                })
              )
              .test("max_2_url_buttons", t("dashboard.whatsappTemplateMessageEdit.max2UrlButtons"), (value) => {
                return value.filter((item) => item.type === chatbotGupshupButtonType.URL).length <= 2;
              })
              .test(
                "max_1_phone_number_button",
                t("dashboard.whatsappTemplateMessageEdit.max1PhoneNumberButton"),
                (value) => {
                  return value.filter((item) => item.type === chatbotGupshupButtonType.PHONE_NUMBER).length <= 1;
                }
              )
              .test(
                "max_10_quick_reply_buttons",
                t("dashboard.whatsappTemplateMessageEdit.max10QuickReplyButtons"),
                (value) => {
                  return value.filter((item) => item.type === chatbotGupshupButtonType.QUICK_REPLY).length <= 10;
                }
              ),
          }),
      }),
    }),
    onSubmit: async (values) => {
      // const buttonsStr = JSON.stringify(values.data.buttons);
      const payload = {
        template_type: values.data.templateType,

        ...((values.data.templateType === chatbotGupshupTemplateType.IMAGE ||
          values.data.templateType === chatbotGupshupTemplateType.VIDEO ||
          values.data.templateType === chatbotGupshupTemplateType.DOCUMENT) && {
          media_file: values.media_file,
        }),

        data: {
          ...values.data,
          // buttons: buttonsStr,
        },
        id: values.id,
      };
      await dispatch(createOrUpdateWhatsappTemplate(currentProject.id, payload));
      handleClickCancel();
    },
  });

  useProjectChange(() => {
    HistoryHelper.push("/contactcenter/settings/canned-responses/", { scope: "dashboard" });
  }, []);

  const parentName = [
    {
      label: t("common.livechat"),
      url: "/contactcenter/livechat/",
    },
    {
      label: t("dashboard.whatsappTemplateMessageEdit.whatsappTemplateMessages"),
      url: "/contactcenter/livechat/whatsapp-template-messages/",
    },
    {
      label: id ? t("dashboard.whatsappTemplateMessageEdit.edit") : t("dashboard.whatsappTemplateMessageEdit.create"),
    },
  ];

  const handleChangeSelect = (key) => (value) => {
    formik.setFieldValue(key, value);
  };

  const handleClickAddButton = () => {
    formik.setFieldValue("data.buttons", [
      ...(formik.values.data?.buttons || []),
      {
        type: chatbotGupshupButtonType.QUICK_REPLY,
        text: "",
      },
    ]);
  };

  //   const exampleBody = useMemo(() => {
  //     //find {{1}} in body and replace it with example

  //     const regex = /{{\d}}/g;
  //     const body = formik.values.data?.content;
  //     const matches = body?.match(regex);
  //     if (!matches) return body;
  //     let exampleBody = body;
  //     matches.forEach((match) => {
  //       exampleBody = exampleBody.replace(match, `[Example ${match.replace(/[{}]/g, "")}]`);
  //     });
  //     return exampleBody;
  //   }, [formik.values.data?.content]);

  const handleChangeFile = (files) => {
    formik.setFieldValue("media_file", files[0]);
  };

  return (
    <StyledPRContainer
      description={t("dashboard.whatsappTemplateMessageEdit.description")}
      name={t("common.contactCenter")}
      parentName={parentName}
    >
      {id && <PRAlert color="warning">{t("dashboard.whatsappTemplateMessageEdit.editWarning")}</PRAlert>}
      <PRPage>
        <Row className="g-2">
          <Col md="6" xs="12">
            <Label>{t("common.name")}</Label>
            <PRInput
              disabled={!!id}
              invalid={formik.touched.data?.elementName && formik.errors.data?.elementName}
              name="data.elementName"
              placeholder={t("dashboard.whatsappTemplateMessageEdit.namePlaceholder")}
              value={formik.values.data?.elementName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          <Col md="6" xs="12">
            <div>
              <Label>{t("dashboard.whatsappTemplateMessageEdit.vertical")}</Label>
              <PRTooltip title={t("dashboard.whatsappTemplateMessageEdit.verticalTooltip")}>
                <span>
                  <MdHelp className="fs-5 ms-1" />
                </span>
              </PRTooltip>
            </div>
            <PRInput
              disabled={!!id}
              invalid={formik.touched.data?.vertical && formik.errors.data?.vertical}
              name="data.vertical"
              placeholder={t("dashboard.whatsappTemplateMessageEdit.verticalPlaceholder")}
              value={formik.values.data?.vertical}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          <Col md="6" xs="12">
            <Label>{t("common.category")}</Label>
            <PRSelect
              isPrimitiveValue
              invalid={formik.touched.data?.category && formik.errors.data?.category}
              isDisabled={!!id}
              name="data.category"
              options={chatbotGupshupCategoryOptions}
              placeholder={t("dashboard.whatsappTemplateMessageEdit.categoryPlaceholder")}
              value={formik.values.data?.category}
              onBlur={formik.handleBlur}
              onChange={handleChangeSelect("data.category")}
            />
          </Col>
          <Col md="6" xs="12">
            <Label>{t("common.type")}</Label>
            <PRSelect
              isPrimitiveValue
              invalid={formik.touched.data?.templateType && formik.errors.data?.templateType}
              isDisabled={!!id}
              name="data.templateType"
              options={chatbotGupshupTemplateTypeOptions}
              placeholder={t("dashboard.whatsappTemplateMessageEdit.typePlaceholder")}
              value={formik.values.data?.templateType}
              onBlur={formik.handleBlur}
              onChange={handleChangeSelect("data.templateType")}
            />
          </Col>
          <Col lg="12">
            <Label>{t("common.language")}</Label>
            <PRSelect
              isPrimitiveValue
              invalid={formik.touched.data?.languageCode && formik.errors.data?.languageCode}
              isDisabled={!!id}
              name="data.languageCode"
              options={chatbotGupshupLanguageOptions}
              placeholder={t("dashboard.whatsappTemplateMessageEdit.languagePlaceholder")}
              value={formik.values.data?.languageCode}
              onBlur={formik.handleBlur}
              onChange={handleChangeSelect("data.languageCode")}
            />
          </Col>
          {formik.values.data?.category !== chatbotGupshupCategory.AUTHENTICATION && (
            <>
              <Col xs="12">
                <Label>{t("dashboard.whatsappTemplateMessageEdit.header")}</Label>
                <PRInput
                  invalid={formik.touched.data?.header && formik.errors.data?.header}
                  name="data.header"
                  placeholder="Hello, {{1}}"
                  value={formik.values.data?.header}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Col>
              <Col xs="12">
                <div>
                  <Label>{t("dashboard.whatsappTemplateMessageEdit.exampleHeader")}</Label>
                  <PRTooltip title={t("dashboard.whatsappTemplateMessageEdit.exampleHeaderTooltip")}>
                    <span>
                      <MdHelp className="fs-5 ms-1" />
                    </span>
                  </PRTooltip>
                </div>
                <PRInput
                  invalid={formik.touched.data?.exampleHeader && formik.errors.data?.exampleHeader}
                  name="data.exampleHeader"
                  placeholder={t("dashboard.whatsappTemplateMessageEdit.exampleHeaderPlaceholder")}
                  value={formik.values.data?.exampleHeader}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Col>
            </>
          )}
          <Col lg="12">
            <Label>{t("common.body")}</Label>
            <PRTextArea
              invalid={formik.touched.data?.content && formik.errors.data?.content}
              name="data.content"
              placeholder={t("dashboard.whatsappTemplateMessageEdit.bodyPlaceholder")}
              rows={5}
              value={formik.values.data?.content}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          <Col lg="12">
            <div>
              <Label>{t("dashboard.whatsappTemplateMessageEdit.exampleBody")}</Label>
              <PRTooltip title={t("dashboard.whatsappTemplateMessageEdit.exampleBodyTooltip")}>
                <span>
                  <MdHelp className="fs-5 ms-1" />
                </span>
              </PRTooltip>
            </div>
            <PRTextArea
              invalid={formik.touched.data?.example && formik.errors.data?.example}
              name="data.example"
              placeholder={t("dashboard.whatsappTemplateMessageEdit.exampleBodyPlaceholder")}
              rows={5}
              value={formik.values.data?.example}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
          {[
            chatbotGupshupTemplateType.IMAGE,
            chatbotGupshupTemplateType.VIDEO,
            chatbotGupshupTemplateType.DOCUMENT,
          ].includes(formik.values.data?.templateType) && (
            <Col xs="12">
              <Label>{t("dashboard.whatsappTemplateMessageEdit.exampleMedia")}</Label>
              {!id && (
                <PRDropZone
                  accept={
                    formik.values.data?.templateType === chatbotGupshupTemplateType.IMAGE
                      ? ["image/png", "image/jpeg"]
                      : formik.values.data?.templateType === chatbotGupshupTemplateType.VIDEO
                        ? ["video/*"]
                        : []
                  }
                  invalid={formik.touched?.media_file && formik.errors?.media_file}
                  onFileChange={handleChangeFile}
                />
              )}
              {loadedMediaFileItem && (
                <div className="mt-2">
                  {formik.values.data?.templateType === chatbotGupshupTemplateType.IMAGE && (
                    <img alt="template" height={300} src={whatsappTemplate?.media_url} width={"auto"} />
                  )}
                  {formik.values.data?.templateType === chatbotGupshupTemplateType.VIDEO && (
                    <ReactPlayer controls height={300} url={whatsappTemplate?.media_url} width={"auto"} />
                  )}
                  {formik.values.data?.templateType === chatbotGupshupTemplateType.DOCUMENT && (
                    <a href={whatsappTemplate?.media_url} rel="noreferrer" target="_blank">
                      {whatsappTemplate?.media_url}
                    </a>
                  )}
                </div>
              )}
            </Col>
          )}
          {formik.values.data?.category !== chatbotGupshupCategory.AUTHENTICATION && (
            <Col xs="12">
              <Label>{t("dashboard.whatsappTemplateMessageEdit.footer")}</Label>
              <PRInput
                invalid={formik.touched.data?.footer && formik.errors.data?.footer}
                name="data.footer"
                placeholder={t("dashboard.whatsappTemplateMessageEdit.footerPlaceholder")}
                value={formik.values.data?.footer}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Col>
          )}
          {formik.values.data?.category !== chatbotGupshupCategory.AUTHENTICATION && (
            <Col lg="12">
              <div className="d-flex align-items-center justify-content-between">
                <Label>{t("dashboard.whatsappTemplateMessageEdit.buttons")}</Label>
                <PRButton
                  color="success-600"
                  disabled={formik.values.data?.buttons?.length >= 10}
                  icon={MdAdd}
                  size="sm"
                  onClick={handleClickAddButton}
                />
              </div>
              {formik.values.data?.buttons?.map((item, index) => {
                const handleChangePhoneNumber = async (data, phoneObj) => {
                  formik.setFieldValue(`data.buttons.${index}.phone_number`, phoneObj.numberValue);
                };
                const handleRemoveButton = () => {
                  const buttons = [...(formik.values.data?.buttons || []).filter((_, i) => i !== index)];
                  formik.setFieldValue("data.buttons", buttons);
                };
                return (
                  <Row key={index} className="g-2 whatsapp-buttons">
                    <Col className="gap-2 d-flex align-items-end justify-content-between w-100" md="12" xs="12">
                      <div className="w-100">
                        <div className="d-flex align-items-center justify-content-between">
                          <Label>{t("common.type")}</Label>
                          <PRButton outline color="danger" icon={MdDelete} size="sm" onClick={handleRemoveButton} />
                        </div>
                        <PRSelect
                          isPrimitiveValue
                          className="w-md-50"
                          invalid={formik.errors.data?.buttons?.[index]?.type}
                          isClearable={false}
                          name={`data.buttons.${index}.type`}
                          options={chatbotGupshupButtonTypeOptions}
                          placeholder={t("dashboard.whatsappTemplateMessageEdit.typePlaceholder")}
                          value={item.type}
                          onBlur={formik.handleBlur}
                          onChange={handleChangeSelect(`data.buttons.${index}.type`)}
                        />
                      </div>
                    </Col>
                    {item.type === chatbotGupshupButtonType.URL && (
                      <Col md xs="12">
                        <Label>{t("common.url")}</Label>
                        <PRInput
                          invalid={formik.errors.data?.buttons?.[index]?.url}
                          name={`data.buttons.${index}.url`}
                          placeholder="https://example.com/foo/{{1}}"
                          value={item.url}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Col>
                    )}
                    {item.type === chatbotGupshupButtonType.PHONE_NUMBER && (
                      <Col md xs="12">
                        <Label>{t("common.phoneNumber")}</Label>
                        <div className="w-md-50">
                          <PalPhoneNumber
                            fullWidth
                            reactstrapMode
                            invalid={formik.errors.data?.buttons?.[index]?.phone_number}
                            name={`data.buttons.${index}.phone_number`}
                            size="small"
                            value={item.phone_number}
                            onBlur={formik.handleBlur}
                            onChange={handleChangePhoneNumber}
                          />
                        </div>
                      </Col>
                    )}
                    {(item.type === chatbotGupshupButtonType.QUICK_REPLY ||
                      item.type === chatbotGupshupButtonType.URL) && (
                      <Col md="12" xs="12">
                        <Label>{t("common.text")}</Label>
                        <PRInput
                          invalid={formik.errors.data?.buttons?.[index]?.text}
                          name={`data.buttons.${index}.text`}
                          placeholder={t("dashboard.whatsappTemplateMessageEdit.textPlaceholder")}
                          value={item.text}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                        />
                      </Col>
                    )}
                  </Row>
                );
              })}
              <PRFormFeedback
                invalid={typeof formik.errors.data?.buttons === "string" && formik.errors.data?.buttons}
              />
            </Col>
          )}
          {formik.values.data?.category === chatbotGupshupCategory.AUTHENTICATION && (
            <Col lg="12">
              <Label>{t("dashboard.whatsappTemplateMessageEdit.addSecurityRecommendation")}</Label>
              <PRInput
                checked={formik.values.data?.addSecurityRecommendation}
                invalid={
                  formik.touched.data?.addSecurityRecommendation && formik.errors.data?.addSecurityRecommendation
                }
                name="data.addSecurityRecommendation"
                type="checkbox"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
            </Col>
          )}
          <Col lg="12">
            <Label>{t("dashboard.whatsappTemplateMessageEdit.allowTemplateCategoryChange")}</Label>
            <PRInput
              checked={formik.values.data?.allowTemplateCategoryChange}
              disabled={!!id}
              invalid={
                formik.touched.data?.allowTemplateCategoryChange && formik.errors.data?.allowTemplateCategoryChange
              }
              name="data.allowTemplateCategoryChange"
              type="checkbox"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>

        <Row className="justify-content-end mt-2">
          <Col xs="auto">
            <PRButton outline className="me-2" onClick={handleClickCancel}>
              {t("common.cancel")}
            </PRButton>
            <PRButton onClick={formik.handleSubmit}>{id ? t("common.update") : t("common.create")}</PRButton>
          </Col>
        </Row>
      </PRPage>
    </StyledPRContainer>
  );
};

export default WhatsappTemplateMessageEdit;
