import { livechatCallcenterType } from "~constants";
import DateHelper from "~helpers/DateHelper";
import { safeProduce as produce } from "~helpers/immer";

import * as at from "./actionTypes";

/**
 * @typedef {typeof chatbotSessionStatus} ChatbotSessionStatus
 *
 * @typedef {keyof ChatbotSessionStatus} ChatbotSessionStatusKeys
 *
 * @typedef {ChatbotSessionStatus[ChatbotSessionStatusKeys]} ChatbotSessionStatusValues
 *
 * @typedef {"oth" | "doc" | "vid" | "img" | "aud"} FileType
 *
 * @typedef {{
 *   file_name: string;
 *   file_type: FileType;
 *   file_url: string;
 *   message: number;
 *   status: boolean;
 *   uuid: string;
 * }} File
 *
 *
 * @typedef {{ action_type: "connect"; type: "bot_action"; bot_info: { id: string; name: string } }} ChatMessageBotConnect
 *
 *
 * @typedef {{ data: string; type: "text" }} ChatMessageText
 *
 * @typedef {{
 *   data: File;
 *   type: "file";
 * }} ChatMessageFile
 *
 * @typedef {{ data: { file_url: string }; type: "bot_file" }} ChatMessageBotFile
 *
 * @typedef {{ value: number; type: "button_select" }} ChatMessageButtonSelect
 *
 * @typedef {{ data: { image_url: string }; type: "image" }} ChatMessageImageFile
 *
 * @typedef {{ msg: string; input_type: "File"; type: "file_request" }} ChatMessageInputRequest
 *
 * @typedef {{
 *   choices: { text: string; type: "button"; value: number; button_type: "select" }[];
 *   type: "choice";
 *   text: string;
 * }} ChatMessageChoices
 *
 *
 * @typedef {ChatMessageBotConnect
 *   | ChatMessageText
 *   | ChatMessageFile
 *   | ChatMessageBotFile
 *   | ChatMessageButtonSelect
 *   | ChatMessageImageFile
 *   | ChatMessageInputRequest
 *   | ChatMessageChoices} ChatMessageData
 *
 *
 * @typedef {{
 *   id: number;
 *   session: number;
 *   sender_type: "BOT" | "CUS" | "AGENT" | "SYS";
 *   customer: number;
 *   agent?: AgentInfo;
 *   bot: number;
 *   data: ChatMessageData;
 *   likes?: any[];
 *   files?: File[];
 *   created: string;
 * }} ChatMessage
 *
 *
 * @typedef {{
 *   id: number;
 *   name: string;
 *   status: "UN" | "AV";
 *   max_active_chat_count: number;
 *   active_session_count: number;
 *   active_sessions: [];
 * }} AgentInfo
 */

/**
 * @typedef {{
 *   id: number;
 *   bot: number;
 *   project: number;
 *   bot_name: string;
 *   session_type: "RTM" | "RTM2" | "OTHER";
 *   chatbot_session: number;
 *   session_status: ChatbotSessionStatusValues;
 *   last_status: ChatbotSessionStatusValues;
 *   customer: CustomerType;
 *   agent: AgentInfo | null;
 *   last_agent: AgentInfo | null;
 *   messages: ChatMessage[];
 *   agent_wait_time: string;
 *   updated: string;
 *   created: string;
 *   source: "OUTGOING" | "WAITING" | "BOT";
 *   interactions: {
 *     closed: boolean;
 *     connection_id: number;
 *     online: boolean;
 *     focused: boolean;
 *     typing: boolean;
 *     location: string;
 *   }[];
 *   connections: {
 *     connection_id: number;
 *     started: string;
 *     last_access: string;
 *     location: string;
 *     referer: string;
 *     focused: boolean;
 *     online: boolean;
 *     metadata: any;
 *   }[];
 *   listen_type?: "all" | "status_only" | "offline";
 * }} CustomerSession
 */

/**
 * @typedef CustomerType
 * @property {number} id
 * @property {string} full_name
 * @property {string} email
 * @property {string} identifier
 * @property {string} identifier_value
 * @property {number} project
 * @property {string} created
 */

// https://transform.tools/json-to-jsdoc

/**
 * @typedef {object} CallcenterSetting
 * @property {number} id
 * @property {string} callcenter_type
 * @property {boolean} agent_can_leave_session
 * @property {boolean} show_bot_sessions
 * @property {boolean} session_label_enabled
 * @property {boolean} session_label_mandatory
 * @property {boolean} session_closing_code_enabled
 * @property {boolean} session_closing_code_mandatory
 * @property {object[]} queues
 * @property {number} queues.id
 * @property {string} queues.key
 * @property {string} queues.name
 * @property {object[]} queues.agents
 * @property {number} queues.agents.id
 * @property {string} queues.agents.name
 * @property {string} queues.agents.status
 * @property {number[]} queues.agents.categories
 * @property {number} queues.agents.max_active_chat_count
 * @property {object[]} queues.agent_groups
 * @property {number} queues.project
 * @property {number} queues.agent_groups.id
 * @property {string} queues.agent_groups.name
 * @property {string} queues.agent_groups.color
 * @property {object[]} queues.agent_groups.agents
 * @property {number} queues.agent_groups.agents.id
 * @property {string} queues.agent_groups.agents.name
 * @property {string} queues.agent_groups.agents.status
 * @property {number[]} queues.agent_groups.agents.categories
 * @property {number} queues.agent_groups.agents.max_active_chat_count
 * @property {number} queues.agent_groups.project
 * @property {object[]} agent_groups
 * @property {number} agent_groups.id
 * @property {string} agent_groups.name
 * @property {string} agent_groups.color
 * @property {object[]} agent_groups.agents
 * @property {number} agent_groups.agents.id
 * @property {string} agent_groups.agents.name
 * @property {string} agent_groups.agents.status
 * @property {number[]} agent_groups.agents.categories
 * @property {number} agent_groups.agents.max_active_chat_count
 * @property {number} agent_groups.project
 * @property {number} project
 */

export function getSessionWithSortedMessages(sessionItem = {}) {
  const session = { ...sessionItem };
  const messages = [...(session?.messages || [])];
  if (messages.length) {
    const mappedMessages = messages.map((item) => {
      const date = item?.message_time || item?.created || item?.time;
      return {
        ...item,
        message_time: DateHelper.getDateTime(date),
      };
    });
    const sortedMessages = mappedMessages?.sort((a, b) => {
      return a.message_time.diff(b.message_time);
    });
    return {
      ...session,
      messages: sortedMessages,
    };
  }
  return sessionItem;
}

/**
 * @typedef {object} SessionCountDelta
 * @property {number} my_session_count
 * @property {number} agent_chatting_count
 * @property {number} agent_waiting_count
 * @property {number} other_count
 * @property {number} all_sessions_count Calculated by adding agent_chatting_count, agent_waiting_count, and other_count
 * @property {object[]} agent_waiting_queue_counts
 * @property {number} agent_waiting_queue_counts.queue_id
 * @property {string} agent_waiting_queue_counts.queue_name
 * @property {number} agent_waiting_queue_counts.count
 */

export const initState = {
  /**
   * The status of the connection to the server
   *
   * @type {"closed" | "connected" | "reconnected" | "disconnected"}
   */
  onlineStatus: "closed",
  /** @type {AgentInfo[]} */
  onlineAgents: [],

  /**
   * The status of the session with the server
   *
   * @type {"init" | "connecting" | "new" | "resume" | "closing" | "closed"}
   */
  sessionStatus: "init",
  sessionFailMsg: "",
  /** @type {CustomerSession[]} */
  sessions: [],
  /** @type {CustomerSession | null} */
  selectedSession: null,
  isLogged: false,
  /** @type {"visible" | "hidden"} */
  viewStatus: "hidden",
  /** @type {AgentInfo} */
  agentInfo: null,
  lastPongTime: null,
  otherSessionsInfo: {
    count: 0,
    page: 1,
  },
  /** @type {CallcenterSetting | null} */
  callcenterSettings: {
    id: 0,
    callcenter_type: livechatCallcenterType.Pool,
    agent_can_leave_session: true,
    show_bot_sessions: false,
  },
  /** @type {CallcenterSetting | null} */
  sessionCallcenterSettings: {
    id: 0,
    callcenter_type: livechatCallcenterType.Pool,
    agent_can_leave_session: true,
    show_bot_sessions: false,
  },
  /**
   * @type {{
   *   id: number;
   *   llm_type: string;
   *   submodel: string;
   *   active: boolean;
   *   llm_size: any;
   *   genai_analysis_active: boolean;
   *   project: number;
   *   access_token: string;
   * }}
   */
  llmSettings: {
    id: 0,
    llm_type: "Gemini",
    submodel: "",
    access_token: "",
  },
  projectSettings: [],
  livechatLoadingStatus: false,
  /** @type {SessionCountDelta} */
  sessionCountDelta: {
    agent_waiting_count: 0,
    all_sessions_count: 0,
    agent_chatting_count: 0,
    agent_waiting_queue_counts: [],
  },
  livechatOptions: {
    highlight: true,
    alert: true,
    continuousAlert: false,
    /** @type {{ [key: number]: string }} */
    filters: null,
    /** @type {keyof livechatCallcenterType} */
    type: livechatCallcenterType.Pool,
    // ...getLiveChatOptions(),
  },
  connectionOptions: {
    expires_in: 900, //900
    ping_interval: 10, //5
    time_until_expiration: 60, //60 14min + 60s warning
  },
  interactionStatus: {
    focused: true,
    online: true,
    typing: false,
    time: 0,
  },
};

const livechat = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case at.SET_AGENT_INFO:
        draft.agentInfo = action.payload;
        break;
      case at.SET_SESSION_STATUS:
        draft.sessionStatus = action.payload;
        break;
      case at.WS_CONNECT_FAIL:
        draft.onlineStatus = "disconnected";
        break;
      case at.WS_LOGIN:
        draft.sessionStatus = "connecting";
        break;
      case at.WS_DISCONNECT:
        draft.isLogged = false;
        draft.onlineStatus = "closed";
        break;

      case at.WS_LOGIN_FAIL:
        draft.sessionStatus = "closed";
        draft.sessionFailMsg = typeof action.payload === "string" ? action.payload : "Unknown login error";

        draft.isLogged = false;
        break;

      case at.WS_LOGIN_SUCCESS:
        draft.sessionStatus = "new";
        draft.isLogged = true;
        break;

      case at.SET_SESSION_LIST:
        const newSessionList = [];
        if (action.payload?.length) {
          for (const s of action.payload) {
            newSessionList.push(getSessionWithSortedMessages(s));
          }
        }
        draft.sessions = newSessionList;
        const newSelectedSession = newSessionList.find((session) => session?.id === draft.selectedSession?.id);
        if (newSelectedSession) {
          draft.selectedSession = { ...draft.selectedSession, ...newSelectedSession };
        }
        break;
      case at.ADD_SESSION:
        draft.sessions.push(getSessionWithSortedMessages(action.payload));
        break;
      case at.SET_SELECTED_SESSION:
        if (action.payload === null) {
          draft.selectedSession = null;
          break;
        }
        draft.selectedSession = getSessionWithSortedMessages(action.payload);
        break;
      case at.SET_PONG:
        draft.lastPongTime = action.payload;
        break;

      case at.SET_VIEW_STATUS:
        draft.viewStatus = action.payload;
        break;
      case at.SET_PROJECT_SETTINGS:
        draft.projectSettings = action.payload;
        break;
      case at.SET_ONLINE_STATUS:
        draft.onlineStatus = action.payload;
        break;
      case at.SET_ONLINE_AGENTS:
        draft.onlineAgents = action.payload;
        break;
      case at.SET_LIVECHAT_LOADING_STATUS:
        draft.livechatLoadingStatus = action.payload;
        break;
      case at.SET_SESSION_COUNT_DELTA:
        const { sessionDelta } = action.payload;

        draft.sessionCountDelta = {
          ...sessionDelta,
        };

        //sort agent_waiting_queue_counts by queue_id if exist
        const agentWaitingQueueCounts = sessionDelta.agent_waiting_queue_counts;
        if (agentWaitingQueueCounts) {
          draft.sessionCountDelta.agent_waiting_queue_counts = agentWaitingQueueCounts.sort(
            (a, b) => a.queue_id - b.queue_id
          );
        }

        break;
      case at.APPEND_MESSAGE_TO_SESSION: {
        const targetSession = draft.sessions.find((session) => session?.id === action.payload.id);
        const payloadNewMsg = {
          isSent: true,
          ...action.payload.message,
          message_time: DateHelper.getDateTime(action.payload.message.message_time || action.payload.message.created),
        };

        if (targetSession) {
          const matchedAckIdMessage =
            payloadNewMsg.payload?.ack_id &&
            targetSession.messages.find((msg) => msg?.payload?.ack_id === payloadNewMsg.payload?.ack_id);

          // && lastMessage.message === payloadNewMsg.message
          if (matchedAckIdMessage) {
            targetSession.messages = targetSession.messages.map((msg) => {
              if (msg?.payload?.ack_id === payloadNewMsg.payload?.ack_id) {
                return payloadNewMsg;
              }
              return msg;
            });
          } else {
            targetSession.messages.push(payloadNewMsg);
          }
          draft.sessions = draft.sessions.map((session) => {
            if (session?.id === action.payload.id) {
              return targetSession;
            }
            return session;
          });
        }
        if (draft.selectedSession && draft.selectedSession?.id === action.payload.id) {
          //TODO: Encountered a bug where the selectedSession.messages is null and throws an error. Identify the root cause and fix it.
          draft.selectedSession ??= {};
          draft.selectedSession.messages ??= [];
          if (Array.isArray(targetSession?.messages)) {
            draft.selectedSession.messages = [...targetSession.messages];
          }
        }

        break;
      }
      case at.UPDATE_SESSION_STATUS: {
        const { id, session_status, last_status } = action.payload;
        const targetSession = draft.sessions?.find((session) => session?.id === id);

        /* No longer need to remove session when customer disconnect. Instead we will keep the session and show it as "Customer disconnected" in the session list up to 5 minutes(or by config)*/

        // if (targetSession && targetSession.session_status !== session_status) {
        //   // const hasMainSessionStatus = chatbotMainSessionStatusList.includes(session_status);
        //   if (
        //     session_status === chatbotSessionStatus.CUSTOMER_DISCONNECT ||
        //     session_status === chatbotSessionStatus.COMPLETED
        //   ) {
        //     //remove session if customer disconnect
        //     draft.sessions = draft.sessions.filter((session) => session?.id !== id);
        //     if (draft.selectedSession?.id === id) {
        //       // draft.selectedSession = null;
        //       draft.selectedSession.last_status = draft.selectedSession.session_status;
        //       draft.selectedSession.session_status = session_status;
        //     }
        //   } else {
        targetSession.last_status = targetSession.session_status;
        targetSession.session_status = session_status;
        if (draft.selectedSession?.id === id) {
          draft.selectedSession.last_status = draft.selectedSession.session_status;
          draft.selectedSession.session_status = session_status;
        }
        // }
        // }
        break;
      }
      case at.SET_LABEL_AND_CLOSING_CODE_STATE: {
        const { session_id, session_labels, closing_codes } = action.payload;
        const targetSession = draft.sessions?.find((session) => session?.id === session_id);
        if (targetSession) {
          targetSession.session_labels = session_labels;
          targetSession.session_closing_codes = closing_codes;
          if (draft.selectedSession?.id === session_id) {
            draft.selectedSession.session_labels = session_labels;
            draft.selectedSession.session_closing_codes = closing_codes;
          }
        }
        break;
      }
      case at.SET_LIVECHAT_OPTIONS:
        draft.livechatOptions = action.payload;
        break;
      case at.SET_INTERACTION_STATUS:
        draft.interactionStatus = action.payload;
        break;
      case at.SET_LLM_SETTINGS:
        draft.llmSettings = action.payload;
        break;
      case at.SET_CALLCENTER_SETTINGS:
        draft.callcenterSettings = action.payload;
        break;
      case at.SET_SESSION_CALLCENTER_SETTINGS:
        draft.sessionCallcenterSettings = action.payload;
        break;
      case at.SET_OTHER_SESSIONS_INFO:
        draft.otherSessionsInfo = { ...draft.otherSessionsInfo, ...action.payload };
        break;
      case at.SET_CONNECTION_OPTIONS:
        draft.connectionOptions = action.payload;
        break;
      case at.UPDATE_INTERACTION_STATUS:
        const sessionId = action.payload?.session_id;
        const connectionId = action.payload?.connection_id;
        let targetSession = draft.sessions?.find((session) => session?.id === sessionId);
        if (!targetSession && draft.selectedSession?.id === sessionId) {
          targetSession = draft.selectedSession;
        }
        if (targetSession) {
          targetSession = { ...targetSession };
          let isExist = false;
          const newInteractions =
            targetSession.interactions?.map((i) => {
              if (i.connection_id === connectionId) {
                isExist = true;
                return {
                  ...i,
                  ...action.payload,
                };
              }
              return i;
            }) || [];
          if (!isExist) {
            newInteractions.push(action.payload);
          }
          const closedInteractions = newInteractions.filter((i) => i.closed);
          const filteredConnections = targetSession.connections?.filter(
            (c) => !closedInteractions.find((i) => i.connection_id === c.connection_id)
          );
          const updatedConnections = filteredConnections?.map((c) => {
            const interaction = newInteractions.find((i) => i.connection_id === c.connection_id);
            if (interaction) {
              return {
                ...c,
                // focused: interaction.focused,
                // online: interaction.online,
                // typing: interaction.typing,
                // location: interaction.location,
                // last_access: interaction.last_access,
                ...interaction,
              };
            }
            return c;
          });

          targetSession.connections = updatedConnections;
          targetSession.interactions = newInteractions;
          draft.sessions = draft.sessions.map((s) => {
            if (s.id === sessionId) {
              return targetSession;
            }
            return s;
          });
          if (draft.selectedSession?.id === sessionId) {
            draft.selectedSession = targetSession;
          }
        }

        break;
      case at.WS_SEND_MESSAGE_LISTEN_SESSION:
        let modifiedSelectedSession;
        const idList = action.payload?.idList;
        const newListenType = action.payload?.listenType;

        const newSessions = draft.sessions?.map((session) => {
          if (idList.includes(session.id)) {
            const s = { ...session, listen_type: newListenType };
            if (draft.selectedSession?.id === session.id) {
              modifiedSelectedSession = s;
            }
            return s;
          }
          return session;
        });

        draft.sessions = newSessions;
        if (modifiedSelectedSession) {
          draft.selectedSession = modifiedSelectedSession;
        }
        break;
      default:
        break;
    }
  });

export default livechat;
