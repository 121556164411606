import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import useBootstrapBreakpoint from "~common/hooks/useBootstrapBreakpoint";
import SessionChat from "~components/Session/SessionChat";
import SetSessionLabelModal from "~components/Session/SessionChat/SetSessionLabelModal";
import { chatbotSessionStatus } from "~constants";
import HistoryHelper from "~helpers/HistoryHelper";
import store from "~store";
import { setClosingCodeToSession } from "~store/closingCode/actions";
import {
  getSessionCallcenterSettings,
  setSelectedSession,
  updateLabelAndClosingCodesById,
  wsSendAssignSession,
  wsSendCloseSession,
  wsSendDirectToBot,
  wsSendInteractionStatus,
  wsSendMsg,
  wsSendTicketCreate,
} from "~store/socket/livechat/actions";
import {
  selectAgentInfo,
  selectInteractionStatus,
  selectOnlineStatus,
  selectProjectSettings,
  selectSelectedSession,
  selectSessionCallcenterSettings,
} from "~store/socket/livechat/selectors";
import { selectProjects } from "~store/user/selectors";

import { FullScreenChatModal } from ".";

export default function LiveSessionChat({ onClickAction }) {
  const dispatch = useDispatch();
  const agentInfo = useSelector(selectAgentInfo);
  const selectedSession = useSelector(selectSelectedSession);
  const onlineStatus = useSelector(selectOnlineStatus);
  const projectSettings = useSelector(selectProjectSettings);
  const projects = useSelector(selectProjects);

  const sessionChatRef = useRef(null);

  const callcenterSettings = useSelector(selectSessionCallcenterSettings);

  const [interactionInfo, setInteractionInfo] = useState({
    online: true,
    typing: false,
    focused: false,
    location: "",
  });
  useEffect(() => {
    const interactions = selectedSession?.interactions || [];
    const connections = selectedSession?.connections || [];

    const loopCount = Math.max(interactions.length, connections.length);
    const mergedInteractionsWithConnections = [];
    for (let i = 0; i < loopCount; i++) {
      const interaction = interactions[i];
      const connection = connections[i];
      if (interaction) {
        const relatedConnection = connections.find((i) => i.connection_id === interaction.connection_id);
        mergedInteractionsWithConnections.push({ ...relatedConnection, ...interaction });
      } else if (connection) {
        mergedInteractionsWithConnections.push({ ...connection });
      }
    }

    if (!mergedInteractionsWithConnections.length) return;
    const onlineList = mergedInteractionsWithConnections.filter((interaction) => interaction?.online);
    const typingInteraction = onlineList.find((interaction) => interaction.typing);
    const isTyping = !!typingInteraction;
    const isTypingStop = onlineList.some((interaction) => interaction.typing === false);
    const isFocused = onlineList.some((interaction) => interaction.focused);
    const locationFocused = onlineList.find((interaction) => interaction.location && interaction.focused);
    const location = onlineList.find((interaction) => interaction.location);
    const locationOfflineLatest = mergedInteractionsWithConnections.find((interaction) => interaction.location);
    const isTypingState = isTyping !== true && isTypingStop ? false : interactionInfo.typing || isTyping;

    const timeoutMs = 10000;

    setInteractionInfo((prev) => {
      const newStatus = {
        ...prev,
        typing: isTypingState,
        focused: isFocused,
        location:
          locationFocused?.location || location?.location || locationOfflineLatest?.location || prev.location || "",
        locationList: mergedInteractionsWithConnections.filter((interaction) => interaction.location),
        count: onlineList.length,
        online: onlineList.length > 0,
      };
      return newStatus;
    });
    let typingTimeout;
    if (isTypingState) {
      typingTimeout = setTimeout(() => {
        setInteractionInfo((prev) => ({ ...prev, typing: false }));
        // console.log("interactionInfo TX", false);
      }, timeoutMs);
    }
    return () => {
      // console.log("interactionInfo clear", true);
      clearTimeout(typingTimeout);
    };
  }, [selectedSession?.interactions, interactionInfo.typing, selectedSession?.connections]);

  // console.log("interactionInfo", interactionInfo.typing);

  const matchedWelcomeSettings = useMemo(() => {
    if (!selectedSession?.project) return null;
    return projectSettings.find((project) => project.project === selectedSession.project);
  }, [projectSettings, selectedSession?.project]);

  useEffect(() => {
    if (matchedWelcomeSettings?.project) {
      dispatch(getSessionCallcenterSettings(matchedWelcomeSettings.project));
    }
  }, [dispatch, matchedWelcomeSettings?.project]);

  const isTicketEnabled = matchedWelcomeSettings?.ticket_enabled;
  const isBotEnabled = useMemo(
    () => (matchedWelcomeSettings?.chatbot_list || []).some((item) => item.enabled),
    [matchedWelcomeSettings?.chatbot_list]
  );

  const handleClickTake = useCallback(
    (session) => {
      dispatch(wsSendAssignSession(session.id));
      onClickAction?.("take", session);
    },
    [dispatch, onClickAction]
  );

  const handleClickSend = useCallback(
    (session, text, type, files, restProps) => {
      // if (!text?.length) return;

      const sessionChat = sessionChatRef.current;
      const chatRef = sessionChat.getChatRef();
      const distributionContext = chatRef?.getDistributionContext();
      dispatch(
        wsSendMsg(session.id, text, type, files, {
          ...(restProps?.attachments && { attachments: restProps.attachments }),
          ...(distributionContext?.trendyolMsg?.historyId && {
            modified_message_id: distributionContext?.trendyolMsg?.historyId,
            // modified_message_id: distributionContext?.trendyolMsg?.platformMessageId,
          }),
        })
      );

      distributionContext.trendyolMsg = null;
    },
    [dispatch]
  );
  const handleClickDirectToBot = useCallback(
    (session) => {
      dispatch(wsSendDirectToBot(session.id));
    },
    [dispatch]
  );

  const handleClickSendTicket = useCallback(
    (session) => {
      dispatch(wsSendTicketCreate(session.id));
    },
    [dispatch]
  );

  const handleClickCloseSession = useCallback(
    async (session, e, closingCodeId) => {
      const isForceClose = e?.shiftKey;
      await dispatch(
        setClosingCodeToSession(session.project, session.id, {
          closing_codes: [closingCodeId],
        })
      );
      await dispatch(wsSendCloseSession(session.id, isForceClose));

      if (closingCodeId) {
        dispatch(updateLabelAndClosingCodesById(session.id));
      }
    },
    [dispatch]
  );

  // const handleClickDirectToTicket = useCallback(
  //   (session) => {
  //     dispatch(wsSendReturnToTicket(session.id));
  //   },
  //   [dispatch]
  // );
  // const handleMessageChange = useCallback((text) => {
  //   const isTyping = !!text?.length;
  //   setInteractionInfo((prev) => ({ ...prev, typing: isTyping }));
  //   let typingTimeout;
  //   if (isTyping) {
  //     typingTimeout = setTimeout(() => {
  //       setInteractionInfo((prev) => ({ ...prev, typing: false }));
  //     }, chatbotTypingThrottleMs);
  //   }
  //   return () => clearTimeout(typingTimeout);
  // }, []);

  const isDisconnected = onlineStatus == "disconnected";
  const handleInteraction = useCallback(
    (interaction) => {
      if (isDisconnected) return;
      if (selectedSession?.session_status === chatbotSessionStatus.AGENT_CHAT) {
        const interactionStatus = selectInteractionStatus(store.getState());
        dispatch(
          wsSendInteractionStatus(selectedSession?.id, {
            location: interactionStatus.location,
            ...interaction,
          })
        );
      }
    },
    [dispatch, isDisconnected, selectedSession]
  );

  const handleClickHistoryItem = useCallback((session) => {
    window.open(HistoryHelper.generateUrl(`/contactcenter/history/${session.id}/`), "_blank");
  }, []);

  const handleClickSetLabel = useCallback(
    async (session) => {
      const firstBot = projects.find((p) => p.id === session.project)?.chatbot_list?.[0];
      const result = await SetSessionLabelModal.show({
        projectId: session.project,
        botId: session.bot || firstBot?.id,
        sessionId: session.id,
        sessionLabels: session.session_labels,
      });
      if (result === false) return;

      if (result?.idList?.length > -1) {
        dispatch(updateLabelAndClosingCodesById(session.id));
      }
    },
    [dispatch, projects]
  );

  // console.log("interactionInfo", interactionInfo.typing);
  const SessionChatComp = (
    <SessionChat
      ref={sessionChatRef}
      liveMode
      chatMessageProps={{
        colorPrimary: matchedWelcomeSettings?.popup_primary_color,
        botLogo:
          matchedWelcomeSettings?.popup_header_icon_type !== "DF" && matchedWelcomeSettings?.popup_header_icon_path,
        botJoinedLogo:
          matchedWelcomeSettings?.popup_bot_icon_type !== "DF" && matchedWelcomeSettings?.popup_bot_icon_path,
        popupStyleInfo: matchedWelcomeSettings?.popup_style_info,
        hideLikeDislike: true,
      }}
      closingCodeEnabled={callcenterSettings?.session_closing_code_enabled}
      closingCodeMandatory={callcenterSettings?.session_closing_code_mandatory}
      disableActions={agentInfo?.status !== "AV"}
      disableTextInput={["closed", "disconnected"].includes(onlineStatus)}
      hideOutline={agentInfo?.status !== "AV"}
      hideSendDirectToBot={!isBotEnabled || !callcenterSettings?.agent_can_leave_session}
      hideSendTicket={!isTicketEnabled}
      interactionInfo={interactionInfo}
      selectedSession={selectedSession}
      onClickCloseSession={handleClickCloseSession}
      onClickDirectToBot={handleClickDirectToBot}
      onClickHistorySelect={handleClickHistoryItem}
      onClickSend={handleClickSend}
      onClickSendTicket={handleClickSendTicket}
      onClickSetLabel={handleClickSetLabel}
      onClickTake={handleClickTake}
      onInteraction={handleInteraction}
      // onClickDirectToTicket={handleClickDirectToTicket}
      // onMessageChange={handleMessageChange}
    />
  );

  // if (isLg) {
  //   if (!selectedSession?.id) return null;
  //   return <FullScreenChatModal onClose={clearSelectedSession}>{SessionChatComp}</FullScreenChatModal>;
  // }
  return SessionChatComp;
}

export function LiveSessionChatModalProvider({ ...props }) {
  const dispatch = useDispatch();
  const isLgDown = useBootstrapBreakpoint("lg");

  const selectedSession = useSelector(selectSelectedSession);
  const clearSelectedSession = useCallback(() => {
    HistoryHelper.replace(
      {
        pathname: `/contactcenter/livechat/`,
        search: window.location.search,
      },
      { scope: "dashboard" }
    );
    dispatch(setSelectedSession(null));
  }, [dispatch]);

  if (isLgDown) {
    if (!selectedSession?.id) return null;
    return (
      <FullScreenChatModal onClose={clearSelectedSession}>
        <LiveSessionChat {...props} />
      </FullScreenChatModal>
    );
  }
}
