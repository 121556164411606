import { useCallback, useEffect, useMemo, useState } from "react";

import { PalCheckbox, PalTimePicker } from "@palamar/fe-library";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { MdAdd, MdCode, MdHelp, MdInfo, MdNextWeek } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";
import * as Yup from "yup";

import styled from "@emotion/styled";
import { Badge, Box, Switch, Tab, Tabs } from "@mui/material";

import useKeyPressed from "~common/hooks/useKeyPressed";
import useQueryParams from "~common/hooks/useQueryParams";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRInput, { PRTextArea, PRTextAreaFormat } from "~components/Generic/PRInput";
import PRNumber from "~components/Generic/PRNumber";
import PRPage from "~components/Generic/PRPage";
import PRSelect from "~components/Generic/PRSelect";
import PRTab from "~components/Generic/PRTab";
import PRTooltip from "~components/Generic/PRTooltip";
import LowCodeEditorModal from "~components/LowCodeEditor";
import PalTooltip from "~components/mui/PalTooltip";
import {
  livechatCallcenterTypeOptions,
  llmTypeOptions,
  projectLanguageOptions,
  projectTimeoutTimes,
  projectTimeoutTimesOptions,
  projectTranslateKeyOptions,
  emailTypes,
  emailTypesOptions,
  livechatCallcenterType,
  lowCodeModuleType,
  chatbotAgentVisibilityTypeOptions,
  chatbotAgentVisibilityType,
} from "~constants";
import AlertHelper from "~helpers/AlertHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import LoadingHelper from "~helpers/LoadingHelper";
import store from "~store";
import { getCurrentUser } from "~store/actions";
import {
  createOrUpdateProject,
  getProject,
  setProject,
  updateProjectSettings,
} from "~store/settings/projectSettings/actions";
import { selectProject } from "~store/settings/projectSettings/selectors";
import {
  deleteLlmSettings,
  getCallcenterSettings,
  getLlmSettings,
  setCallcenterSettings,
  setLlmSettings,
  updateCallcenterSettings,
  updateLlmSettings,
} from "~store/socket/livechat/actions";
import { selectCallcenterSettings, selectLlmSettings } from "~store/socket/livechat/selectors";
import { selectBots, selectCurrentProject, selectProjects } from "~store/user/selectors";

const defaultTimeoutTimes = {
  waiting_timeout: 20,
  inactivity_timeout: 5,
  bot_sessions_timeout: 10,
  agent_sessions_timeout: 15,
  feedback_timeout: 5,
};

function TimeoutTimesItem({ tab, value = {}, onChange }) {
  const { t } = useTranslation();
  const handleChange = (key) => (data) => {
    const inputValue = data?.floatValue;
    onChange?.({ ...value, [key]: inputValue });
  };

  return (
    <Row className="g-2 mt-0">
      <Col md={3} xs={12}>
        <Label>{t("dashboard.myProjectSettings.waitingTimeout")}</Label>
        <PRNumber
          key={tab}
          min={1}
          placeholder={t("dashboard.myProjectSettings.enterTimeoutValue")}
          suffix=" minutes"
          value={value.waiting_timeout ?? null}
          onChange={handleChange("waiting_timeout")}
        />
      </Col>
      <Col md={3} xs={12}>
        <Label>{t("dashboard.myProjectSettings.inactivityTimeout")}</Label>
        <PRNumber
          key={tab}
          min={1}
          placeholder={t("dashboard.myProjectSettings.enterTimeoutValue")}
          suffix=" minutes"
          value={value.inactivity_timeout ?? null}
          onChange={handleChange("inactivity_timeout")}
        />
      </Col>
      <Col md={3} xs={12}>
        <Label>{t("dashboard.myProjectSettings.botSessionsTimeout")}</Label>
        <PRNumber
          key={tab}
          min={1}
          placeholder={t("dashboard.myProjectSettings.enterTimeoutValue")}
          suffix=" minutes"
          value={value.bot_sessions_timeout ?? null}
          onChange={handleChange("bot_sessions_timeout")}
        />
      </Col>
      <Col md={3} xs={12}>
        <Label>{t("dashboard.myProjectSettings.agentSessionsTimeout")}</Label>
        <PRNumber
          key={tab}
          min={1}
          placeholder={t("dashboard.myProjectSettings.enterTimeoutValue")}
          suffix=" minutes"
          value={value.agent_sessions_timeout ?? null}
          onChange={handleChange("agent_sessions_timeout")}
        />
      </Col>
      <Col md={3} xs={12}>
        <Label>{t("dashboard.myProjectSettings.feedbackTimeout")}</Label>
        <PRNumber
          key={tab}
          min={1}
          placeholder={t("dashboard.myProjectSettings.enterTimeoutValue")}
          suffix=" minutes"
          value={value.feedback_timeout ?? null}
          onChange={handleChange("feedback_timeout")}
        />
      </Col>
    </Row>
  );
}

const StyledTab = styled(Tab)`
  font-weight: 600;
`;
const tabList = [
  { id: "general", label: "dashboard.myProjectSettings.general" },
  { id: "email", label: "common.email" },
  { id: "llm", label: "dashboard.myProjectSettings.llm" },
  { id: "livechat", label: "dashboard.myProjectSettings.liveChatTicket" },
  { id: "timeout", label: "dashboard.myProjectSettings.timeoutSettings" },
  { id: "system", label: "dashboard.myProjectSettings.systemMessages" },
];
export default function MyProjectSettings() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { new: isNew } = useQueryParams();
  const [timeoutTab, setTimeoutTab] = useState(projectTimeoutTimes.web);
  const isShiftPressed = useKeyPressed("Shift");
  const [firstAccessTokenFocus, setFirstAccessTokenFocus] = useState(false);

  const queryParams = useQueryParams();
  const [offlineMessageTab, setOfflineMessageTab] = useState(projectTimeoutTimes.web);
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedTranslateKey, setSelectedTranslateKey] = useState(projectTranslateKeyOptions[0].value);
  const [enableLLMOptions, setEnableLLMOptions] = useState(false);
  const [activeTab, setActiveTab] = useState(tabList[0].id);

  const callcenterSettings = useSelector(selectCallcenterSettings);
  const llmSettings = useSelector(selectLlmSettings);
  const currentProject = useSelector(selectCurrentProject);

  const currentProjectId = useMemo(() => (isNew ? 0 : currentProject?.id), [currentProject?.id, isNew]);

  const project = useSelector(selectProject);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: project?.name,
      chatbotLanguages: project?.settings?.chatbot_languages ?? [],
      timeoutTimes: project?.settings?.timeout_times || {},
      maintenance: project?.settings?.maintenance ?? false,
      show_thumps_up: project?.settings?.show_thumps_up ?? true,
      show_queue_order: project?.settings?.show_queue_order ?? true,
      chat_label_enabled: project?.settings?.chat_label_enabled ?? false,
      ticket_enabled: project?.settings?.ticket_enabled ?? true,
      direct_to_ticket_enabled: project?.settings?.direct_to_ticket_enabled ?? false,
      system_chatbot_info: project?.settings?.system_chatbot_info,
      chatbot_info: project?.settings?.chatbot_info,

      mailer_username: project?.settings?.mailer_username || "",
      mailer_password: project?.settings?.mailer_password || "",
      mailer_encryption_type: project?.settings?.mailer_encryption_type || "",
      mailer_domain: project?.settings?.mailer_domain || "",
      mailer_port: project?.settings?.mailer_port || "",
      info_email_type: project?.settings?.info_email_type || emailTypes.SES,
      email_status: project?.settings?.email_status || "",

      info_email: project?.settings?.info_email ?? "noreply@palmate.ai",
      info_email_name: project?.settings?.info_email_name ?? "",
      reply_to_email: project?.settings?.reply_to_email ?? "",
      receiver_email: project?.settings?.receiver_email ?? "noreply@palmate.ai",
      static_messages: [
        ...(Array.isArray(project?.settings?.static_messages) ? project?.settings?.static_messages || [] : []),
      ],
      callcenterSettings: {
        ...callcenterSettings,
        callcenter_type: callcenterSettings?.callcenter_type || livechatCallcenterType.Pool,
        chat_visibility: callcenterSettings?.chat_visibility || chatbotAgentVisibilityType.All,
        session_closing_code_enabled: callcenterSettings?.session_closing_code_enabled ?? false,
        session_closing_code_mandatory: callcenterSettings?.session_closing_code_mandatory ?? false,
        session_label_enabled: callcenterSettings?.session_label_enabled ?? true,
        session_label_mandatory: callcenterSettings?.session_label_mandatory ?? false,
      },
      // llmSettings: llmSettings,
      llmSettings: {
        id: llmSettings?.id,
        llm_type: llmSettings?.llm_type,
        submodel: llmSettings?.submodel,
        access_token: llmSettings?.access_token,
      },
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t("common.required"))
        .min(3, t("component.formik.minLength").format(t("common.name"), "3")),
      chatbotLanguages: Yup.array()
        .required(t("common.required"))
        .min(1, t("component.formik.minNumber").format(t("dashboard.myProjectSettings.chatbotLanguages"), "1")),
      llmSettings: Yup.object().shape({
        llm_type: Yup.string().when("llmSettings", {
          is: (value) => enableLLMOptions,
          then: Yup.string().required(t("common.required")),
          otherwise: Yup.string().notRequired(),
        }),
        submodel: Yup.string().when("llm_type", {
          is: (value) => !!value && enableLLMOptions,
          then: Yup.string().required(t("common.required")),
          otherwise: Yup.string().notRequired(),
        }),
      }),
      // email_status: Yup.string()
      //   .required("Required")
      //   .test("is-key-of-emailStatus", "email_status must be a key of emailStatus", (value) => {
      //     return Object.keys(emailStatus).includes(value);
      //   }),
      info_email: Yup.string()
        .optional()
        .email(t("component.formik.invalid").format(t("common.email"))),
      reply_to_email: Yup.string()
        .optional()
        .email(t("component.formik.invalid").format(t("common.email"))),
      mailer_username: Yup.string()
        .nullable()
        .when("info_email_type", {
          is: (value) => value === emailTypes.SMTP,
          then: Yup.string().required(t("common.required")),
          otherwise: Yup.string().notRequired(),
        }),
      mailer_password: Yup.string()
        .nullable()
        .when("info_email_type", {
          // Check if the email type is SMTP and the username is not empty
          is: (value) => value === emailTypes.SMTP && !project?.settings?.mailer_username,
          then: Yup.string().required(t("common.required")),
          otherwise: Yup.string().notRequired(),
        }),
      mailer_domain: Yup.string()
        .nullable()
        .when("info_email_type", {
          is: (value) => value === emailTypes.SMTP,
          then: Yup.string().required(t("common.required")),
          otherwise: Yup.string().notRequired(),
        }),
      mailer_port: Yup.string()
        .nullable()
        .when("info_email_type", {
          is: (value) => value === emailTypes.SMTP,
          then: Yup.string()
            .required(t("common.required"))
            .test("is-port", t("dashboard.myProjectSettings.portError"), (value) => {
              if (!value) return true;
              if (!/^\d+$/.test(value)) return false;
              return Number(value) > 0 && Number(value) < 65536;
            }),
          otherwise: Yup.string().notRequired(),
        }),

      mailer_encryption_type: Yup.string().when("info_email_type", {
        is: (value) => value === emailTypes.SMTP,
        then: Yup.string()
          .required(t("common.required"))
          .max(4, t("component.formik.maxLength").format(t("dashboard.myProjectSettings.encryptionType"), "4")),
        otherwise: Yup.string().notRequired(),
      }),
    }),

    onSubmit: async ({ name, chatbotLanguages, timeoutTimes, ...restSettings }, formikHelpers) => {
      LoadingHelper.open();

      if (restSettings.info_email_type === emailTypes.SES) {
        restSettings.mailer_encryption_type = "";
        restSettings.mailer_port = "";
        restSettings.mailer_domain = "";
        restSettings.mailer_password = "";
        restSettings.mailer_username = "";
      }

      if (!enableLLMOptions) {
        if (restSettings.llmSettings?.id) {
          await dispatch(deleteLlmSettings(project?.id, restSettings.llmSettings?.id));
        }
        restSettings.llmSettings = null;
      }

      try {
        let newProject;
        newProject = await dispatch(
          createOrUpdateProject(
            {
              name: name,
              id: project?.id,
            },
            {
              successMsg: false,
              loading: false,
              onSuccess: () => {},
            }
          )
        );

        if (newProject?.id) {
          const payload = {
            timeout_times: timeoutTimes,
            chatbot_languages: chatbotLanguages,
            ...restSettings,
          };

          await dispatch(
            updateProjectSettings(newProject?.id, payload, {
              successMsg: false,
              loading: false,
            })
          );
        }
        await dispatch(
          updateCallcenterSettings(newProject?.id, formik.values.callcenterSettings, {
            loading: false,
          })
        );
        if (restSettings.llmSettings?.access_token === llmSettings.access_token) {
          delete restSettings.llmSettings.access_token;
        }

        if (restSettings.llmSettings) {
          await dispatch(
            updateLlmSettings(newProject?.id, restSettings.llmSettings, {
              loading: false,
            })
          );
        }

        if (!currentProjectId) {
          await dispatch(getCurrentUser());
          async function changeProject(id) {
            const projects = selectProjects(store.getState());
            const project = projects.find((project) => project.id === id);
            const bots = selectBots(store.getState());

            const botsForProject = bots.filter((bot) => bot.projectId === id);
            const mainBotId = project?.main_bot;
            const targetBotId = mainBotId ?? botsForProject?.[0]?.id;

            const selectedBot = botsForProject.find((bot) => bot.id === targetBotId);
            //native url replace
            history.pushState({}, "", `/dashboard/project/${id}/bot/${selectedBot?.id}/settings/project`);
            location.assign(window.location.href);
          }
          changeProject(newProject?.id);
        } else {
          await dispatch(getProject(currentProjectId));
          await dispatch(getCallcenterSettings(currentProjectId));
          await dispatch(getLlmSettings(currentProjectId));
        }
        AlertHelper.showSuccess();

        if (!isShiftPressed) {
          // HistoryHelper.goBack("/settings/project", { scope: "dashboard" });
        }
      } catch (error) {}
      LoadingHelper.close();
    },
  });

  useEffect(() => {
    (async () => {
      if (queryParams.low_code === "true") {
        await LowCodeEditorModal.show({
          noFooter: true,
          moduleType: lowCodeModuleType.INSIGHT_MODULES,
        });
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("low_code");

        HistoryHelper.push({
          pathname: window.location.pathname,
          search: searchParams.toString(),
        });
      } else {
        LowCodeEditorModal.hide();
      }
    })();
  }, [queryParams?.low_code]);

  useEffect(() => {
    if (llmSettings?.submodel || llmSettings?.access_token) {
      setEnableLLMOptions(true);
    }
  }, [llmSettings]);

  const availableLanguageListOptions = useMemo(() => {
    return projectLanguageOptions.filter((a) => formik.values.chatbotLanguages.includes(a.value));
  }, [formik.values.chatbotLanguages]);

  useEffect(() => {
    if (!selectedLanguage && availableLanguageListOptions?.length) {
      setSelectedLanguage(availableLanguageListOptions?.[0]?.value);
    }
  }, [availableLanguageListOptions, selectedLanguage]);

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getProject(currentProjectId));
      dispatch(getCallcenterSettings(currentProjectId));
      dispatch(getLlmSettings(currentProjectId));
    }
    return () => {
      dispatch(setProject({}));
      dispatch(setLlmSettings({}));
      dispatch(setCallcenterSettings({}));
    };
  }, [dispatch, currentProjectId]);

  const onAccessTokenFocus = () => {
    if (!firstAccessTokenFocus) {
      formik.setFieldValue("llmSettings.access_token", "");
      setFirstAccessTokenFocus(true);
    }
  };

  const handeEmailStatusChange = (value) => {
    formik.setFieldValue("email_status", value);
  };

  const handleEmailTypeChange = (value) => {
    formik.setFieldValue("info_email_type", value);
  };

  const handleChangeCallcenterType = (value) => {
    formik.setFieldValue("callcenterSettings.callcenter_type", value);
  };

  const handleChangeAgentVisibilityType = (value) => {
    formik.setFieldValue("callcenterSettings.chat_visibility", value);
  };
  const handleChangeLlmType = (value) => {
    formik.setFieldValue("llmSettings.llm_type", value);
  };

  const handleClickCancel = () => {
    HistoryHelper.goBack("/settings/project", { scope: "dashboard" });
  };

  const handleChangeSelect = (value) => {
    formik.setFieldValue("chatbotLanguages", value);
  };
  const handleOptionReadonly = useCallback(
    (option) => {
      return !!project?.settings?.chatbot_languages.find((item) => item === option.value);
    },
    [project?.settings?.chatbot_languages]
  );

  const handleChangeTimeoutTimes = (value) => {
    formik.setFieldValue("timeoutTimes", {
      ...formik.values.timeoutTimes,
      [timeoutTab]: value,
    });
  };

  const handleChangeSwitch = (name) => (e) => {
    formik.setFieldValue(name, e.target.checked);
  };

  const parentName = [
    // {
    //   label: "Project",
    //   url: "/settings/project/",
    // },
    {
      label: currentProjectId ? t("common.project") : t("dashboard.myProjectSettings.newProject"),
    },
  ];

  const excludeFormatList = useMemo(() => {
    if ([projectTimeoutTimes.web].includes(offlineMessageTab)) {
      return [PRTextAreaFormat.css];
    }
    return [PRTextAreaFormat.html, PRTextAreaFormat.markdown, PRTextAreaFormat.css];
  }, [offlineMessageTab]);

  const activeOfflineMessageData = useMemo(() => {
    const dataFiltered = formik.values.static_messages.filter((item) => {
      return (
        item.platform === offlineMessageTab && item.language === selectedLanguage && item.key === selectedTranslateKey
      );
    });

    return dataFiltered?.[0] || {};
  }, [formik.values.static_messages, offlineMessageTab, selectedLanguage, selectedTranslateKey]);

  const activeDefaultOfflineMessageData = useMemo(() => {
    const dataFiltered = formik.values.static_messages.filter((item) => {
      return item.platform === "default" && item.language === selectedLanguage && item.key === selectedTranslateKey;
    });

    return dataFiltered?.[0] || {};
  }, [formik.values.static_messages, selectedLanguage, selectedTranslateKey]);

  const handleChangeOfflineMessageValue = (e) => {
    const value = e.target.value;
    let isChanged = false;
    const mappedArray = formik.values.static_messages.map((item) => {
      let newItem = { ...item };
      if (
        item.platform === offlineMessageTab &&
        item.language === selectedLanguage &&
        item.key === selectedTranslateKey
      ) {
        newItem.value = value;
        isChanged = true;
      }

      newItem.format ??= PRTextAreaFormat.plain;
      return newItem;
    });

    if (!isChanged) {
      mappedArray.push({
        platform: offlineMessageTab,
        language: selectedLanguage,
        key: selectedTranslateKey,
        value: value,
        format: activeDefaultOfflineMessageData.format || PRTextAreaFormat.plain,
      });
    }
    formik.setFieldValue("static_messages", mappedArray);
  };

  const handleChangeOfflineMessageFormat = (format) => {
    let isChanged = false;
    const mappedArray = formik.values.static_messages.map((item) => {
      let newItem = { ...item };
      if (
        item.platform === offlineMessageTab &&
        item.language === selectedLanguage &&
        item.key === selectedTranslateKey
      ) {
        newItem.format = format;
        isChanged = true;
      }

      newItem.format ??= PRTextAreaFormat.plain;
      return newItem;
    });

    if (!isChanged) {
      mappedArray.push({
        platform: offlineMessageTab,
        language: selectedLanguage,
        key: selectedTranslateKey,
        value: activeDefaultOfflineMessageData.value || "",
        format: format,
      });
    }
    formik.setFieldValue("static_messages", mappedArray);
  };

  const handleChangeLanguageSystemMessage = (value) => {
    setSelectedLanguage(value);
  };

  const handleChangeTab = (e, value) => {
    setActiveTab(value);
  };

  const hasErrorInGeneral = useMemo(() => {
    return (
      (formik.touched.name && formik.errors.name) || (formik.touched.chatbotLanguages && formik.errors.chatbotLanguages)
    );
  }, [formik.touched.name, formik.errors.name, formik.touched.chatbotLanguages, formik.errors.chatbotLanguages]);

  const hasErrorInEmail = useMemo(() => {
    return (
      (formik.touched.receiver_email && formik.errors.receiver_email) ||
      (formik.touched.info_email && formik.errors.info_email) ||
      (formik.touched.info_email_name && formik.errors.info_email_name) ||
      (formik.touched.info_email_type && formik.errors.info_email_type) ||
      (formik.touched.reply_to_email && formik.errors.reply_to_email)
    );
  }, [
    formik.touched.receiver_email,
    formik.errors.receiver_email,
    formik.touched.info_email,
    formik.errors.info_email,
    formik.touched.info_email_name,
    formik.errors.info_email_name,
    formik.touched.info_email_type,
    formik.errors.info_email_type,
    formik.touched.reply_to_email,
    formik.errors.reply_to_email,
  ]);

  const hasErrorInLLM = useMemo(() => {
    return (
      (formik.touched.llmSettings?.llm_type && formik.errors.llmSettings?.llm_type) ||
      (formik.touched.llmSettings?.submodel && formik.errors.llmSettings?.submodel) ||
      (formik.touched.llmSettings?.access_token && formik.errors.llmSettings?.access_token)
    );
  }, [
    formik.touched.llmSettings?.llm_type,
    formik.errors.llmSettings?.llm_type,
    formik.touched.llmSettings?.submodel,
    formik.errors.llmSettings?.submodel,
    formik.touched.llmSettings?.access_token,
    formik.errors.llmSettings?.access_token,
  ]);

  const hasErrorInLivechat = useMemo(() => {
    return (
      (formik.touched.callcenterSettings?.callcenter_type && formik.errors.callcenterSettings?.callcenter_type) ||
      (formik.touched.callcenterSettings?.chat_visibility && formik.errors.callcenterSettings?.chat_visibility) ||
      (formik.touched.callcenterSettings?.agent_can_leave_session &&
        formik.errors.callcenterSettings?.agent_can_leave_session) ||
      (formik.touched.show_queue_order && formik.errors.show_queue_order) ||
      (formik.touched.chat_label_enabled && formik.errors.chat_label_enabled) ||
      (formik.touched.ticket_enabled && formik.errors.ticket_enabled) ||
      (formik.touched.direct_to_ticket_enabled && formik.errors.direct_to_ticket_enabled) ||
      (formik.touched.callcenterSettings?.session_label_enabled &&
        formik.errors.callcenterSettings?.session_label_enabled) ||
      (formik.touched.callcenterSettings?.session_label_mandatory &&
        formik.errors.callcenterSettings?.session_label_mandatory) ||
      (formik.touched.callcenterSettings?.session_closing_code_enabled &&
        formik.errors.callcenterSettings?.session_closing_code_enabled) ||
      (formik.touched.callcenterSettings?.session_closing_code_mandatory &&
        formik.errors.callcenterSettings?.session_closing_code_mandatory)
    );
  }, [
    formik.touched.callcenterSettings?.callcenter_type,
    formik.errors.callcenterSettings?.callcenter_type,
    formik.touched.callcenterSettings?.chat_visibility,
    formik.errors.callcenterSettings?.chat_visibility,
    formik.touched.callcenterSettings?.agent_can_leave_session,
    formik.errors.callcenterSettings?.agent_can_leave_session,
    formik.touched.show_queue_order,
    formik.errors.show_queue_order,
    formik.touched.chat_label_enabled,
    formik.errors.chat_label_enabled,
    formik.touched.ticket_enabled,
    formik.errors.ticket_enabled,
    formik.touched.direct_to_ticket_enabled,
    formik.errors.direct_to_ticket_enabled,
    formik.touched.callcenterSettings?.session_label_enabled,
    formik.errors.callcenterSettings?.session_label_enabled,
    formik.touched.callcenterSettings?.session_label_mandatory,
    formik.errors.callcenterSettings?.session_label_mandatory,
    formik.touched.callcenterSettings?.session_closing_code_enabled,
    formik.errors.callcenterSettings?.session_closing_code_enabled,
    formik.touched.callcenterSettings?.session_closing_code_mandatory,
    formik.errors.callcenterSettings?.session_closing_code_mandatory,
  ]);

  const hasErrorInTimeout = useMemo(() => {
    return false;
  }, []);

  const hasErrorInSystem = useMemo(() => {
    return false;
  }, []);

  const hasErrorMap = useMemo(() => {
    return {
      general: hasErrorInGeneral,
      email: hasErrorInEmail,
      llm: hasErrorInLLM,
      livechat: hasErrorInLivechat,
      timeout: hasErrorInTimeout,
      system: hasErrorInSystem,
    };
  }, [hasErrorInGeneral, hasErrorInEmail, hasErrorInLLM, hasErrorInLivechat, hasErrorInTimeout, hasErrorInSystem]);

  const actions = useMemo(
    () =>
      [
        {
          tooltipText: t("dashboard.myProjectSettings.action.onboarding"),
          icon: MdNextWeek,
          color: "primary",
          onClick: () => {
            HistoryHelper.push(`/dashboard/setup/${currentProjectId}`, { scope: "" });
          },
        },
        {
          tooltipText: t("dashboard.intentList.lowCodeEditorButton.tooltip"),
          icon: MdCode,
          color: "primary",
          onClick: () => {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set("low_code", "true");

            HistoryHelper.push({
              pathname: window.location.pathname,
              search: searchParams.toString(),
            });
          },
        },
        currentProjectId && {
          label: t("common.create"),
          icon: MdAdd,
          color: "success",
          // onClick: formik.handleSubmit,
          link: "/settings/project/?new=1",
          linkProps: { newTab: true },
        },
      ].filter(Boolean),
    [currentProjectId, t]
  );
  return (
    <PRContainer actions={actions} name={t("common.settings")} parentName={parentName}>
      <PRPage>
        <Box mb={2}>
          <Tabs value={activeTab} onChange={handleChangeTab}>
            {tabList.map((item) => (
              <StyledTab
                key={item.id}
                label={
                  <Badge color="error" invisible={!hasErrorMap[item.id]} variant="dot">
                    <span>{t(item.label)}</span>
                  </Badge>
                }
                value={item.id}
              />
            ))}
          </Tabs>
        </Box>
        {activeTab === "general" && (
          <Row className="g-2 w-lg-50">
            <Col xs={12}>
              <Label>{t("common.name")}:</Label>
              <PRInput
                invalid={formik.touched.name && formik.errors.name}
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </Col>
            <Col xs={12}>
              <Label>{t("dashboard.myProjectSettings.chatbotLanguages")}:</Label>
              <PRSelect
                isMulti
                isPrimitiveValue
                disabled={project?.id}
                invalid={formik.touched.chatbotLanguages && formik.errors.chatbotLanguages}
                isClearable={false}
                isOptionReadonly={handleOptionReadonly}
                options={projectLanguageOptions}
                value={formik.values.chatbotLanguages}
                onChange={handleChangeSelect}
              />
            </Col>

            <Col md="12">
              <Switch
                checked={formik.values.show_thumps_up}
                name="show_thumps_up"
                onChange={handleChangeSwitch("show_thumps_up")}
              />
              {t("dashboard.myProjectSettings.showThumpsUpDown")}
            </Col>
            <Col md="12">
              <Switch
                checked={formik.values.maintenance}
                name="maintenance"
                onChange={handleChangeSwitch("maintenance")}
              />
              <span className="text-danger">{t("dashboard.myProjectSettings.enableMaintenanceMode")}</span>
            </Col>
          </Row>
        )}
        {activeTab === "email" && (
          <Row className="g-2 w-lg-50">
            <Col xs={12}>
              <Label>{t("dashboard.myProjectSettings.receiverEmail")}:</Label>
              <PRInput
                invalid={formik.touched.receiver_email && formik.errors.receiver_email}
                name="receiver_email"
                value={formik.values.receiver_email}
                onChange={formik.handleChange}
              />
            </Col>
            <Col xs={12}>
              <Label>{t("dashboard.myProjectSettings.senderName")}:</Label>
              <PRInput
                invalid={formik.touched.info_email_name && formik.errors.info_email_name}
                name="info_email_name"
                value={formik.values.info_email_name}
                onChange={formik.handleChange}
              />
            </Col>
            <Col xs={12}>
              <Label>{t("dashboard.myProjectSettings.senderEmail")}:</Label>
              <PRInput
                invalid={formik.touched.info_email && formik.errors.info_email}
                name="info_email"
                value={formik.values.info_email}
                onChange={formik.handleChange}
              />
            </Col>
            {/* <Col xs={12}>
            <Switch checked={enableMailOptions} onChange={() => setEnableMailOptions(!enableMailOptions)} />
            <Label>Enable Mail Options</Label>
          </Col> */}
            <Col xs={12}>
              <Label>{t("dashboard.myProjectSettings.senderEmailType")}:</Label>
              <PRSelect
                isPrimitiveValue
                invalid={formik.touched.info_email_type && formik.errors.info_email_type}
                isClearable={false}
                options={emailTypesOptions}
                value={formik.values.info_email_type}
                onChange={handleEmailTypeChange}
              />
            </Col>
            <Col xs={12}>
              <Label>{t("dashboard.myProjectSettings.replyToEmail")}:</Label>
              <PRInput
                invalid={formik.touched.reply_to_email && formik.errors.reply_to_email}
                name="reply_to_email"
                value={formik.values.reply_to_email}
                onChange={formik.handleChange}
              />
            </Col>
            <>
              {formik.values.info_email_type === emailTypes.SMTP && (
                <>
                  <Col xs={12}>
                    <Label>{t("dashboard.myProjectSettings.smtpUsername")}:</Label>
                    <PRInput
                      invalid={formik.touched.mailer_username && formik.errors.mailer_username}
                      name="mailer_username"
                      value={formik.values.mailer_username}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col xs={12}>
                    <Label>{t("dashboard.myProjectSettings.smtpPassword")}:</Label>
                    <PRInput
                      invalid={formik.touched.mailer_password && formik.errors.mailer_password}
                      name="mailer_password"
                      value={formik.values.mailer_password}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col xs={12}>
                    <Label>{t("dashboard.myProjectSettings.smtpDomain")}:</Label>
                    <PRInput
                      invalid={formik.touched.mailer_domain && formik.errors.mailer_domain}
                      name="mailer_domain"
                      value={formik.values.mailer_domain}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col xs={12}>
                    <Label>{t("dashboard.myProjectSettings.smtpPort")}:</Label>
                    <PRInput
                      invalid={formik.touched.mailer_port && formik.errors.mailer_port}
                      name="mailer_port"
                      value={formik.values.mailer_port}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col xs={12}>
                    <Label className="d-flex align-items-center">
                      {t("dashboard.myProjectSettings.smtpEncryptionType")}:
                      <PalTooltip title={t("dashboard.myProjectSettings.SMTPEncryptionTypeTooltip")}>
                        <div>
                          <MdInfo />
                        </div>
                      </PalTooltip>
                    </Label>
                    <PRInput
                      invalid={formik.touched.mailer_encryption_type && formik.errors.mailer_encryption_type}
                      name="mailer_encryption_type"
                      value={formik.values.mailer_encryption_type}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </>
              )}
            </>
          </Row>
        )}
        {activeTab === "llm" && (
          <Row className="g-2 w-lg-50">
            <Col xs={12}>
              <Switch checked={enableLLMOptions} onChange={() => setEnableLLMOptions(!enableLLMOptions)} />
              <Label>{t("dashboard.myProjectSettings.enableLLM")}</Label>
            </Col>

            {enableLLMOptions && (
              <>
                <Col xs={12}>
                  <Label>{t("dashboard.myProjectSettings.LLMType")}:</Label>
                  <PRSelect
                    isPrimitiveValue
                    className="w-xl"
                    invalid={formik.touched.llmSettings?.llm_type && formik.errors.llmSettings?.llm_type}
                    isClearable={false}
                    options={llmTypeOptions}
                    value={formik.values.llmSettings?.llm_type}
                    onChange={handleChangeLlmType}
                  />
                </Col>
                <Col xs={12}>
                  <Label>{t("dashboard.myProjectSettings.submodel")}:</Label>
                  <PRInput
                    invalid={formik.touched.llmSettings?.submodel && formik.errors.llmSettings?.submodel}
                    name="llmSettings.submodel"
                    value={formik.values.llmSettings?.submodel}
                    onChange={formik.handleChange}
                  />
                </Col>
                <Col xs={12}>
                  <Label>{t("dashboard.myProjectSettings.accessToken")}:</Label>
                  <PRInput
                    invalid={formik.touched.llmSettings?.access_token && formik.errors.llmSettings?.access_token}
                    name="llmSettings.access_token"
                    value={formik.values.llmSettings?.access_token}
                    onChange={formik.handleChange}
                    onClick={onAccessTokenFocus}
                  />
                </Col>
              </>
            )}
          </Row>
        )}
        {activeTab === "livechat" && (
          <Row className="g-2 w-lg-50">
            {/* <Col xs={12}>
            <Label>Email Status:</Label>
            <b>{emailStatusMap[formik.values.email_status] || formik.values.email_status}</b>
          </Col> */}
            <Col xs={12}>
              <Label>{t("dashboard.myProjectSettings.livechatMode")}:</Label>

              <PRSelect
                isPrimitiveValue
                className="w-xl"
                isClearable={false}
                options={livechatCallcenterTypeOptions}
                value={formik.values.callcenterSettings?.callcenter_type}
                onChange={handleChangeCallcenterType}
              />
            </Col>
            <Col xs={12}>
              <Label>{t("dashboard.myProjectSettings.chatVisibility")}:</Label>
              <PRTooltip
                title={
                  <Box
                    sx={{
                      whiteSpace: "pre",
                    }}
                  >
                    {t("dashboard.myProjectSettings.chatVisibilityTooltip")}
                  </Box>
                }
              >
                <span>
                  <MdHelp className="ms-1 fs-6" />
                </span>
              </PRTooltip>
              <PRSelect
                isPrimitiveValue
                className="w-xl"
                isClearable={false}
                options={chatbotAgentVisibilityTypeOptions}
                value={formik.values.callcenterSettings?.chat_visibility}
                onChange={handleChangeAgentVisibilityType}
              />
            </Col>
            <Col md="12">
              <Switch
                checked={formik.values.callcenterSettings?.agent_can_leave_session}
                name="callcenterSettings.agent_can_leave_session"
                onChange={handleChangeSwitch("callcenterSettings.agent_can_leave_session")}
              />
              {t("dashboard.myProjectSettings.workHours")}:
              <Box display="flex" flexDirection="row" gap={2}>
                <PalTimePicker
                  fullWidth
                  label="Select Start Time"
                  value={formik.values.work_start_time}
                  onChange={handleChangeSwitch("work_start_time")}
                />
                <PalTimePicker
                  fullWidth
                  label="Select End Time"
                  value={formik.values.work_end_time}
                  onChange={handleChangeSwitch("work_end_time")}
                />
              </Box>
            </Col>
            <Col md="12">
              <Switch
                checked={formik.values.callcenterSettings?.agent_can_leave_session}
                name="callcenterSettings.agent_can_leave_session"
                onChange={handleChangeSwitch("callcenterSettings.agent_can_leave_session")}
              />
              {t("dashboard.myProjectSettings.agentCanLeaveSession")}
            </Col>
            <Col md="12">
              <Switch
                checked={formik.values.show_queue_order}
                name="show_queue_order"
                onChange={handleChangeSwitch("show_queue_order")}
              />
              {t("dashboard.myProjectSettings.showQueueOrder")}
            </Col>
            <Col md="12">
              <Switch
                checked={formik.values.chat_label_enabled}
                name="chat_label_enabled"
                onChange={handleChangeSwitch("chat_label_enabled")}
              />
              {t("dashboard.myProjectSettings.enableChatLabel")}
            </Col>
            <Col md="12">
              <Switch
                checked={formik.values.ticket_enabled}
                name="ticket_enabled"
                onChange={handleChangeSwitch("ticket_enabled")}
              />
              {t("dashboard.myProjectSettings.enableTicket")}
            </Col>
            <Col md="12">
              <Switch
                checked={formik.values.direct_to_ticket_enabled}
                disabled={!formik.values.ticket_enabled}
                name="direct_to_ticket_enabled"
                onChange={handleChangeSwitch("direct_to_ticket_enabled")}
              />
              {t("dashboard.myProjectSettings.enableDirectToTicket")}
            </Col>
            <Col md="12">
              <Switch
                checked={formik.values.callcenterSettings?.session_closing_code_enabled}
                name="callcenterSettings.session_closing_code_enabled"
                onChange={handleChangeSwitch("callcenterSettings.session_closing_code_enabled")}
              />
              {t("dashboard.myProjectSettings.enableAgentClosingMessage")}
              {formik.values.callcenterSettings?.session_closing_code_enabled && (
                <Box ml={2}>
                  <PalCheckbox
                    checked={formik.values.callcenterSettings?.session_closing_code_mandatory}
                    name="callcenterSettings.session_closing_code_mandatory"
                    onChange={handleChangeSwitch("callcenterSettings.session_closing_code_mandatory")}
                  />
                  {t("dashboard.myProjectSettings.mandatoryAgentClosingMessage")}
                </Box>
              )}
            </Col>
            <Col md="12">
              <Switch
                checked={formik.values.callcenterSettings?.session_label_enabled}
                name="callcenterSettings.session_label_enabled"
                onChange={handleChangeSwitch("callcenterSettings.session_label_enabled")}
              />
              {t("dashboard.myProjectSettings.enableSessionLabel")}
              {formik.values.callcenterSettings?.session_label_enabled && (
                <Box ml={2}>
                  <PalCheckbox
                    checked={formik.values.callcenterSettings?.session_label_mandatory}
                    name="callcenterSettings.session_label_mandatory"
                    onChange={handleChangeSwitch("callcenterSettings.session_label_mandatory")}
                  />
                  {t("dashboard.myProjectSettings.mandatorySessionLabel")}
                </Box>
              )}
            </Col>
            {/* <Col md="12">
            <Label size="md">Show Bot Sessions</Label>
            <PRInput
              checked={formik.values.callcenterSettings?.show_bot_sessions}
              name="callcenterSettings.show_bot_sessions"
              type="checkbox"
              onChange={formik.handleChange}
            />
          </Col> */}
          </Row>
        )}
        {activeTab === "timeout" && (
          <Row className="g-2">
            <PRTab
              isTranslate
              tab={timeoutTab}
              tabList={projectTimeoutTimesOptions}
              valueSelector="value"
              onChange={setTimeoutTab}
            />
            <TimeoutTimesItem
              tab={timeoutTab}
              value={{
                ...defaultTimeoutTimes,
                ...formik.values.timeoutTimes?.[timeoutTab],
              }}
              onChange={handleChangeTimeoutTimes}
            />
          </Row>
        )}
        {activeTab === "system" && (
          <>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="gap-2 d-flex align-items-center">
                <PRSelect
                  isPrimitiveValue
                  className="w-xl"
                  isClearable={false}
                  options={availableLanguageListOptions}
                  value={selectedLanguage}
                  onChange={handleChangeLanguageSystemMessage}
                />
                <PRSelect
                  isPrimitiveValue
                  className="w-xl"
                  isClearable={false}
                  options={projectTranslateKeyOptions}
                  value={selectedTranslateKey}
                  onChange={setSelectedTranslateKey}
                />
              </div>
            </div>
            <PRTab
              isTranslate
              className="mb-1"
              tab={offlineMessageTab}
              tabList={projectTimeoutTimesOptions}
              valueSelector="value"
              onChange={setOfflineMessageTab}
            />
            <PRTextArea
              key={offlineMessageTab}
              editorMode
              editorProps={{
                defaultHeight: 180,
              }}
              excludeFormatList={excludeFormatList}
              format={activeOfflineMessageData.format || activeDefaultOfflineMessageData.format}
              value={activeOfflineMessageData.value ?? activeDefaultOfflineMessageData.value}
              onChange={handleChangeOfflineMessageValue}
              onFormatChange={handleChangeOfflineMessageFormat}
            />
          </>
        )}

        <Row className="justify-content-end mt-2">
          <Col md="auto">
            {!!currentProjectId && (
              <PRButton outline className="" onClick={handleClickCancel}>
                {t("common.cancel")}
              </PRButton>
            )}
            <PRButton className="ms-2" onClick={formik.handleSubmit}>
              {currentProjectId ? t("common.save") : t("common.create")}
            </PRButton>
          </Col>
        </Row>
      </PRPage>
    </PRContainer>
  );
}
