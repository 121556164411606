import { createSelector } from "reselect";

/** @type {() => typeof import("./reducer").initState} */
export const selectRoot = (state) => state.closingCode;

export const selectClosingCode = createSelector(selectRoot, (root) => root.closingCode);

export const selectFilteredClosingCode = createSelector(selectClosingCode, (closingCode) =>
  closingCode.filter((code) => code.isActive)
);
