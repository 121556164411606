import { useEffect, useMemo, useState } from "react";

import { Menu, MenuItem, Sidebar as SB, SubMenu } from "@hepter/react-pro-sidebar";
import { PalBadge, PalTypography } from "@palamar/fe-library";
import classNames from "classnames";
import { compareVersions } from "compare-versions";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Col, Label, Row } from "reactstrap";

// @svgr/webpack import syntax
import styled from "@emotion/styled";
import { Circle, OutlinedFlag, Remove } from "@mui/icons-material";
import { Box, Chip } from "@mui/material";

import { ReactComponent as ChevronIcon } from "~assets/images/icons/sidebar-chevron.svg";
import { ReactComponent as PrefixIcon } from "~assets/images/icons/sidebar-prefix.svg";
import { ReactComponent as LogoLightWide } from "~assets/images/logo-palmate-dark.svg";
import PRButton from "~components/Generic/PRButton";
import PRLink from "~components/Generic/PRLink";
import { LS_APP_VERSION_CHECKED_VERSION, LS_SIDEBAR_MINIMIZED, onboardingProjectStatus } from "~constants";
import HistoryHelper from "~helpers/HistoryHelper";
import StorageHelper from "~helpers/StorageHelper";
import { authProtectedRoutes, sidebarMenuData } from "~routes";
import { getLatestAppVersion, setSidebar } from "~store/actions";
import { selectLatestAppVersion, selectSidebar } from "~store/layout/selectors";
import { selectPopupSettingsState } from "~store/settings/popupSettings/selectors";
import { selectCurrentProject, selectUserInfo } from "~store/user/selectors";

import AppVersionModal from "./AppVersionModal";

const headerHeight = 72; // $header-height: 100px;
const headerHeightSm = 54; //  $header-height-sm: 54px;

const StyledChevronDiv = styled.div`
  transition: all 0.3s ease;
  ${(props) => (props.open ? "transform: rotate(90deg);" : "")}
  width: 12px;
  transform-origin: 50% 50%;
  opacity: ${(props) => (props.open ? 1 : 0.55)};
`;
const MenuChevron = ({ open }) => {
  const sidebar = useSelector(selectSidebar);
  if (sidebar.minimized) return null;
  return (
    <StyledChevronDiv open={open}>
      <ChevronIcon />
    </StyledChevronDiv>
  );
};

const StyledSubPrefix = styled(PrefixIcon, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "minimized",
})`
  flex: 0 0 auto;
  opacity: ${(props) => (props.open ? 1 : 0.55)};
  margin-left: ${(props) => (props.minimized ? 0 : 20)}px;
  margin-right: 11px;
`;
const StyledSubPrefixLevel2 = styled(Remove, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "minimized",
})`
  flex: 0 0 auto;
  opacity: ${(props) => (props.open ? 1 : 0.55)};
  margin-left: ${(props) => (props.minimized ? 0 : 17)}px;
  margin-right: 10px;
  width: 10px;
`;
const StyledSubmenuPrefix = styled(Circle, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "minimized",
})`
  flex: 0 0 auto;
  opacity: ${(props) => (props.open ? 1 : 0.55)};
  margin-left: ${(props) => (props.minimized ? 0 : 18)}px;
  margin-right: 10px;
  width: 8px;
`;

const StyledSB = styled(SB)`
  border: none !important;

  .ps-submenu-content {
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 16px 0; */
  }
  .menu-divider {
    width: auto;
    height: 1px;
    margin: 10px 25px;
    background-color: #efeded;
  }
  .ps-sidebar-container,
  .ps-sidebar-container::-webkit-scrollbar-track {
    background: #fafafa;
  }

  .ps__rail-y:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .menu-sidebar-close {
    position: absolute;
    top: 10px;
    right: 10px;
    svg {
      height: 24px;
      width: auto;
      transition: all 0.15s ease;
      &:hover {
        color: var(--bs-gray-400);
        cursor: pointer;
      }
    }
  }
  .sidebar-logo {
    padding: 30px 25px;
    svg {
      height: 40px;
      width: auto;
      //smooth render
      shape-rendering: geometricPrecision;
    }
    transition: all 0.3s ease;
    width: 0px;
    padding-right: 40px;
    overflow: hidden;
    &-minimize {
      padding-right: 166.88px; //logo width */
      overflow: hidden;
    }
    &:not(.sidebar-logo-minimize) {
      padding-left: 20px;
    }
  }
  .superuser-menu {
    color: var(--bs-danger-400) !important;
    &:hover {
      color: var(--bs-danger) !important;
    }
    &.ps-active {
      color: var(--bs-danger) !important;
    }
  }

  &::after {
    content: "";
    border-right: 1px solid #ddd;
    position: absolute;
    top: calc(${headerHeight}px / 2 - 20px);
    right: -1px;
    height: 40px;
    @media (max-width: 576px) {
      height: ${headerHeightSm}px;
      top: calc(${headerHeightSm}px / 2 - 20px);
    }
  }

  .side-logo {
    height: ${headerHeight}px;
    @media (max-width: 576px) {
      height: ${headerHeightSm}px;
    }
    margin-bottom: 27px;
  }

  /* & > .ps-menu-button {
    padding-left: 40px;
    padding-right: 40px;
  } */

  .ps-menu-icon {
    svg {
      max-width: 27px;
      max-height: 27px;
      height: 27px;
      width: 27px;
    }
    .sidebar-level-0 {
      /* padding-right: 7px; */
    }
    /* width: 21px !important;*/
    /* min-width: 21px !important;
    width: 21px !important; */
    .sidebar-submenu-level-1 {
      /* margin-right: -2px; */
      svg {
        max-width: 20px;
        max-height: 20px;
        height: 20px;
        width: 20px;
      }
    }
  }
`;

const StyledVersion = styled.div`
  font-size: 14px;
  /* margin-left: auto; */
  margin-left: ${(props) => (props.minimized ? "auto" : "32px")};
  margin-right: auto;
  margin-bottom: 10px;
  .app-version {
    font-weight: ${(props) => (props.newAppVersion ? "700" : "100")};
    color: #000;
    opacity: 0.7;
  }
  user-select: none;
  display: flex;
  align-items: center;

  &:hover {
    color: var(--bs-primary);
    cursor: pointer;
  }
`;
const Sidebar = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [openState, setOpenState] = useState({});

  const currentProject = useSelector(selectCurrentProject);
  const sidebar = useSelector(selectSidebar);
  const userInfo = useSelector(selectUserInfo);
  const popupSettings = useSelector(selectPopupSettingsState);
  const latestAppVersion = useSelector(selectLatestAppVersion);
  const [newAppVersion, setNewAppVersion] = useState(false);

  useEffect(() => {
    (async () => {
      const minimizedStorage = await StorageHelper.get(LS_SIDEBAR_MINIMIZED);
      dispatch(
        setSidebar({
          ...minimizedStorage,
          loading: false,
        })
      );
      // setIsSidebarReady(true);
    })();
  }, [dispatch]);

  const filteredRoutes = useMemo(() => {
    const permissions = currentProject?.permissions || [];
    return authProtectedRoutes.filter((route) => {
      const routePermissions = route.permissions;
      if (route.noPermission && !!permissions?.length) return false;
      if (routePermissions) {
        const permList = Array.isArray(routePermissions) ? routePermissions : [routePermissions];
        if (!permList.length) return true;
        return permList.some((permission) => permissions.includes(permission));
      }
      return true;
    });
  }, [currentProject?.permissions]);

  const handleOpenState = (path) => (state) => {
    const isOpen = openState?.[path] ?? Object.keys(openState).some((key) => key.startsWith(path));
    setOpenState({ [path]: !isOpen });
  };
  const hasPermission = (permission) => {
    const permList = currentProject?.permissions || [];
    if (Array.isArray(permission)) {
      return permission.some((perm) => permList.includes(perm));
    }
    return permList.includes(permission);
  };

  const handleToggleToggled = (state) => () => {
    const sidebarData = {
      mobile: sidebar.mobile,
      open: state,
      minimized: false,
    };

    dispatch(setSidebar(sidebarData));
  };

  useEffect(() => {
    if (!latestAppVersion) {
      dispatch(getLatestAppVersion());
    }
  }, [dispatch, latestAppVersion]);

  useEffect(() => {
    if (latestAppVersion?.app_version) {
      (async () => {
        const getLastOpenedVersion = await StorageHelper.get(LS_APP_VERSION_CHECKED_VERSION);
        if (
          getLastOpenedVersion &&
          typeof getLastOpenedVersion === "string" &&
          compareVersions(latestAppVersion.app_version, getLastOpenedVersion) > 0
        ) {
          setNewAppVersion(true);
        } else {
          StorageHelper.set(LS_APP_VERSION_CHECKED_VERSION, latestAppVersion.app_version);
        }
      })();
    }
  }, [dispatch, latestAppVersion]);

  const pathnameWithoutPrefix = useMemo(() => {
    const pathname = location.pathname;
    const chunks = pathname?.split("/");
    if (chunks.length > 5) {
      const pathChunks = "/" + pathname.split("/").slice(6).join("/");
      return pathChunks;
    }
    return pathname;
    //Do not remove 'location.pathname' dependency to render sidebar correctly
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    let orderedRoutes = [...filteredRoutes].sort((a, b) => b.path.split("/")?.length - a.path.split("/")?.length);
    const firstRoute = orderedRoutes.find((route) => pathnameWithoutPrefix.startsWith(route.path));

    if (firstRoute) {
      document.title = `${t(firstRoute.name)} | Palmate`;
      setOpenState({ [firstRoute.path]: true });
    }
  }, [filteredRoutes, pathnameWithoutPrefix, t]);

  const getMenuItem = (menuItem, { menuKey, parentPermissions, parentOnlySuperuser, level } = {}) => {
    if (menuItem.redirect) return null;

    //check popupSettings and hide unwanted menu items
    if (
      !popupSettings.ticket_enabled &&
      (menuItem?.path?.startsWith("/helpdesk") || menuItem?.path?.startsWith("/stats/helpdesk"))
    ) {
      return null;
    }

    const currentPermissions = menuItem?.permissions || parentPermissions;
    const onlySuperuser = menuItem?.onlySuperuser || parentOnlySuperuser;
    if (onlySuperuser && !userInfo.is_superuser) return null;
    else {
      //Skip permission check if user is superuser
      const hasNoNonProjectPermission = !currentProject?.id && !menuItem.noPermission;
      const hasNoMenuPermission = currentProject?.id && !hasPermission(currentPermissions);
      if (hasNoNonProjectPermission || hasNoMenuPermission) return null;
    }

    if (menuItem?.isLabel) {
      return (
        <Label
          key={menuKey}
          className="text-secondary"
          size="md"
          style={{
            // padding: 20,
            opacity: !sidebar.minimized ? 0.7 : 0,
          }}
          tag="h4"
        >
          {t(menuItem.name)}
        </Label>
      );
    } else if (menuItem?.isDivider) {
      if (sidebar.minimized) return null;
      return <div key={menuKey} className="menu-divider" />;
    } else if (menuItem?.children) {
      const isOpen = openState?.[menuItem.path] ?? Object.keys(openState).some((key) => key.startsWith(menuItem.path));
      const childrenItems = menuItem.children.map((item, subIndex) =>
        getMenuItem(item, {
          menuKey: `${menuKey}.${subIndex}`,
          parentPermissions: currentPermissions,
          parentOnlySuperuser: onlySuperuser,
          level: level + 1,
        })
      );

      return (
        <SubMenu
          key={menuKey}
          active={pathnameWithoutPrefix.startsWith(menuItem.path)}
          defaultOpen={isOpen}
          icon={
            menuItem.icon && (
              <span className={`sidebar-submenu-level-${level} sidebar-level-${level}`}>{menuItem.icon}</span>
            )
          }
          label={
            <Box>
              {level > 0 && !menuItem.icon && (
                <StyledSubmenuPrefix
                  minimized={sidebar.minimized}
                  open={pathnameWithoutPrefix.startsWith(menuItem.path)}
                />
              )}
              {t(menuItem.name)}
            </Box>
          }
          open={isOpen}
          onOpenChange={handleOpenState(menuItem.path)}
        >
          <Box>{childrenItems}</Box>
        </SubMenu>
      );
    }

    const SubPrefixComp = level > 1 ? StyledSubPrefixLevel2 : StyledSubPrefix;
    return (
      <MenuItem
        key={menuKey}
        active={pathnameWithoutPrefix.startsWith(menuItem.path)}
        component={
          <PRLink
            className={classNames({
              "superuser-menu": onlySuperuser,
            })}
            to={menuItem.path}
          />
        }
        icon={
          menuItem.icon && (
            <span className={`sidebar-menuitem-level-${level} sidebar-level-${level}`}>{menuItem.icon}</span>
          )
        }
      >
        <div className="d-flex align-items-center">
          {level > 0 && (
            <SubPrefixComp minimized={sidebar.minimized} open={pathnameWithoutPrefix.startsWith(menuItem.path)} />
          )}
          {t(menuItem.name)}
        </div>
      </MenuItem>
    );
  };
  const handleBreakpoint = (state) => {
    const sidebarData = {
      mobile: state,
      open: !state,
      minimized: sidebar?.minimized ?? true,
      loading: false,
    };

    dispatch(setSidebar(sidebarData));
  };

  const topSidebarMenuData = sidebarMenuData.filter((item) => item.position === "top" || !item.position);
  const bottomSidebarMenuData = sidebarMenuData.filter((item) => item.position === "bottom");

  const handleClickOnboarding = () => {
    HistoryHelper.push(`/dashboard/setup/${currentProject?.id}`, { scope: "" });
  };
  const MenuRenderer = (menuList, isBottom) => {
    return (
      <div
        style={{
          ...(isBottom && {
            marginBottom: 10 + 5,
          }),
        }}
      >
        {isBottom && <div className="menu-divider" />}
        {isBottom && currentProject?.status === onboardingProjectStatus.ONB && (
          <Box
            sx={{
              borderRadius: 1,
              borderColor: "rgba(0, 0, 0, 0.1)",
              margin: "0px 25px",
              padding: "10px 0px",
              display: "flex",
              whiteSpace: "nowrap",
              cursor: "pointer",
              alignItems: "center",
              gap: "10px",
            }}
            onClick={handleClickOnboarding}
          >
            <PalBadge color="error" invisible={!sidebar.minimized} variant="dot">
              <OutlinedFlag
                sx={{
                  color: "rgba(0, 0, 0, 0.65)",
                  fontSize: "32px",
                }}
              />
            </PalBadge>
            {!sidebar.minimized && (
              <Box>
                <PalBadge color="error" variant="dot">
                  <PalTypography
                    sx={{
                      fontWeight: 700,
                      color: "rgba(0, 0, 0, 0.75)",
                      marginRight: "4px",
                    }}
                    variant="body2"
                  >
                    Quick Start
                  </PalTypography>
                </PalBadge>
                <Box>
                  <PalTypography sx={{ color: "rgba(0, 0, 0, 0.65)" }} variant="caption">
                    Complete your tasks
                  </PalTypography>
                </Box>
              </Box>
            )}
          </Box>
        )}
        <Menu
          menuItemStyles={{
            root: ({ active }) => {
              return {
                color: "#000",
                fontFamily: "Museo Sans",
                fontSize: 15,
                fontWeight: 500,
                backgroundColor: sidebar.minimized && active ? "var(--bs-gray-200)" : "transparent",
              };
            },
            icon: (props) => {
              const { active, level } = props;
              return {
                // color: active ? "#838383" : "#2f36f9",
                marginRight: level === 0 ? "7px" : "2px",
                color: "rgb(83, 83, 83)",
              };
            },
            SubMenuExpandIcon: ({ active }) => ({
              color: !active ? "rgba(0, 0, 0, 0.85)" : "#535353",
            }),
            subMenuContent: ({ level, active }) => ({
              backgroundColor: (level === 0 && sidebar.open) || sidebar.minimized ? "##fff" : "#fafafa",
            }),
            button: ({ active, disabled, isSubmenu, level }) => {
              return {
                height: level === 0 ? 40 : 32,
                fontWeight: level > 0 ? "500" : !active ? "500" : "bold",
                color: level === 0 ? "rgba(0, 0, 0, 0.85)" : !active ? "rgba(0, 0, 0, 0.65)" : "#000",

                "&:hover": {
                  color: level == 0 ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.85)",
                },

                paddingLeft: sidebar.minimized ? 22.5 : 25,
                paddingRight: sidebar.minimized ? 22.5 : 25,
                marginBottom: 7,
              };
            },
            label: ({ open, active, level }) => ({
              userSelect: "none",
            }),
          }}
          renderExpandIcon={(props) => {
            const { open } = props;
            return <MenuChevron open={open} />;
          }}
        >
          {menuList.map((item, index) =>
            getMenuItem(item, {
              menuKey: `${isBottom ? "bottom" : "top"}.${index}`,
              level: 0,
            })
          )}
        </Menu>
      </div>
    );
  };

  const handleClickAppVersion = () => {
    AppVersionModal.show();
    StorageHelper.set(LS_APP_VERSION_CHECKED_VERSION, latestAppVersion?.app_version);
    setNewAppVersion(false);
  };
  if (sidebar.loading) {
    return null;
  }
  return (
    <StyledSB
      breakPoint="lg"
      collapsed={sidebar.minimized}
      id="side-menu"
      rootStyles={{
        height: "100%",
        // fontSize: "0.9rem",
        fontFamily: "var(--bs-font-sans-serif)",
      }}
      toggled={sidebar.open}
      transitionDuration={300}
      width="240px"
      onBackdropClick={handleToggleToggled(false)}
      onBreakPoint={handleBreakpoint}
    >
      <Row className="g-0 flex-column flex-nowrap h-100">
        <Col xs>
          <div className="side-logo">
            {!!sidebar.mobile && (
              <div className="menu-sidebar-close d-flex justify-content-end align-items-center">
                <PRButton
                  noBorder
                  outline
                  round
                  color="secondary"
                  icon={MdClose}
                  onClick={handleToggleToggled(false)}
                />
              </div>
            )}
            <PRLink to={filteredRoutes?.[0]?.path}>
              <div
                className={classNames({
                  "sidebar-logo": true,
                  "sidebar-logo-minimize": !sidebar.minimized,
                })}
              >
                <LogoLightWide />
              </div>
            </PRLink>
          </div>
          {MenuRenderer(topSidebarMenuData)}
        </Col>
        <Col xs="auto">{bottomSidebarMenuData?.length > 0 && MenuRenderer(bottomSidebarMenuData, true)}</Col>
        <Col className="d-flex align-items-center" xs="auto" onClick={handleClickAppVersion}>
          <StyledVersion minimized={sidebar.minimized} newAppVersion={newAppVersion}>
            {latestAppVersion?.app_version && <span className="app-version">V {latestAppVersion?.app_version}</span>}
            {!!newAppVersion && (
              <Chip
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent="New"
                color="primary"
                label="New"
                size="small"
                sx={{
                  ml: 1,
                }}
              />
            )}
          </StyledVersion>
        </Col>
      </Row>
    </StyledSB>
  );
};

export default Sidebar;
