import { createRef, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";

import { Box, Chip } from "@mui/material";

import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRTable from "~components/Generic/PRTable";
import PalTooltip from "~components/mui/PalTooltip";
import { apiUrlClosingCode } from "~constants";
import DialogHelper from "~helpers/DialogHelper";
import { deleteClosingCode } from "~store/closingCode/actions";
import { selectCurrentProject } from "~store/user/selectors";

const ClosingCodeList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = createRef();
  const currentProject = useSelector(selectCurrentProject);

  const actions = useMemo(() => {
    return [
      {
        label: t("common.createNew"),
        icon: MdAdd,
        color: "success",
        link: "/contactcenter/settings/closing-code/form",
      },
    ];
  }, []);

  const columns = useMemo(() => {
    const handleClickDelete = (row) => async () => {
      if (await DialogHelper.showQuestionDeleteWithFormat(row.key)) {
        dispatch(deleteClosingCode(currentProject.id, row.id)).then(() => {
          tableRef.current.refresh();
        });
      }
    };
    return [
      {
        label: t("common.label"),
        key: "key",
      },
      {
        label: t("common.color"),
        key: "color",
        render: (row) => {
          return (
            <Box
              sx={{
                width: "20px",
                height: "20px",
                backgroundColor: row.color,
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            ></Box>
          );
        },
      },
      {
        label: t("dashboard.chatLabelsList.agents"),
        key: "agents",
        render: (row) => {
          return (
            <Box alignItems="center" display="flex" gap={1}>
              {row.agents?.map((agent) => (
                <Chip key={agent.id} label={agent.name} size="small" />
              ))}
            </Box>
          );
        },
      },
      {
        label: t("dashboard.agentGroupsList.agentGroups"),
        key: "agent_groups",
        render: (row) => {
          return (
            <Box alignItems="center" display="flex" gap={1}>
              {row.agent_groups?.map((agent) => (
                <PalTooltip key={agent.id} title={`Agents: ${agent.agents.map((agent) => agent.name).join(", ")}`}>
                  <Chip key={agent.id} label={agent.name} size="small" />
                </PalTooltip>
              ))}
            </Box>
          );
        },
      },
      {
        label: t("common.actions"),
        key: "actions",
        actions: true,
        render: (row) => (
          <div className="d-flex justify-content-center">
            <PRButton
              outline
              color="primary"
              icon={MdEdit}
              link={`/contactcenter/settings/closing-code/form/${row.id}`}
              size="sm"
              tooltipText={t("common.edit")}
            />
            <PRButton
              outline
              className="ms-1"
              color="danger"
              icon={MdDelete}
              size="sm"
              tooltipText={t("common.delete")}
              onClick={handleClickDelete(row)}
            />
          </div>
        ),
      },
    ];
  }, [currentProject.id, dispatch, tableRef, t]);
  return (
    <PRContainer
      actions={actions}
      description={t("dashboard.chatLabelsList.description")}
      name={t("common.contactCenter")}
      parentName={t("dashboard.chatLabels")}
    >
      <PRTable
        ref={tableRef}
        className="" //
        columns={columns}
        url={apiUrlClosingCode.get.format(currentProject.id)}
      />
    </PRContainer>
  );
};

export default ClosingCodeList;
