import { useEffect, useMemo } from "react";

import { PalAutoComplete, PalTextField } from "@palamar/fe-library";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { Grid } from "@mui/material";

import useProjectChange from "~common/hooks/useProjectChange";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRPage from "~components/Generic/PRPage";
import { userRole } from "~constants";
import HistoryHelper from "~helpers/HistoryHelper";
import { getPermissionUserList } from "~store/settings/permission/actions";
import { selectPermissionUserList } from "~store/settings/permission/selectors";
import { getCallcenterSettings, updateCallcenterSettings } from "~store/socket/livechat/actions";
import { selectCallcenterSettings } from "~store/socket/livechat/selectors";
import { selectCurrentProject } from "~store/user/selectors";

const AgentQueuesEdit = () => {
  const { t } = useTranslation();
  const { id: idUnparsed } = useParams();
  const id = parseInt(idUnparsed) || null;

  const dispatch = useDispatch();

  const currentProject = useSelector(selectCurrentProject);
  const permissionUserList = useSelector(selectPermissionUserList);

  const callcenterSettings = useSelector(selectCallcenterSettings);

  const handleClickCancel = () => {
    HistoryHelper.goBack("/contactcenter/settings/agent-queues/", { scope: "dashboard" });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      agents: [],
      agent_groups: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("component.formik.required").format(t("common.label"))),
    }),
    onSubmit: async (values) => {
      await dispatch(
        updateCallcenterSettings(
          currentProject.id,
          {
            ...callcenterSettings,
            queues: [
              ...(callcenterSettings.queues || [])
                .filter((item) => item.id !== id)
                .map((item) => ({
                  ...item,
                  agents: item.agents.map((agent) => agent.id),
                  agent_groups: item.agent_groups.map((agentGroup) => agentGroup.id),
                })),
              {
                ...values,
              },
            ],
          },
          {
            updateAgentQueues: true,
          }
        )
      );

      handleClickCancel();
    },
  });

  useEffect(() => {
    dispatch(getPermissionUserList(currentProject.id));
    dispatch(getCallcenterSettings(currentProject?.id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, currentProject.id]);

  useEffect(() => {
    if (callcenterSettings?.queues?.length && id) {
      //try parse to number if fail return null
      const matchedAgentGroup = callcenterSettings.queues?.find((item) => item.id === id);
      if (matchedAgentGroup) {
        formik.setValues({
          ...matchedAgentGroup,
          agents: matchedAgentGroup.agents.map((agent) => agent.id),
          agent_groups: matchedAgentGroup.agent_groups.map((agentGroup) => agentGroup.id),
        });
      }
    }
  }, [callcenterSettings, id]);

  useProjectChange(() => {
    HistoryHelper.push("/contactcenter/settings/agent-queues/", { scope: "dashboard" });
  }, []);

  const parentName = useMemo(
    () => [
      {
        label: t("dashboard.agentQueues"),
        url: "/contactcenter/settings/agent-queues",
      },
      {
        label: id ? t("dashboard.agentQueues.edit") : t("dashboard.agentQueues.create"),
      },
    ],
    [id, t]
  );

  const permissionUserOptions = useMemo(() => {
    const livechatValidRoles = [userRole.admin, userRole.callCenterAgent];

    return permissionUserList
      .filter((item) => item?.roles?.some((role) => livechatValidRoles.includes(role)))
      .map((item) => ({
        label: `${item.firstname} ${item.lastname} (${item.email})`,
        value: item.id,
      }));
  }, [permissionUserList]);

  const agentGroupList = useMemo(() => {
    return (callcenterSettings?.agent_groups || []).map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [callcenterSettings]);

  const handleChangeAgents = (e, agents) => {
    formik.setFieldValue("agents", agents);
  };

  const handleChangeAgentGroups = (e, agentGroups) => {
    formik.setFieldValue("agent_groups", agentGroups);
  };
  return (
    <PRContainer name={t("common.contactCenter")} parentName={parentName}>
      <PRPage>
        <Grid
          container
          spacing={2}
          width={{
            xs: 1,
            sm: 1,
            md: 1 / 2,
          }}
        >
          <Grid item xs={12}>
            <PalTextField
              fullWidth
              invalid={formik.touched.name && formik.errors.name}
              label={t("dashboard.agentGroups.name")}
              name="name"
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <PalAutoComplete
              isPrimitiveValue
              multiple
              invalid={formik.touched.agents && formik.errors.agents}
              label={t("common.agents")}
              name="agents"
              options={permissionUserOptions}
              value={formik.values.agents}
              onBlur={formik.handleBlur}
              onChange={handleChangeAgents}
            />
          </Grid>
          <Grid item xs={12}>
            <PalAutoComplete
              isPrimitiveValue
              multiple
              invalid={formik.touched.agentGroups && formik.errors.agentGroups}
              label={t("dashboard.agentGroups.agentGroups")}
              name="agentGroups"
              options={agentGroupList}
              value={formik.values.agent_groups}
              onBlur={formik.handleBlur}
              onChange={handleChangeAgentGroups}
            />
          </Grid>
        </Grid>

        <Grid container mt={2}>
          <Grid item ml="auto">
            <PRButton outline className="me-2" onClick={handleClickCancel}>
              {t("common.cancel")}
            </PRButton>
            <PRButton onClick={formik.handleSubmit}>{id ? t("common.update") : t("common.create")}</PRButton>
          </Grid>
        </Grid>
      </PRPage>
    </PRContainer>
  );
};

export default AgentQueuesEdit;
