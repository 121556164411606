import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { MdDragHandle, MdExpandMore, MdFullscreen } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { useParams } from "react-router-dom";
import { FormGroup, Label } from "reactstrap";

import styled from "@emotion/styled";
import { Box } from "@mui/material";

import useBootstrapBreakpoint from "~common/hooks/useBootstrapBreakpoint";
import { useDebouncedEffect } from "~common/hooks/useDebounceEffect";
import { useStorage } from "~common/hooks/useStorage";
import PRContainer from "~components/Generic/PRContainer";
import PRInput from "~components/Generic/PRInput";
import { PRCard } from "~components/Generic/PRPage";
import PRPagination from "~components/Generic/PRPagination";
import PalFilter from "~components/PalFilter";
import SessionChat from "~components/Session/SessionChat";
import SetSessionLabelModal from "~components/Session/SessionChat/SetSessionLabelModal";
import SessionList from "~components/Session/SessionList";
import {
  apiUrlLiveChatHistory,
  chatbotHistoryPageSize,
  chatbotSessionStatusOptions,
  popupIconType,
  tableFilterStorageKey,
  userRole,
} from "~constants";
import DateHelper from "~helpers/DateHelper";
import HistoryHelper from "~helpers/HistoryHelper";
import Network from "~helpers/Network";
import Utils from "~helpers/Utils";
import {
  getCompletedSession,
  getCompletedSessions,
  getSessionGetterSetter,
  setIsGetterSetterDrawerOpen,
  setSelectedSession,
  updateLabelAndClosingCodesHistoryById,
} from "~store/chathistory/actions";
import {
  selectIsGetterSetter,
  selectSelectedSession,
  selectSessionId,
  selectSessionResponse,
} from "~store/chathistory/selectors";
import { getPermissionUserList } from "~store/settings/permission/actions";
import { selectPermissionUserList } from "~store/settings/permission/selectors";
import { getProjectSettings, setLivechatLoadingStatus } from "~store/socket/livechat/actions";
import { selectProjectSettings } from "~store/socket/livechat/selectors";
import { selectCurrentProject, selectCurrentProjectId, selectProjects } from "~store/user/selectors";

import { LiveSessionGetterSetterContent, LiveSessionGetterSetterDrawer } from "./LiveSessionGetterSetter";
import SessionStatisticMessage from "./SessionStatisticMessage";
import { FullScreenChatModal, LiveChatFullscreenModal } from "../LiveChat";

function LiveSessionChat({ livechatListRef, hideInput }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedSession = useSelector(selectSelectedSession);
  const [selectedSessionState, setSelectedSessionState] = useState(selectedSession);
  const projectSettings = useSelector(selectProjectSettings);
  const isLg = useBootstrapBreakpoint("lg");
  const projects = useSelector(selectProjects);

  const matchedWelcomeSettings = useMemo(() => {
    if (!selectedSession?.project) return null;
    return projectSettings.find((project) => project.project === selectedSession.project);
  }, [projectSettings, selectedSession?.project]);

  useEffect(() => {
    const session = { ...selectedSession };
    if (session?.messages?.length) {
      const selectedSessionMessages = [...session.messages];

      const lastMessageTime = session.messages?.[session.messages.length - 1]?.message_time;
      selectedSessionMessages.push({
        type: "custom",
        position: "center",
        message_time: lastMessageTime?.clone().add(1, "minute"),
        render: ({ distributedRef }) => (
          <SessionStatisticMessage
            distributedRef={distributedRef}
            session={session}
            welcomeSettings={matchedWelcomeSettings}
          />
        ),
      });
      session.messages = selectedSessionMessages;
    } else {
      session.messages = [
        {
          type: "custom",
          position: "center",
          message_time: DateHelper.getDateTime(),
          render: () => (
            <div className="text-center">
              <h5 className="text-center m-0 text-secondary opacity-50">{t("component.liveSessionChat.noMessages")}</h5>
            </div>
          ),
        },
      ];
    }
    setSelectedSessionState(session);
  }, [selectedSession, matchedWelcomeSettings, t]);

  const [interactionInfo, setInteractionInfo] = useState({
    online: true,
    typing: false,
    focused: false,
    location: "",
  });
  useEffect(() => {
    const interactions = selectedSession?.interactions || [];
    const connections = selectedSession?.connections || [];

    const loopCount = Math.max(interactions.length, connections.length);
    const mergedInteractionsWithConnections = [];
    for (let i = 0; i < loopCount; i++) {
      const interaction = interactions[i];
      const connection = connections[i];
      if (interaction) {
        const relatedConnection = connections.find((i) => i.connection_id === interaction.connection_id);
        mergedInteractionsWithConnections.push({ ...relatedConnection, ...interaction });
      } else if (connection) {
        mergedInteractionsWithConnections.push({ ...connection });
      }
    }

    if (!mergedInteractionsWithConnections.length) return;
    const onlineList = mergedInteractionsWithConnections.filter((interaction) => interaction?.online);
    const typingInteraction = onlineList.find((interaction) => interaction.typing);
    const isTyping = !!typingInteraction;
    const isTypingStop = onlineList.some((interaction) => interaction.typing === false);
    const isFocused = onlineList.some((interaction) => interaction.focused);
    const locationFocused = onlineList.find((interaction) => interaction.location && interaction.focused);
    const location = onlineList.find((interaction) => interaction.location);
    const locationOfflineLatest = mergedInteractionsWithConnections.find((interaction) => interaction.location);
    const isTypingState = isTyping !== true && isTypingStop ? false : interactionInfo.typing || isTyping;

    const timeoutMs = 10000;

    setInteractionInfo((prev) => {
      const newStatus = {
        ...prev,
        typing: isTypingState,
        focused: isFocused,
        location:
          locationFocused?.location || location?.location || locationOfflineLatest?.location || prev.location || "",
        locationList: mergedInteractionsWithConnections.filter((interaction) => interaction.location),
        count: onlineList.length,
        online: onlineList.length > 0,
      };
      return newStatus;
    });
    let typingTimeout;
    if (isTypingState) {
      typingTimeout = setTimeout(() => {
        setInteractionInfo((prev) => ({ ...prev, typing: false }));
        // console.log("interactionInfo TX", false);
      }, timeoutMs);
    }
    return () => {
      // console.log("interactionInfo clear", true);
      clearTimeout(typingTimeout);
    };
  }, [selectedSession?.interactions, interactionInfo.typing, selectedSession?.connections]);

  const handleClickHistoryItem = useCallback((item) => {
    // HistoryHelper.push(`/contactcenter/history/${item.id}/`, { scope: "dashboard" });
    //open in new tab
    window.open(HistoryHelper.generateUrl(`/contactcenter/history/${item.id}/`), "_blank");
  }, []);

  const handleClickSetLabel = useCallback(
    async (session) => {
      const firstBot = projects.find((p) => p.id === session.project)?.chatbot_list?.[0];
      const result = await SetSessionLabelModal.show({
        projectId: session.project,
        botId: session.bot || firstBot?.id,
        sessionId: session.id,
        sessionLabels: session.session_labels,
      });

      if (result?.idList?.length > -1) {
        dispatch(updateLabelAndClosingCodesHistoryById(session.id));
        livechatListRef.current?.refreshFilter?.();
      }
    },
    [dispatch, projects, livechatListRef]
  );
  const SessionChatComp = (
    <SessionChat
      // scrollOnMessage={false}
      disableActions
      disableTextInput
      hideActions
      hideOnline
      chatMessageProps={{
        colorPrimary: matchedWelcomeSettings?.popup_primary_color,
        botLogo:
          matchedWelcomeSettings?.popup_style_info?.popup_header_icon_type === popupIconType.icon &&
          matchedWelcomeSettings?.popup_header_icon_path,
        botJoinedLogo:
          matchedWelcomeSettings?.popup_style_info?.popup_bot_icon_type === popupIconType.icon &&
          matchedWelcomeSettings?.popup_bot_icon_path,
        popupStyleInfo: matchedWelcomeSettings?.popup_style_info,
      }}
      hideInput={hideInput}
      interactionInfo={interactionInfo}
      selectedSession={selectedSessionState}
      onClickHistorySelect={handleClickHistoryItem}
      onClickSetLabel={handleClickSetLabel}
    />
  );

  const clearSelectedSession = useCallback(() => {
    HistoryHelper.replace(`/contactcenter/history/`, { scope: "dashboard" });
    dispatch(setSelectedSession(null));
  }, [dispatch]);

  if (isLg) {
    if (!selectedSession?.id) return null;
    return (
      <FullScreenChatModal fullscreen noPadding submitText="" onClose={clearSelectedSession}>
        {SessionChatComp}
      </FullScreenChatModal>
    );
  }
  return SessionChatComp;
}

const LiveSessionList = forwardRef(function LiveSessionList(props, ref) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({});
  const [showUniqueSession, setShowUniqueSession] = useStorage("chat-history-unique", false);

  const selectedSession = useSelector(selectSelectedSession);
  const { results: sessions, count } = useSelector(selectSessionResponse);
  const currentProject = useSelector(selectCurrentProject);
  const projects = useSelector(selectProjects);
  const [filterOptions, setFilterOptions] = useState([]);
  const permissionUserList = useSelector(selectPermissionUserList);
  const projectOptions = useMemo(() => {
    return projects.map((project) => ({
      value: project.id,
      label: project.name,
    }));
  }, [projects]);

  useEffect(() => {
    setPage(1);
    dispatch(getPermissionUserList(currentProject.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject.id]);

  useEffect(() => {
    Network.request(apiUrlLiveChatHistory.get, {
      method: "OPTIONS",
      onSuccess: (response) => {
        setFilterOptions(response?.options?.filter_fields || []);
      },
    });
  }, []);

  useImperativeHandle(ref, () => ({
    refreshFilter: () => {
      Network.request(apiUrlLiveChatHistory.get, {
        method: "OPTIONS",
        onSuccess: (response) => {
          setFilterOptions(response?.options?.filter_fields || []);
        },
      });
    },
  }));

  const agentList = useMemo(() => {
    return permissionUserList.filter((item) =>
      item.roles.some((r) => [userRole.admin, userRole.ticketAgent].includes(r))
    );
  }, [permissionUserList]);

  const agentListOptions = useMemo(() => {
    return agentList.map((item) => ({
      label: `${item.firstname} ${item.lastname} (${item.email})`,
      value: item.id,
    }));
  }, [agentList]);

  useEffect(() => {
    if (id && ((selectedSession?.id && id !== selectedSession?.id) || !selectedSession?.id)) {
      dispatch(getCompletedSession(id));
    }
  }, [dispatch, id, selectedSession?.id]);

  useEffect(() => {
    if (selectedSession?.id && selectedSession?.project) {
      dispatch(getSessionGetterSetter(selectedSession?.project, selectedSession?.id));
    }
  }, [dispatch, selectedSession?.id, selectedSession?.project]);

  useDebouncedEffect(
    () => {
      const controller = new AbortController();
      const pagingData = Utils.getPaging(page, chatbotHistoryPageSize);
      dispatch(
        getCompletedSessions(
          currentProject.id,
          {
            ...filter,
            ...pagingData,
            unique: showUniqueSession,
          },
          {
            signal: controller.signal,
          }
        )
      ).finally(() => {
        dispatch(setLivechatLoadingStatus(false));
      });
      return () => {
        controller.abort();
      };
    },
    [dispatch, currentProject.id, page, filter, showUniqueSession],
    250
  );

  const handleClick = useCallback(
    (session) => {
      dispatch(setSelectedSession(session));
      const locationParts = window.location.href.split("?");
      let url = `/contactcenter/history/${session.id}/`;
      if (locationParts.length > 1) {
        url += `?${locationParts[1]}`;
      }
      HistoryHelper.push(url, { scope: "dashboard" });
    },
    [dispatch]
  );

  const handleChangePage = useCallback(
    (newPage) => {
      if (page !== newPage) {
        dispatch(setLivechatLoadingStatus(true));
        setPage(newPage);
      }
    },
    [dispatch, page]
  );

  const handleChangeFilter = useCallback(
    (_filters, filterQuery) => {
      dispatch(setLivechatLoadingStatus(true));
      setFilter(filterQuery);
      setPage(1);
    },
    [dispatch]
  );

  const filters = useMemo(() => {
    const allFilterOptions = [...filterOptions];

    // const createdFilter = allFilterOptions.findIndex((filter) => filter.key === "created");
    // if (createdFilter > -1) {
    //   allFilterOptions[createdFilter] = {
    //     ...allFilterOptions[createdFilter],
    //     type: "date",
    //     key: "created",
    //     label: "Created",
    //     lookup_keys: ["lte", "gte", "exact"],
    //     multiple: false,
    //     default_lookup_key: "exact",
    //   };
    // }

    const sessionStatusFilter = allFilterOptions.findIndex((filter) => filter.key === "session_status");
    if (sessionStatusFilter > -1) {
      allFilterOptions[sessionStatusFilter] = {
        ...allFilterOptions[sessionStatusFilter],
        // type: "options",
        // key: "session_status",
        // label: "Status",
        options: chatbotSessionStatusOptions,
        // lookup_keys: ["in"],
        // multiple: true,
        // default_lookup_key: null,
      };
    }

    const projectFilter = allFilterOptions.findIndex((filter) => filter.key === "project");
    if (projectFilter > -1) {
      allFilterOptions[projectFilter] = {
        ...allFilterOptions[projectFilter],
        // type: "options",
        // key: "project",
        // label: "Project",
        options: projectOptions,
        // lookup_keys: ["in"],
        // multiple: true,
        // default_lookup_key: null,
      };
    }
    const projectAgent = allFilterOptions.findIndex((filter) => filter.key === "agent");
    if (projectAgent > -1) {
      allFilterOptions[projectAgent] = {
        ...allFilterOptions[projectAgent],
        options: agentListOptions,
        type: "options",
      };
    }

    return allFilterOptions;
  }, [projectOptions, filterOptions, agentListOptions]);

  const handleCheckedUniqueSession = (e) => {
    dispatch(setLivechatLoadingStatus(true));
    setShowUniqueSession(e.target.checked);
  };
  return (
    <>
      <div className="list-header">
        <div className="list-top">
          <div className="list-actions d-flex justify-content-between">
            <FormGroup switch className="mt-1 d-flex align-items-center">
              <PRInput checked={showUniqueSession} type="switch" onChange={handleCheckedUniqueSession} />
              <Label className="p-0" size="md">
                {t("component.liveSessionList.groupByCustomer")}
              </Label>
            </FormGroup>
            <PalFilter filters={filters} storageKey={tableFilterStorageKey.chatHistory} onChange={handleChangeFilter} />
          </div>
        </div>
      </div>
      <SessionList
        hideHeader
        hideHighlight
        selectedSession={selectedSession}
        sessions={sessions}
        onClick={handleClick}
      />
      {!!count && (
        <div className="d-flex justify-content-center w-100 mt-auto list-footer">
          <PRPagination
            className="my-1"
            pageSize={chatbotHistoryPageSize}
            total={count}
            value={page}
            onChange={handleChangePage}
          />
        </div>
      )}
    </>
  );
});
const StyledPanelResizeHandlerWrapper = styled.span`
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  transition: all 0.2s ease-in-out;
  margin: 0px 4px;
  border-radius: 10px;
  &:active,
  &:focus,
  &:hover {
    background-color: #f7f7f7;
  }

  ${(props) =>
    props.collapsed &&
    `
  background-color: #f7f7f7;
  `}
  svg {
    margin: -0.7rem;
    font-size: 1.5rem;
    color: var(--bs-gray-500);
  }
`;
const StyledDiv = styled.div`
  //3xxl max with bootstrap
  /* @media (min-width: 1920px) { */
  /* max-width: 1920px; */
  max-width: 100%;

  height: ${(props) => (props.isFullscreen ? "calc(var(--vh) * 100 - 52px)" : "calc(var(--vh) * 100 - 152px)")};
  /* } */

  .card {
    margin: 0;
  }
  .card-body {
    padding: 0px;
    position: relative;
  }
  .list-actions {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    svg {
      height: 18px;
      width: 18px;
      overflow: visible;
    }
    .MuiButtonBase-root {
      width: 36px;
      height: 36px;
      background-color: #f7f7f7;
    }
  }
  .list-header {
    flex: 0 0 auto;
    background-color: #fff;
    margin: 4px;
    padding: 5px 15px;
    /* border-radius: 14px; */
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    .list-top {
      /* display: flex; */
    }
  }
  .pr-livechat-sessionlist {
    flex: 1 0 0%;
  }
  .list-footer {
    flex: 0 0 auto;
  }
  .scrollbar-container {
    /* max-height: calc(var(--vh) * 100 - 250px);
    min-height: calc(var(--vh) * 30 - 400px);
    overflow-y: auto; */
  }
`;

export default function ChatHistory() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [getterSetterPanelCollapsed, setGetterSetterPanelCollapsed] = useState(false);

  const isXxlDown = useBootstrapBreakpoint("xxl");
  const isLgDown = useBootstrapBreakpoint("lg");

  const isSessionInfo = useSelector(selectIsGetterSetter);
  const sessionId = useSelector(selectSessionId);
  const projectId = useSelector(selectCurrentProjectId);
  const livechatListRef = useRef(null);

  const livechatListPanelRef = useRef(null);
  const livechatChatPanelRef = useRef(null);
  const getterSetterPanelRef = useRef(null);

  useEffect(() => {
    dispatch(getProjectSettings());
  }, [dispatch]);
  useEffect(() => {
    if (!isXxlDown) {
      dispatch(setIsGetterSetterDrawerOpen(false));
    }
  }, [isXxlDown, dispatch]);

  const handleGetterSetterCollapsed = (collapsed) => {
    setGetterSetterPanelCollapsed(collapsed);
  };

  const actions = useMemo(() => {
    return [
      {
        tooltipText: t("dashboard.chatHistory.fullscreenTooltip"),
        icon: MdFullscreen,
        color: "primary",
        onClick: () => setIsFullscreen((s) => !s),
      },
    ].filter(Boolean);
  }, [t]);

  const handleClickArrangeLivechatPanel = (e) => {
    //double clicked
    if (e.detail === 2) {
      const isGetterSetterVisible = !!isSessionInfo && !isXxlDown;
      if (isGetterSetterVisible && (!getterSetterPanelCollapsed || !getterSetterPanelRef.current.getCollapsed())) {
        getterSetterPanelRef.current.collapse();
        setGetterSetterPanelCollapsed(true);
      }
      const panelCount = 2 + (isGetterSetterVisible ? 1 : 0);
      const centerRatio = 100 / panelCount;
      livechatListPanelRef.current.resize(centerRatio);
    }
  };
  const handleToggleCollapseExpanded = (e) => {
    //double clicked
    if (e.detail === 2) {
      if (getterSetterPanelCollapsed) {
        getterSetterPanelRef.current.expand();
      } else {
        getterSetterPanelRef.current.collapse();
      }
      setGetterSetterPanelCollapsed(!getterSetterPanelCollapsed);
    }
  };

  return (
    <PRContainer
      fullHeight
      maxWidth
      actions={actions}
      className="pr-chat-history"
      // description={t("dashboard.chatHistory.description")}
      name={t("common.contactCenter")}
      parentName={t("dashboard.chatHistory")}
    >
      <LiveSessionGetterSetterDrawer />
      <ChatHistorySessionChatModalProvider />
      <LiveChatFullscreenModal historyMode open={isFullscreen} onClose={() => setIsFullscreen(false)}>
        <StyledDiv isFullscreen={isFullscreen}>
          <PanelGroup autoSaveId="livechat-panel" direction="horizontal">
            <Panel ref={livechatListPanelRef} minSize={20} order={1}>
              <Box display={"flex"} flexDirection={"column"} height="100%">
                <PRCard className="h-100" innerClassName="h-100 d-flex flex-column">
                  <LiveSessionList ref={livechatListRef} />
                </PRCard>
              </Box>
            </Panel>
            {!isLgDown && (
              <>
                <PanelResizeHandle>
                  <StyledPanelResizeHandlerWrapper onClick={handleClickArrangeLivechatPanel}>
                    <MdDragHandle className="rotate-90" />
                  </StyledPanelResizeHandlerWrapper>
                </PanelResizeHandle>
                <Panel ref={livechatChatPanelRef} minSize={20} order={2}>
                  <LiveSessionChat hideInput livechatListRef={livechatListRef} />
                </Panel>
              </>
            )}

            {isSessionInfo && !isXxlDown && (
              <>
                <PanelResizeHandle>
                  <StyledPanelResizeHandlerWrapper
                    collapsed={getterSetterPanelCollapsed}
                    onClick={handleToggleCollapseExpanded}
                  >
                    {getterSetterPanelCollapsed ? (
                      <MdExpandMore className="rotate-90" />
                    ) : (
                      <MdDragHandle className="rotate-90" />
                    )}
                  </StyledPanelResizeHandlerWrapper>
                </PanelResizeHandle>
                <Panel
                  ref={getterSetterPanelRef}
                  collapsible
                  defaultSize={20}
                  minSize={15}
                  order={3}
                  onCollapse={handleGetterSetterCollapsed}
                >
                  <LiveSessionGetterSetterContent projectId={projectId} sessionId={sessionId} />
                </Panel>
              </>
            )}
          </PanelGroup>
        </StyledDiv>
      </LiveChatFullscreenModal>
    </PRContainer>
  );
}

export function ChatHistorySessionChatModalProvider({ ...props }) {
  const dispatch = useDispatch();
  const isLgDown = useBootstrapBreakpoint("lg");

  const selectedSession = useSelector(selectSelectedSession);
  const clearSelectedSession = useCallback(() => {
    HistoryHelper.replace(
      {
        pathname: `/contactcenter/livechat/`,
        search: window.location.search,
      },
      { scope: "dashboard" }
    );
    dispatch(setSelectedSession(null));
  }, [dispatch]);

  if (isLgDown) {
    if (!selectedSession?.id) return null;
    return (
      <FullScreenChatModal onClose={clearSelectedSession}>
        <LiveSessionChat {...props} />
      </FullScreenChatModal>
    );
  }
}
