import { useEffect, useMemo } from "react";

import { PalAutoComplete, PalTextField, PalTypography } from "@palamar/fe-library";
import { useFormik } from "formik";
import { SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { Box, Grid } from "@mui/material";

import useProjectChange from "~common/hooks/useProjectChange";
import PRButton from "~components/Generic/PRButton";
import PRContainer from "~components/Generic/PRContainer";
import PRPage from "~components/Generic/PRPage";
import PRPopover from "~components/Generic/PRPopover";
import { userRole } from "~constants";
import HistoryHelper from "~helpers/HistoryHelper";
import { getPermissionUserList } from "~store/settings/permission/actions";
import { selectPermissionUserList } from "~store/settings/permission/selectors";
import { getCallcenterSettings, updateCallcenterSettings } from "~store/socket/livechat/actions";
import { selectCallcenterSettings } from "~store/socket/livechat/selectors";
import { selectCurrentProject } from "~store/user/selectors";

const AgentGroupEdit = () => {
  const { t } = useTranslation();
  const { id: idUnparsed } = useParams();
  const id = parseInt(idUnparsed) || null;

  const dispatch = useDispatch();

  const currentProject = useSelector(selectCurrentProject);
  const permissionUserList = useSelector(selectPermissionUserList);

  const callcenterSettings = useSelector(selectCallcenterSettings);

  const handleClickCancel = () => {
    HistoryHelper.goBack("/contactcenter/settings/agent-groups/", { scope: "dashboard" });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      color: "#ffffff",
      agents: [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("component.formik.required").format(t("common.label"))),
    }),
    onSubmit: async (values) => {
      await dispatch(
        updateCallcenterSettings(
          currentProject.id,
          {
            ...callcenterSettings,
            agent_groups: [
              ...(callcenterSettings.agent_groups || [])
                .filter((item) => item.id !== id)
                .map((item) => ({
                  ...item,
                  agents: item.agents.map((agent) => agent.id),
                })),
              {
                ...values,
              },
            ],
          },
          {
            updateAgentGroups: true,
          }
        )
      );

      handleClickCancel();
    },
  });

  useEffect(() => {
    dispatch(getPermissionUserList(currentProject.id));
    dispatch(getCallcenterSettings(currentProject?.id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, currentProject.id]);

  useEffect(() => {
    if (callcenterSettings?.agent_groups?.length && id) {
      //try parse to number if fail return null
      const matchedAgentGroup = callcenterSettings.agent_groups?.find((item) => item.id === id);
      if (matchedAgentGroup) {
        formik.setValues({
          ...matchedAgentGroup,
          agents: matchedAgentGroup.agents.map((agent) => agent.id),
        });
      }
    }
  }, [callcenterSettings, id]);
  useProjectChange(() => {
    HistoryHelper.push("/contactcenter/settings/agent-groups/", { scope: "dashboard" });
  }, []);

  const parentName = useMemo(
    () => [
      {
        label: t("dashboard.agentGroups"),
        url: "/contactcenter/settings/agent-groups",
      },
      {
        label: id ? t("dashboard.agentGroups.edit") : t("dashboard.agentGroups.create"),
      },
    ],
    [id, t]
  );

  const handleChangeColor = (color) => {
    formik.setFieldValue("color", color.hex);
  };

  const permissionUserOptions = useMemo(() => {
    const livechatValidRoles = [userRole.admin, userRole.callCenterAgent];

    return permissionUserList
      .filter((item) => item?.roles?.some((role) => livechatValidRoles.includes(role)))
      .map((item) => ({
        label: `${item.firstname} ${item.lastname} (${item.email})`,
        value: item.id,
      }));
  }, [permissionUserList]);

  const handleChangeAgents = (e, agents) => {
    formik.setFieldValue("agents", agents);
  };
  return (
    <PRContainer name={t("common.contactCenter")} parentName={parentName}>
      <PRPage>
        <Grid
          container
          spacing={2}
          width={{
            xs: 1,
            sm: 1,
            md: 1 / 2,
          }}
        >
          <Grid item xs={12}>
            <PalTextField
              fullWidth
              invalid={formik.touched.name && formik.errors.name}
              label={t("dashboard.agentGroups.name")}
              name="name"
              value={formik.values.name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <PalAutoComplete
              isPrimitiveValue
              multiple
              invalid={formik.touched.agents && formik.errors.agents}
              label={t("common.agents")}
              name="agents"
              options={permissionUserOptions}
              value={formik.values.agents}
              onBlur={formik.handleBlur}
              onChange={handleChangeAgents}
            />
          </Grid>
          <Grid item alignItems={"center"} display={"flex"} xs={12}>
            <PalTypography>{t("common.color")}</PalTypography>
            <Box ml={1}>
              <PRPopover
                content={
                  <>
                    <SketchPicker color={formik.values.color} onChange={handleChangeColor} />
                  </>
                }
              >
                <Box
                  sx={{
                    backgroundColor: formik.values.color || "#ffffff",
                    width: "20px",
                    height: "20px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
              </PRPopover>
            </Box>
          </Grid>
        </Grid>

        <Grid container mt={2}>
          <Grid item ml="auto">
            <PRButton outline className="me-2" onClick={handleClickCancel}>
              {t("common.cancel")}
            </PRButton>
            <PRButton onClick={formik.handleSubmit}>{id ? t("common.update") : t("common.create")}</PRButton>
          </Grid>
        </Grid>
      </PRPage>
    </PRContainer>
  );
};

export default AgentGroupEdit;
