import { apiUrlGetterSetter } from "~constants";
import Network from "~helpers/Network";
import Utils from "~helpers/Utils";

import * as at from "./actionTypes";
import { sessionHistoryToMessages } from "./reducer";

export const setSelectedSession = (payload) => ({
  type: at.SELECT_SESSION,
  payload,
});
export const setSessionResponse = (payload) => ({
  type: at.SET_SESSION_RESPONSE,
  payload,
});

export const setSessionGetterSetter = (payload) => ({
  type: at.SET_SESSION_GETTER_SETTER,
  payload,
});
export const setIsGetterSetterDrawerOpen = (payload) => ({
  type: at.SET_IS_GETTER_SETTER_DRAWER_OPEN,
  payload,
});

export const setLabelAndClosingCodeStateForHistory = (payload) => ({
  type: at.SET_LABEL_AND_CLOSING_CODE_STATE_HISTORY,
  payload,
});

let getCompletedSessionsAbortController;
export const getCompletedSessions = (projectId, queryObj) => (_dispatch, _getState) => {
  if (getCompletedSessionsAbortController) {
    getCompletedSessionsAbortController.abort();
  }
  getCompletedSessionsAbortController = new AbortController();
  return Network.request(
    `/customer/all-sessions2/${Utils.qs({
      ...queryObj,
    })}`,
    {
      method: "GET",
      onSuccess: setSessionResponse,
      signal: getCompletedSessionsAbortController.signal,
    }
  );
};

let getCompletedSessionAbortController;
export const getCompletedSession =
  (id, { disableState } = {}) =>
  async (_dispatch, _getState) => {
    if (getCompletedSessionAbortController) {
      getCompletedSessionAbortController.abort();
    }
    getCompletedSessionAbortController = new AbortController();
    const result = await Network.request(`/customer/all-sessions2/${id}/`, {
      method: "GET",
      ...(!disableState && {
        onSuccess: setSelectedSession,
      }),
      signal: getCompletedSessionAbortController.signal,
      // loading: true,
    });
    return sessionHistoryToMessages(result);
  };

export const getSessionGetterSetter = (projectId, id) => async (_dispatch, _getState) => {
  const response = await Network.request(apiUrlGetterSetter.get.format(projectId, id), {
    method: "GET",
  });
  _dispatch(setSessionGetterSetter(response?.result || []));
  return response?.result;
};

export const setGetterSetterAction = (projectId, id, action) => async (_dispatch, _getState) => {
  const response = await Network.request(apiUrlGetterSetter.get.format(projectId, id), {
    method: "POST",
    data: action,
    loading: true,
    successMsg: "Action successfully set",
  });
  return response?.result;
};

export const updateLabelAndClosingCodesHistoryById = (sessionId) => async (_dispatch, _getState) => {
  const sessionHistory = await _dispatch(getCompletedSession(sessionId, { disableState: true }));

  await _dispatch(
    setLabelAndClosingCodeStateForHistory({
      session_id: sessionId,
      session_labels: sessionHistory?.session_labels || [],
      closing_codes: sessionHistory?.closing_codes || [],
    })
  );
};
