import { useEffect, useMemo, useState } from "react";

import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { MdCircle } from "react-icons/md";
import { useSelector } from "react-redux";

import { Grid } from "@mui/material";

import PRContainer from "~components/Generic/PRContainer";
import PRPage from "~components/Generic/PRPage";
import PRTable from "~components/Generic/PRTable";
import PRTooltip from "~components/Generic/PRTooltip";
import LeaderboardFilterCard from "~components/Leaderboards/LeaderboardFilterCard";
import { apiUrlAgentLeaderboard, tableFilterStorageKey } from "~constants";
import DateHelper from "~helpers/DateHelper";
import { selectPopupSettingsState } from "~store/settings/popupSettings/selectors";
import { selectCurrentProject } from "~store/user/selectors";

const RenderAgentStatus = ({ status }) => {
  const { t } = useTranslation();
  return status === "AV" ? (
    <PRTooltip placement="top" title={t("common.online")}>
      <span>
        <MdCircle className="text-success fs-5 ms-1" />
      </span>
    </PRTooltip>
  ) : (
    <PRTooltip placement="top" title={t("common.offline")}>
      <span>
        <MdCircle className="text-secondary fs-5 ms-1" />
      </span>
    </PRTooltip>
  );
};

export default function AgentLeaderboard() {
  const { t } = useTranslation();
  const [filterSticked, setFilterSticked] = useState(false);

  const [genericQueryFilter, setGenericQueryFilter] = useState({
    begin_date: DateHelper.getDateTimeLocal().subtract(1, "month").format("YYYY-MM-DD"),
    end_date: DateHelper.getDateTimeLocal().format("YYYY-MM-DD"),
    filter_day: 1,
  });

  const currentProject = useSelector(selectCurrentProject);
  const popupSettings = useSelector(selectPopupSettingsState);

  const columns = useMemo(() => {
    return [
      {
        label: t("common.status"),
        key: "status",
        render: (row) => {
          return <RenderAgentStatus status={row?.status} />;
        },
      },
      {
        label: t("common.name"),
        key: "name",
      },
      popupSettings.show_thumps_up && {
        label: t("dashboard.agentLeaderboard.likeCount"),
        key: "like_count",
      },
      popupSettings.show_thumps_up && {
        label: t("dashboard.agentLeaderboard.dislikeCount"),
        key: "dislike_count",
      },
      {
        label: t("dashboard.agentLeaderboard.availableTime"),
        key: "available_time",
        render: (row) => {
          return <>{DateHelper.formatMoment(row?.available_time, "seconds", "h [hrs] m [min]")}</>;
        },
      },
      {
        label: t("dashboard.agentLeaderboard.chatTime"),
        key: "chat_time",
        render: (row) => {
          return <>{DateHelper.formatMoment(row?.chat_time, "seconds", "h [hrs] m [min]")}</>;
        },
      },
      {
        label: t("dashboard.agentLeaderboard.sessionCount"),
        key: "session_count",
      },
      {
        label: t("dashboard.agentLeaderboard.messageCount"),
        key: "message_count",
      },
      {
        label: t("dashboard.agentLeaderboard.maxActiveChatCount"),
        key: "max_active_chat_count",
      },
      // {
      //   label: "Actions",
      //   key: "actions",
      //   actions: true,
      //   render: (row) => (
      //     <div className="d-flex justify-content-center">
      //       <PRButton
      //         outline
      //         color="primary"
      //         icon={MdEdit}
      //         link={`/contactcenter/agents/form/${row.id}`}
      //         size="sm"
      //         tooltipText="Edit"
      //       />
      //       {/* <PRButton
      //         outline
      //         className="ms-1"
      //         color="danger"
      //         icon={MdDelete}
      //         size="sm"
      //         tooltipText="Delete"
      //         onClick={handleDelete(row)}
      //       /> */}
      //     </div>
      //   ),
      // },
    ].filter(Boolean);
  }, [popupSettings, t]);

  useEffect(() => {
    const positionHandler = () => {
      const filterDom = document.getElementById("filter-col");
      if (!filterDom) return;

      const { top } = filterDom.getBoundingClientRect();
      const limit = 110; // header + padding-top
      // const limit = 70 + 24; // header + padding-top
      if (top <= limit) {
        setFilterSticked(true);
      } else {
        setFilterSticked(false);
      }
    };

    const filterDom = document.getElementById("filter-col");
    if (!filterDom) return;

    const mainDom = document.getElementById("main-scroll");
    mainDom.addEventListener("scroll", positionHandler);

    return () => {
      mainDom.removeEventListener("scroll", positionHandler);
    };
  }, []);

  const handleDateChange = ({ beginDate, endDate }) => {
    // console.log("beginDate, endDate", beginDate, endDate);

    setGenericQueryFilter({
      ...genericQueryFilter,
      begin_date: beginDate,
      end_date: endDate,
    });
  };

  const handleChangeGenericFilter = (key) => (value) => {
    let newFilter = {};

    const newState = {
      ...genericQueryFilter,
      [key]: value,
      ...newFilter,
    };
    setGenericQueryFilter(newState);
  };

  return (
    <PRContainer
      noAction
      className={"pr-agent-stats"}
      description={t("dashboard.agentLeaderboard.description")}
      name={t("common.statistics")}
      parentName={t("dashboard.agentLeaderboard")}
    >
      <Grid container>
        <Grid
          item
          className={classNames("pr-stats-filter-col", {
            "pr-stats-filter-col-sticked": filterSticked,
          })}
          id="filter-col"
          xs={12}
        >
          <PRPage headerColor="primary" title={!filterSticked && "Filter"}>
            <Grid container marginTop={!filterSticked ? 0 : 3}>
              <Grid item xs={12}>
                <LeaderboardFilterCard onDateChange={handleDateChange} />
              </Grid>
            </Grid>
          </PRPage>
        </Grid>
        <Grid item xs={12}>
          <PRPage
            // actions={actions}
            // title={
            //   <>
            //     Agents&nbsp;
            //     <RippleBadge
            //       anchorOrigin={{ vertical: "50%", horizontal: "right" }}
            //       color={"success"}
            //       overlap="circular"
            //       sx={{}}
            //       variant="dot"
            //     >
            //       &nbsp;
            //     </RippleBadge>
            //   </>
            // }
            title={t("common.agents")}
          >
            <PRTable
              inline
              columns={columns}
              defaultSortInfo={[{ key: "session_count", order: "desc" }]}
              memoizeAsQuery={false}
              storageKey={tableFilterStorageKey.agentLeaderboardList}
              url={`${apiUrlAgentLeaderboard.list.format(currentProject?.id)}?begin_date=${
                genericQueryFilter?.begin_date
              }&end_date=${genericQueryFilter?.end_date}`}
            />
          </PRPage>
        </Grid>
      </Grid>
    </PRContainer>
  );
}
