import PRSwitch from "~components/Generic/PRSwitch";

import ChatLabelEdit from "./ChatLabelEdit";
import ChatLabelList from "./ChatLabelList";

function ChatLabels() {
  const routes = [
    { path: "/contactcenter/settings/chat-labels/form/:id?", component: ChatLabelEdit },
    { path: "/contactcenter/settings/chat-labels/", component: ChatLabelList },
    { path: "/contactcenter", to: "/contactcenter/settings/chat-labels", redirect: true },
  ];
  return <PRSwitch routes={routes} />;
}

export default ChatLabels;
